import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";
import moment from "moment";
import { FormattingNumber } from "../../Helper/Helper";

export default function Payments({ navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));

  const [Payments, setPayments] = useState([]);
  const [FRow, setFRow] = useState({});
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Payments.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Payments.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  const CallPayments = async () => {
    await magento
      .get("payments/channel/" + profile[0].id , {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setPayments(response.data);
        response.data.map((item, index) => {
          setFRow(response.data);
        });
        return Payments;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  console.log(FRow);


  useEffect(() => {
    CallPayments();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Payments
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 8, mb: 2, p: 2, background: "#ffffff", fontSize:'10px' }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowHeight={30}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "payment_number", hideable: true, headerName: "Payment #", width: 80,
            renderCell: (params) => <Link to={`/admin/payment/view/${params.row.id}`}>{params.row.payment_number}</Link>
          },
            { field: "payment_date", hideable: true, headerName: "Payment Date" ,width: 100, valueGetter: ({ value }) =>
            value && moment(value).format("YYYY-MM-DD"),},
            { field: "receipt_number", hideable: true, headerName: "Receipt Number " },
            { field: "ref_number", hideable: true, headerName: "Ref No#" },
            {
              field: "payment_method",
              hideable: true,
              headerName: "Payment Method",
            },
            {
              field: "receipt_amount",
              hideable: true,
              headerName: "Payment Ammount",
              renderCell: (params) => FormattingNumber(params.row.receipt_amount)+'.00',
            },
            {
              field: "actions",
              type: "actions",
              width: 200,
              getActions: (GridRowParams) => [
                <Link to={`/admin/payment/view/${GridRowParams.row.id}`}>
                  View
                </Link>,
               
              ],
            },
          ]}
          rows={Payments}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/payment/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
