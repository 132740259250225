import React from "react";
import { Paper } from "@mui/material";
import { Button } from "semantic-ui-react";
import magento from "../api/magento";

export default function FileUpload() {
  const [file, setFile] = React.useState("");
  const handleSelectChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);

    return file;
  };

  const UploadImage = async (data) => {
    const UploadFileData = new FormData();
    UploadFileData.append("image", data, data.name);
    console.log(UploadFileData);

    await magento
      .post("https://webapi.automarts.pk/upload", UploadFileData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("sub", response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <>
      <Paper sx={{ mx: "20%", py: 2, my: 5 }}>
        <input
          name="logo"
          type="file"
          accept="image/*"
          onChange={(e) => handleSelectChange(e)}
        />
        <br />
        <br />
        <Button fluid secondary onClick={() => UploadImage(file)}>
          Upload Image
        </Button>
      </Paper>
    </>
  );
}
