import React, { useEffect, useState } from "react";
import "./../create.css";
import magento from "../../api/magento";
import {
  AppBar,
  Divider,
  InputAdornment,
  Paper,
  Toolbar,
} from "@mui/material";
import { ArrowBack, Send } from "@mui/icons-material";
import { Form } from "semantic-ui-react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { profile } from "../../Helper/Helper";
import NotificationMessage from "../../components/NotificationMessage";


export default function AddItem({ navigate }) {
  const [formData, setFormData] = useState({
    item_title: "",
    item_code: "",
    status: 1,
    price: "",
    cost: "",
    weight: 0,
    unit_of_measure: "kg",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    channel_id: profile[0].id,
    brand_id: 3,
    magento_id: null,
  });


  const getBrand = async () =>{
    await magento.get('http://localhost:3001/brands').then(function(response){
      console.log(response.data);
    }).catch(function(err){
      console.log(err);
    })
  }

  const UploadImage = async (data) => {
    const UploadFileData = new FormData();
    console.log(data);

    if (formData.item_code !== "") {
      UploadFileData.append("image", data, formData.item_code);
    } else {
      UploadFileData.append("image", data, data.name);
    }

    await magento
      .post("https://webapi.automarts.pk/upload", UploadFileData)
      .then(function (response) {
        console.log(response.data);
        setFormData({ ...formData, imageName: response.data.filename });
      })
      .catch(function (err) {
        console.log(err);
      });

    console.log(formData.image);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, brand_id: event.target.value });
  };

  const handleSelectChange = (e, data, update = "brand") => {
    switch (update) {
      case "brand":
        setFormData({ ...formData, brand_id: data.value });
        break;
      case "unit_of_measure":
        setFormData({ ...formData, unit_of_measure: data.value });
        break;
      case "status":
        setFormData((prevState) => ({
          ...formData,
          status: !prevState.status,
        }));
        break;
      case "image":
        setFormData({
          ...formData,
          image: e.target.files,
          imageName: e.target.files[0].name,
        });
        break;
      default:
        setFormData({ ...formData, brand_id: `${data.value}` });
        break;
    }
  };
  const OnSubmit = async (data) => {
    await magento
      .post("item/create", data)
      .then(function (response) {
        navigate(`/admin/item/view/${response.data.insertId}`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    getBrand();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {profile && profile[0].channel} - Create New Item
            </Typography>
          </Toolbar>
        </AppBar>
        <NotificationMessage />
        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "34%" }}
            >
              <TextField
                label="Item Title  Name"
                helperText="Enter item title"
                fullWidth
                variant="standard"
                value={formData.item_title}
                onChange={(e) =>
                  setFormData({ ...formData, item_title: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "20%" }}
            >
              <TextField
                id="item_code"
                name="item_code"
                label="Item Code"
                variant="standard"
                value={formData.item_code}
                helperText="Enter Item Code"
                onChange={(e) =>
                  setFormData({ ...formData, item_code: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "20%" }}
            >
              <TextField
                label="SKU"
                helperText="Enter sku"
                variant="standard"
                defaultValue={formData.sku}
                onChange={(e) =>
                  setFormData({ ...formData, sku: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
              <TextField
                label="Part No."
                helperText="Enter Part No."
                variant="standard"
                defaultValue={formData.part_no}
                onChange={(e) =>
                  setFormData({ ...formData, part_no: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl sx={{ mb: 2, mr: "2%", width: "20%" }}>
              <InputLabel id="channel">Select Brand</InputLabel>
              <Select
                labelId="brand"
                id="brand"
                name="brand"
                variant="standard"
                required
                value={formData.brand_id}
                label="Select Brand"
                onChange={(e) => handleChange(e)}
                defaultValue={3}
              >
                <MenuItem value="Rs.">Pakistani Rs</MenuItem>
                <MenuItem value="$">US Dollar</MenuItem>
                <MenuItem value="£">UK Pounds</MenuItem>
                <MenuItem value="AED">UAE Dirham</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "18%" }}
            >
              <TextField
                label="Price"
                helperText="Enter item price"
                variant="standard"
                defaultValue={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">.00</InputAdornment>
                  ),
                }}
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "18%" }}
            >
              <TextField
                label="Cost"
                helperText="Enter item cost"
                variant="standard"
                defaultValue={formData.cost}
                onChange={(e) =>
                  setFormData({ ...formData, cost: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">.00</InputAdornment>
                  ),
                }}
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "15%" }}
            >
              <TextField
                label="Weight"
                helperText="enter weight"
                variant="standard"
                defaultValue={formData.weight}
                onChange={(e) =>
                  setFormData({ ...formData, weight: e.target.value })
                }
                type="number"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
              <TextField
                label="Measure Unit"
                helperText="Enter Measure unit"
                variant="standard"
                defaultValue={formData.unit_of_measure}
                onChange={(e) =>
                  setFormData({ ...formData, unit_of_measure: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                label="Sales Description"
                helperText="Please enter items sales description."
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sales_description: e.target.value,
                  })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                label="Purchase Decription"
                helperText="Enter items purchase description.  NOTE: this will not display under pdf"
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    purchase_description: e.target.value,
                  })
                }
                type="text"
                margin="10"
              />
            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
        <Paper sx={{ mx: "20%", py: 2 }}>
          <Form
            size="large"
            onSubmit={() => UploadImage(formData.logo)}
            encType="multipart/form-data"
          >
            <input
              name="logo"
              type="file"
              accept="image/*"
              onChange={(e) => handleSelectChange(e)}
            />
            <br />
            <br />
            <Button fluid secondary>
              Upload Image
            </Button>
          </Form>
        </Paper>
      </Box>
    </>
  );
}
