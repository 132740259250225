import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
} from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
} from "@mui/x-data-grid";
import { FormattingNumber, getCall, profileId } from "../../Helper/Helper";
import SubNavBar from "../../components/SubNavBar";

export default function Po({ navigate }) {
  const [Pos, setPos] = useState([]);
  const [FRow, setFRow] = useState({});
  const {type} = useParams();
  const location = useLocation();

  const CallPos = async () => {
    let callType = '/po/channel/'+ profileId;
    await getCall(callType).then((response) => {
      setPos(response);
      response.data.map((item, index) => {
        setFRow(response);
      });
      console.log(response);
      return Pos;
    });
  };

  useEffect(() => {
    CallPos();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {(!type)? '': type  } Vendors PO{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowHeight={30}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "po_number", hideable: true, headerName: "PO#", width:80,
            renderCell: (params) => <Link to={`/admin/po/view/${params.row.id}`}>{params.row.so_number}</Link> },
            { field: "vendor_id", hideable: true, headerName: "Vendor", width:'360'},
            { field: "ref_number", hideable: true, headerName: "Ref#", width:100 },
            
            { field: "subtotal", hideable: true, headerName: "Subtotal",
              renderCell: (params) => FormattingNumber(params.row.subtotal) + '.00'
            },
            { field: "gst_total", hideable: true, headerName: "GST",
            renderCell: (params) => FormattingNumber(params.row.gst_total) + '.00' },
            { field: "grand_total_gst", hideable: true, headerName: "Total",
              renderCell: (params) => FormattingNumber(params.row.grand_total_gst) + '.00' },
            
            {
              field: "actions",
              width: 200,
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/po/view/${GridRowParams.id}`}>
                  PO 
                </Link>,
               
               
              ],
            },
          ]}
          rows={Pos}
          pageSize="25"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/po/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
