import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
} from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";
import NotificationMessage from "../../components/NotificationMessage";
import moment from "moment";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  GridRowParams,
} from "@mui/x-data-grid";

export default function Users({ navigate }) {
  const [Users, setUsers] = useState([]);
  const [FRow, setFRow] = useState({});
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Users.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Users.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  const CallUsers = async () => {
    await magento
      .get("https://webapi.automarts.pk/users/", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setUsers(response.data);
        response.data.map((item, index) => {
          setFRow(response.data);
        });
        return Users;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  console.log(FRow);

  useEffect(() => {
    CallUsers();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Users{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 8, mb:2, p: 2, background: "#ffffff" }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 50 },
            { field: "username", hideable: true, headerName: "Username#" },
            { field: "acitve", hideable: true, headerName: "Active" },
            { field: "email", hideable: true, headerName: "Email" },
            { field: "name", hideable: true, headerName: "Name" },
            { field: "role", hideable: true, headerName: "Designation" },
            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/user/view/${GridRowParams.id}`}>View</Link>,
                <Link to={`/admin/user/edit/${GridRowParams.id}`}>Edit</Link>,
              ],
            },
          ]}
          rows={Users}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/user/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
