import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar  
} from "@mui/material";

import {
 ArrowBack,
 Send,
} from "@mui/icons-material";
import {FormattingNumber, profile, today, taxes, ActiveUser, getCall, postCall, LocalUpdate} from './../../Helper/Helper';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

import CompanySelection from "../../components/CompanySelection";

import { DataGrid } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";

export default function AddPayment({ navigate }) {
  const [GlobalGST, setGlobalGST] = useState(4.5);
  const [Profile, setProfile] = useState([]);
  const [unPaid, setUnPaid] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [formData, setFormData] = useState({
    CompanyList: [],
    payment_date: today,
    channel_id: profile[0].id,
    user_id: ActiveUser[0].id,
    username: ActiveUser[0].username,
    payment_number: profile[0].payment_series,
    payment_method: "Check",
    ref_number: '',
    receipt_number:'',
    receipt_amount:0,
    income_tax_amt:0,
    total_savings: 0
  }); 

  const InfoCalls = async ()=>{
    Promise.all(
      [setFormData({ ...formData, CompanyList: await getCall("company/channel/" + profile[0].id)})]
    )
  }

  
  const handleSelectChange = (e) => {
    setFormData({ ...formData, taxtype: `${e.target.value}` });
    return formData;
  };
  const OnSubmit = async (data) => {
    await postCall("payment/create", data).then(navigate(`/admin/payments`))    
  };


  const CalculateTotal = (ids = selectedInvoice,itax = GlobalGST) =>{
    const selectedRowsData = ids.map((i) => unPaid.find((row) => i === row.id));
    const TotalAmt = selectedRowsData.reduce(
      (prevValue, currentValue) =>
        parseInt(prevValue) + parseInt(currentValue.grand_total_gst),
      0
    );
    var incomeTax = parseInt((itax / 100) * TotalAmt * 1);
    formData.receipt_amount = TotalAmt
    formData.income_tax_amt = incomeTax;
    formData.total_savings = TotalAmt - incomeTax;
    console.log(formData.receipt_amount, incomeTax);

    return formData;
  }

  const LoadInvoices = async (cinfo = 0) =>{
    if(cinfo != 0){
      setUnPaid(await getCall("invoices/unpaid/company/" +  cinfo.id));
      return formData;
    }
  }

  

  React.useEffect(() => {
    LocalUpdate();
    InfoCalls();

    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      profile = JSON.parse(localStorage.getItem('channelDetail'))
    });

  },[]);
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Add Payment #{profile[0].payment_series}
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-around' }}>
        <Paper sx={{ width: "30%", py: 2 }}>
          <Grid fluid sx={{ p: 2 }}>
           
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "96%" }}
            >
           <Select
            labelId="payment_method"
            id="payment_method"
            onChange={handleSelectChange}
            placeholder="Payment Method"
            label="Payment Method"
            native
            variant="standard"
          >
            <option key="check" value="Check" selected>Check</option>
            <option key="cash" value="Cash">Cash</option>
            <option key="payorder" value="Pay Order">Pay Order</option>
            <option key="onlinetransfer" value="Online Transfer">Online Transfer</option>
          </Select>
          <InputLabel sx={{ml:'-6%'}} id="taxtype">Payment Method</InputLabel>


            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              
              <TextField
                id="receipt_number"
                name="receipt_number"
                label="Receipt Number"
                variant="standard"
                value={formData.receipt_number}
                helperText="Please enter Receipt Number or Transcation ID"
                onChange={(e) =>
                  setFormData({ ...formData, receipt_number: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              
              <TextField
                id="ref_number"
                name="ref_number"
                label="Ref Number"
                variant="standard"
                value={formData.ref_number}
                helperText="Please enter Referene Number or PO"
                onChange={(e) =>
                  setFormData({ ...formData, ref_number: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl sx={{  mb: 2, mx: "2%", width: "96%" }}>
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    size="small"
                    sx={{ m: 0, p: 0, ml:'-6%' }}
                  >
                    Taxes
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={GlobalGST}
                    onChange={(e) => {
                      setGlobalGST(e.target.value);
                      return CalculateTotal(selectedInvoice,e.target.value)
                      
                    }}
                    autoWidth
                    fullWidth
                    sx={{ textAlign: "left" }}
                    size="small"
                    label="GST"
                    variant="standard"
                  >
                    <MenuItem sx={{ textAlign: "left" }} value="0">
                      No Tax
                    </MenuItem>
                    {taxes.map((item) => {
                        return (
                          
                          <MenuItem
                            sx={{ textAlign: "left" }}
                            value={item.percentage}
                          >
                            {item.name}
                          </MenuItem>
                        );
                    })}
                  </Select>
              </FormControl>
              <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "96%" }}
            >
              <TextField
                helperText="Tax Amount"
                variant="standard"
                value={formData.income_tax_amt}

                onChange={(e) =>
                  setFormData({ ...formData, income_tax_amt: e.target.value })
                }
                type="number"
              />
            </FormControl>
           
              <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "96%" }}
            >
              <TextField
                helperText="Total Amount of Receipts"
                variant="standard"
                value={formData.total_savings}

                onChange={(e) =>
                  setFormData({ ...formData, total_savings: e.target.value })
                }
                type="number"
              />
            </FormControl>
            
           
          </Grid>
          <Divider />
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
          <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
            Receipt Amount:
              </Typography>
          <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
             {FormattingNumber(formData.receipt_amount)} 
              </Typography>
          </Grid>
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
          <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
          Income Taxes:
              </Typography>

           <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
            {FormattingNumber(formData.income_tax_amt)} 
              </Typography>
              </Grid>
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
          <Typography variant="h5"  fontSize={22} sx={{display:'flex'}}>
          Amount:
              </Typography>

              <Typography variant="h5"  fontSize={22} sx={{display:'flex'}}>
             {FormattingNumber(formData.total_savings)} 
              </Typography>
          </Grid>

          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
              endIcon={<Send />}
              disabled={false}
            onClick={() => OnSubmit({formData, selectedInvoice})}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
        <Paper sx={{ width: '65%', py: 2 }}>
          <Grid fluid sx={{ p: 2 }}>
            <Typography variant="h5" textAlign="left">
              <CompanySelection data={formData} fnCall={LoadInvoices}/>
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
          <DataGrid 
          sx={{minHeight:'400px'}}
          density="standard"
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) =>  {
            setSelectedInvoice(newSelectionModel);
            return CalculateTotal(newSelectionModel);
          }
          }
          selectionModel={selectedInvoice}
          // rowSelectionModel={rowSelectionModel}
          
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 50 },
            { field: "status", hideable: true, headerName: "Status", width: 100 },     
            { field: "invoice_number", hideable: true, headerName: "Invoice#" },
            { field: "invoice_number_type", hideable: true, headerName: "ST/SST Invoice" },
            { field: "grand_total_gst", hideable: true, headerName: "Amount", },
            // {
            //   field: "payment_method",
            //   hideable: true,
            //   headerName: "Payment Method",
            // },
            // {
            //   field: "receipt_amount",
            //   hideable: true,
            //   headerName: "Payment Ammount",
            // },
            {
              field: "actions",
              type: "actions",
              width: 200,
              getActions: (GridRowParams) => [
                <Link to={`/admin/invoice/view/${GridRowParams.row.id}`} target="_blank">
                  View
                </Link>,
               
              ],
            },
          ]}
          rows={unPaid}
          pageSize="50"
        />
          </Grid>
          
        </Paper>
        </Box>
      </Box>
    </>
  );
}
