import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function Taxes({ navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));

  const [Taxes, setTaxes] = useState([]);
  const [FRow, setFRow] = useState({});
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Taxes.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Taxes.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  const CallTaxes = async () => {
    await magento
      .get("https://webapi.automarts.pk/taxes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        localStorage.removeItem("taxes");
        console.log(response.data);
        setTaxes(response.data);
        response.data.map((item, index) => {
          setFRow(response.data);
        });
        localStorage.setItem("taxes", JSON.stringify(response.data));
        return Taxes;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  console.log(FRow);
  const DeleteTax = async (TId) => {
    await magento
      .post("https://webapi.automarts.pk/tax/delete/" + TId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        navigate("admin/taxes");
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallTaxes();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Taxes (GST)
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 8, mb: 2, p: 2, background: "#ffffff" }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 50 },
            { field: "name", hideable: true, headerName: "Name" },
            {
              field: "percentage",
              hideable: true,
              headerName: "Percentage (%)",
            },
            {
              field: "taxtype",
              hideable: true,
              headerName: "Type",
            },
            {
              field: "actions",
              type: "actions",
              width: 200,
              getActions: (GridRowParams) => [
                <Link to={`/admin/tax/edit/${GridRowParams.row.id}`}>
                  Edit
                </Link>,
                <Button onClick={() => DeleteTax(GridRowParams.row.id)}>
                  Delete
                </Button>,
              ],
            },
          ]}
          rows={Taxes}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/tax/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
