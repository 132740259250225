import { Button, MenuItem, MenuList, Popover } from '@mui/material';
import { Icon } from "semantic-ui-react";
import React from 'react';


const PopOverComponent = ({buttonTitle = 'Button', ButtonIcon = 'print', menu = []}) =>{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const Hc = (event, menu = event.currentTarget.id ) => {
        setAnchorEl(event.currentTarget);
    };
  
    const Hclose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (<>
    <Button aria-describedby={'btn'+ buttonTitle} variant="contained" className="ui secondary button tiny" onClick={(e)=> Hc(e, menu)}>
    <Icon name={ButtonIcon} />{buttonTitle}
          </Button>
          <Popover
            id={'btn'+ buttonTitle}
            open={open}
            anchorEl={anchorEl}
            onClose={Hclose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuList style={{padding:10, minWidth:'300px', textAlign:'left'}}>
                {menu.map((item) => (
                <MenuItem style={{textAlign:'left'}}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
    </>)
}

export default PopOverComponent;