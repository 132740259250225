import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  // Font,
} from "@react-pdf/renderer";

import moment from "moment/moment";
// import logo from "./../admin/order/ZA-Autos-logo2.jpg";
import NumberIntoWord from "./NumberIntoWord";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 200,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {
    borderBottomStyle: "dotted",
    borderBottomWidth: 1,
    borderBottomColor: "#777",
    paddingVertical: 10,
    width: "100%",
    height: "1.5in",
  },
  topSection: {
    padding: 15,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },
  MainHeading: {
    fontSize: "25pt",
    fontWeight: "heavy",
    marginLeft: "129px",
    textAlign: "left",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    right: "3%",
    top: "15pt",
  },
  MainText: {
    fontSize: "9pt",
    marginVertical: 2,
  },
  SubHeading: {
    fontSize: "10pt",
    marginTop: 5,
    textAlign: "left",
    marginLeft: 130,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 2,
  },
  ShippingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
    marginHorizontal: 10,
  },
  BillingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  logo: {
    width: "45%",
    height: "100%",
    minHeight: 100,
    marginLeft: "0",
    marginRight: "auto",
    marginTop: -20,
  },
  grid: {
    borderWidth: 1,
    borderColor: "#777",
    borderStyle: "dotted",
    width: "100%",
    marginTop: 20,
    marginHorizontal: 15,
  },
  GridHeader: {
    backgroundColor: "#eee",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "dotted",
    borderBottomColor: "#777",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTopWidth: 1,
    borderTopStyle: "dotted",
    borderTopColor: "#ccc",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  InvoiceNumber: {
    fontSize: 12,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 9,
    textAlign: "left",
    alignSelf: "left",
  },
  gridText: {
    fontSize: 9,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
  },
  productSno: {
    flex: 0.6,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "dotted",
    height: "100%",
  },
  productTitle: {
    flex: 10,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "dotted",
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
  },
  productPart: {
    flex: 2.5,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "dotted",
    height: "100%",
  },
  productQty: {
    flex: 1.2,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "dotted",
    height: "100%",
  },
  productMeasure: {
    flex: 1.2,
    padding: 5,

    height: "100%",
  },
  productPrice: {
    flex: 3,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "dotted",
    height: "100%",
  },
  producctTotal: {
    flex: 3,
    padding: 5,
    height: "100%",
  },
  productGrid: {
    padding: 5,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#777",
    borderTopStyle: "dotted",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  productNote: {
    padding: 5,
    flex: 9,
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 9,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 9,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 9,
    textAlign: "right",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
  },
  mainhead2: {
    fontSize: "12pt",
  },
});

// Create Document Component
const VDcPdf = ({ data, type }) => {
  console.log(data);
  return (
    <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="ZA Autos"
      title={type}
      keywords="ZA Autos"
      subject={type}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pagewrapper} fixed>
          <View style={styles.topSection}>
            <View style={styles.HeadingWrapper}>
              {data[1] && (
                <Image
                  style={styles.logo}
                  source={{
                    uri:
                      "https://accounting.automarts.pk/assets/products/" +
                      data[1].logo,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              )}
              {/* <Image style={styles.logo} src={logo} /> */}
            </View>
            <View style={styles.InfoWrapper}>
              <Text style={styles.MainHeading}>Delivery Challan</Text>
              <Text style={styles.SubHeading}>
                Date:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {data && moment(data[0].create_at).format("DD/MM/YYYY")}
              </Text>
              <Text style={styles.SubHeading}>
                DC#: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {data && data[0].dc_number}
              </Text>
              <Text style={styles.SubHeading}>
                {data && data[0].po_number !== ""
                  ? "PO#: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data[0].po_number
                  : data && data[0].ref_number !== ""
                  ? "Ref#: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data[0].ref_number
                  : " "}
                {}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.CustomerInfo} fixed>
          <View style={styles.InfWrapper}>
            <Text style={styles.MainText}>
              {data[0].billTo && data[0].dc_to_company}
              {data[0].billTo && data[0].billTo.company}
            </Text>
            <Text style={styles.MainText}>
              {data[0].billTo && data[0].billTo.address}
              {data[0].dc_to && data[0].dc_to}
            </Text>
            <Text style={styles.MainText}>
              {data[0].dc_to_phone && "Phone:" + data[0].dc_to_phone}{" "}
              {data[0].billTo && data[0].billTo.phone}
            </Text>
            <Text style={styles.MainText}>
              {data[0].dc_to_stn && "STN: " + data[0].dc_to_stn}{" "}
              {data[0].billTo && data[0].billTo.stn}
            </Text>
            <Text style={styles.MainText}>
              {data[0].dc_to_ntn && "NTN: " + data[0].dc_to_ntn}{" "}
              {data[0].billTo && data[0].billTo.ntn}
            </Text>
          </View>
          <View style={styles.BillingAddress}>
            <Text style={{ fontSize: 10, marginBottom: 10 }}></Text>
            <Text style={{ fontSize: 9, marginBottom: 5 }}></Text>
          </View>
        </View>
        <View style={styles.grid}>
          <View style={styles.GridHeader} fixed>
            <View style={styles.productSno}>
              <Text style={styles.gridHeading}>Sno.</Text>
            </View>
            <View style={styles.productPart}>
              <Text style={styles.gridHeading}>Item Code</Text>
            </View>
            <View style={styles.productTitle}>
              <Text style={styles.gridHeading}>Description</Text>
            </View>

            <View style={styles.productQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.productMeasure}>
              <Text style={styles.priceCenter}>Unit of Measure</Text>
            </View>
          </View>
          {data[0].SelectedItems &&
            data[0].SelectedItems.map((item, index) => {
              //   console.log(data[0].id);
              return (
                <View style={styles.GridProducts}>
                  <View style={styles.productSno}>
                    <Text style={styles.priceCenter}>{index + 1}</Text>
                  </View>
                  <View style={styles.productPart}>
                    <Text style={styles.gridText}>{item.item_code}</Text>
                  </View>

                  <View style={styles.productTitle}>
                    <Text style={styles.gridText}>{item.item_title}</Text>
                    <Text style={styles.smallText}></Text>
                  </View>

                  <View style={styles.productQty}>
                    <Text style={styles.priceCenter}>{item.item_qty}</Text>
                  </View>
                  <View style={styles.productMeasure}>
                    <Text style={styles.priceCenter}>
                      {item.measure_of_unit}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 15,
            paddingHorizontal: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: 30,
            bottom: 50,
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 15,
            }}
          >
            <View
              style={{
                borderColor: "#777",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "dotted",
                padding: 10,
                width: "55%",
                height: 100,
                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75, paddingTop: 10 }}>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  Created By:
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 5 }}>
                  {data[0].name}
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  {data[0].role}
                </Text>
              </View>
              <Text style={styles.footerMainTextTop}>
                This is computer generated document, no signature and stamp
                required.
              </Text>
            </View>
            <View style={{ width: "45%" }}></View>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 15,
              paddingHorizontal: 10,
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginTop: 0,
              bottom: -50,
              borderTopColor: "#777",
              borderTopStyle: "dotted",
              borderTopWidth: 1,
            }}
          >
            <View style={styles.footer}>
              <Text style={styles.footerMainText}>
                {data[1] && data[1].channel}
                {", "}
                {data[1] && data[1].address}
              </Text>
              <Text style={styles.footerMainText}>
                STN: {data[1] && data[1].stn}, NTN: {data[1] && data[1].ntn},
                Phone:
                {data[1] && data[1].phone}
              </Text>
              <Text style={styles.MainText}></Text>
            </View>
            <Text
              style={styles.footerMainText}
              render={({ pageNumber, totalPages }) => {
                return `Page No. ${pageNumber} / ${totalPages}`;
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default VDcPdf;
