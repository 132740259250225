import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";

import {profile} from "./../../Helper/Helper";
import PrintEnvelope from "../../components/pdf/Default/PrintEnvelope";
import { useParams } from "react-router-dom";
import magento from "../../api/magento";

export const EnvelopePDF = () => {
    const {Color, type, Id} = useParams();
    const [formData, setFormData] = useState();
    const CallData = async () =>{
        await magento
        // .get(`http://localhost:3001/quotation/${quoteId}`, {
          .get(`https://webapi.automarts.pk/${type}/${Id}`, {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response.data);
          setFormData(response.data);
          return formData;
        })
        .catch((error) => {
          console.log(error);
        });

    }
    useEffect(()=>{
        CallData();
    },[]);
    return (
        <PDFViewer width="100%" height="1000px">
            <PrintEnvelope data={{formData, type}} />
        </PDFViewer>
      );
}

