import React from "react";
import magento from "../../api/magento";
import { Box, TextField } from "@mui/material";
import YelpData from "./YelpData.json";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment/moment";
import { Link } from "react-router-dom";
const YelpSearch = () => {
  const [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [tasksPerPage, setTasksPerPage] = React.useState(50);
  const [ItemsPerPage, setItemsPerPage] = React.useState(50);

  const CallYelpSearch = async (terms = "Houston") => {
    // let proxyUrl = "https://cors-anywhere.herokuapp.com/";
    await magento
      .get(
        "http://localhost:3001/yelp/" +
          "/" +
          50 +
          "/" +
          tasksPerPage +
          "/" +
          terms,
        {
          // params: {
          //   term: "Bar",
          //   location: encodeURI(terms),
          //   sort_by: "best_match",
          //   limit: 50,
          //   offset: tasksPerPage,
          // },
          headers: {
            changeOrigin: true,

            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",

            Authorization:
              "Bearer oTbojFM4iJBoj23S5FP55qxLRKKfSxrqX8OjcxB65C6t9hB_MsCIfZwEBTd4A9XtXKN2JFkkmDTvlQ3zq1MvnbQ4KGOvRguwEA7WQtnqUknv1RC79Qzf2wy86NR3YHYx",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setTableData(response.data.businesses);
        return tableData;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangePage = (page, details) => {
    setTasksPerPage(Number(tasksPerPage) + 50);
    CallYelpSearch("Houston", Number(tasksPerPage) + 50);

    setPage(page + 1);
  };
  const handleChangeRowsPerPage = (pageSize) => {
    setItemsPerPage(parseInt(pageSize, 50));
    console.log(ItemsPerPage);
    setPage(1);
  };

  React.useEffect(() => {
    CallYelpSearch("Houston");
  }, [page, tasksPerPage, ItemsPerPage]);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <TextField
          sx={{ marginTop: 12 }}
          onChange={(e) => CallYelpSearch(e.target.value)}
        />

        <h1 style={{ textAlign: "center" }}>Search Result</h1>
        <DataGrid
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            created_by: false,
            id: false,
            grand_total_gst: false,
            image_url: false,
          }}
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 20 },
            {
              field: "image_url",
              width: 20,
              headerName: "",
              renderCell: (params) => (
                <img src={params.row.image_url} width={30} height={30} />
              ),
            },
            {
              field: "name",
              hideable: true,
              headerName: "Business Name",
              width: 200,
            },
            {
              field: "display_phone",
              hideable: true,
              headerName: "Phone",
              width: 100,
            },

            {
              field: "location",
              hideable: true,
              headerName: "Location ",
              width: 300,
              valueGetter: (params) => params.row.location.display_address,
            },
            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <a href={GridRowParams.row.url} target="_blank">
                  View
                </a>,
              ],
            },
          ]}
          EnableRowVirtualization="True"
          rows={tableData}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          page={page}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[50, 100, 200]}
          pageSize={ItemsPerPage}
          autoHeight
          rowCount={YelpData.total}
        />
      </Box>
    </>
  );
};

export default YelpSearch;
