import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";

import magento from "../../api/magento";
import { useParams, useLocation } from "react-router-dom";
import CreatePDF from "../backups/BackupCreatePDF";
import VPdf from "../../components/VPdf";
import VDcPdf from "../../components/VDcPdf";

// Font.register({
//   family: "RobotoBold",
//   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900",
// });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9Regular' });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap', { fontFamily: 'Roboto-Bold' });

// Create Document Component
const PreviewPdf = ({ sdata, type = "quote" }) => {
  const [data, setData] = useState(sdata);
  const { quoteId } = useParams();

  useEffect(() => {
    console.log(sdata);
  }, []);

  if (type === "dc") {
    return (
      <PDFViewer showToolbar={false} width="100%" height="850px">
        <VDcPdf data={data} />
      </PDFViewer>
    );
  } else if (type === "invoice") {
    return (
      <PDFViewer showToolbar={false} width="100%" height="850px">
        <VPdf data={data} />
      </PDFViewer>
    );
  } else {
    return (
      <PDFViewer showToolbar={false} width="100%" height="850px">
        <VPdf data={data} />
      </PDFViewer>
    );
  }
};

export default PreviewPdf;
