import React from 'react';
import { Typography } from '@mui/material';
import { Link } from "react-router-dom";

const DetailInfoComponents = ( {exp = 'From', head, InfoData = [], Edit = false, EditLink = "", EditData}) => {
    
    const DetailInfoRow = (RowTitle, RowData) =>{
         return <>
                    <Typography variant="p" marginBottom={0}>
                        {RowTitle}: {RowData}
                    </Typography>
                    <br/>
                </>
        
    }

    const EditButton = () =>{
        return <>
                    <Link
                        to={{ pathname: EditLink }}
                        state={ EditData }
                        >
                        <small>Edit</small>
                    </Link>
                </>
    }

    return (
    <>
    {console.log(EditData,'new component')}
        <Typography
                variant="h6"
                textAlign="left"
                color={"#777"}
                fontSize={15}
              >
                {exp}: {head}
                {Edit === true ? EditButton() : ''}
                
              </Typography>
            
              {InfoData.map((item)=>{
                // console.log(item.title);
                    return DetailInfoRow(item.title, item.infodata);
              })}
            
    </>
    );
}

export default DetailInfoComponents;