import React, { useEffect, useState } from "react";
import "./../create.css";
import { Segment, SegmentGroup, GridColumn } from "semantic-ui-react";
import magento from "../../api/magento";
import {
  AppBar,
  Divider,
  FormHelperText,
  InputAdornment,
  Paper,
  Toolbar,
} from "@mui/material";
import { ArrowBack, Send } from "@mui/icons-material";
import { Form, Input, Icon } from "semantic-ui-react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocation, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
const currency = [
  { key: "R", text: "Rs.", value: "Rs." },
  { key: "u", text: "US Dollar", value: "$" },
  { key: "p", text: "UK Pounds", value: "£" },
  { key: "d", text: "UAE Dirham", value: "AED" },
];
const unit_of_measure = [
  { key: "k", text: "Kilogram", value: "kg" },
  { key: "p", text: "Pounds", value: "Lbs." },
  { key: "l", text: "Litre", value: "Ltr." },
  { key: "c", text: "Carton", value: "Ctn." },
];
export default function EditItem({ popup, navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const location = useLocation();
  const ChannelData = location.state;
  console.log(ChannelData.purchase_description);
  const [formData, setFormData] = useState({
    id: ChannelData.id,
    item_title: ChannelData.item_title,
    item_code: ChannelData.item_code,
    status: ChannelData.status,
    price: ChannelData.price,
    cost: ChannelData.cost,
    weight: ChannelData.weight,
    currency: ChannelData.currency,
    unit_of_measure: "kg",
    image: [],
    imageName: "",
    sku: ChannelData.sku,
    purchase_description: ChannelData.purchase_description,
    sales_description: ChannelData.sales_description,
    part_no: ChannelData.part_no,
    item_types: ChannelData.item_types,
    location: ChannelData.location,
    image: [],
    imageName: "",
  });

  const UploadImage = async (data) => {
    const UploadFileData = new FormData();
    console.log(data);

    if (formData.item_code !== "") {
      UploadFileData.append("image", data, formData.item_code);
    } else {
      UploadFileData.append("image", data, data.name);
    }

    await magento
      .post("https://webapi.automarts.pk/upload", UploadFileData)
      .then(function (response) {
        console.log(response.data);
        setFormData({ ...formData, imageName: response.data.filename });
      })
      .catch(function (err) {
        console.log(err);
      });

    console.log(formData.image);
  };

  const handleChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value as string);
    console.log(event.target.value);
    setFormData({ ...formData, currency: event.target.value });
  };

  const handleSelectChange = (e, data, update = "currency") => {
    switch (update) {
      case "currency":
        setFormData({ ...formData, currency: data.value });
        break;
      case "unit_of_measure":
        setFormData({ ...formData, unit_of_measure: data.value });
        break;
      case "status":
        setFormData((prevState) => ({
          ...formData,
          status: !prevState.status,
        }));
        break;
      case "image":
        // console.log(e.target.files)
        setFormData({
          ...formData,
          image: e.target.files,
          imageName: e.target.files[0].name,
        });
        break;
      default:
        setFormData({ ...formData, currency: `${data.value}` });
        break;
    }
  };
  const OnSubmit = async (data) => {
    console.log(data);
    await magento
      .post("https://webapi.automarts.pk/item/edit/" + formData.id, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate("/admin/item", {
          state: {
            message: "Item has been sucessfully updated",
          },
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log(formData, "New");
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {profile && profile[0].channel} - Edit Item
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "34%" }}
            >
              <TextField
                label="Item Title  Name"
                helperText="Enter item title"
                fullWidth
                variant="standard"
                value={formData.item_title}
                onChange={(e) =>
                  setFormData({ ...formData, item_title: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "20%" }}
            >
              <TextField
                id="item_code"
                name="item_code"
                label="Item Code"
                variant="standard"
                value={formData.item_code}
                helperText="Enter Item Code"
                onChange={(e) =>
                  setFormData({ ...formData, item_code: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "20%" }}
            >
              <TextField
                label="SKU"
                helperText="Enter sku"
                variant="standard"
                defaultValue={formData.sku}
                onChange={(e) =>
                  setFormData({ ...formData, sku: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
              <TextField
                label="Part No."
                helperText="Enter Part No."
                variant="standard"
                defaultValue={formData.part_no}
                onChange={(e) =>
                  setFormData({ ...formData, part_no: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl sx={{ mb: 2, mr: "2%", width: "20%" }}>
              <InputLabel id="channel">Select Currency</InputLabel>
              <Select
                labelId="currency"
                id="currency"
                name="currency"
                variant="standard"
                required
                value={formData.currency}
                label="Select Currency"
                onChange={(e) => handleChange(e)}
                defaultValue={1}
              >
                <MenuItem value="Rs.">Pakistani Rs</MenuItem>
                <MenuItem value="$">US Dollar</MenuItem>
                <MenuItem value="£">UK Pounds</MenuItem>
                <MenuItem value="AED">UAE Dirham</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "18%" }}
            >
              <TextField
                label="Price"
                helperText="Enter item price"
                variant="standard"
                defaultValue={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">.00</InputAdornment>
                  ),
                }}
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "18%" }}
            >
              <TextField
                label="Cost"
                helperText="Enter item cost"
                variant="standard"
                defaultValue={formData.cost}
                onChange={(e) =>
                  setFormData({ ...formData, cost: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">.00</InputAdornment>
                  ),
                }}
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "15%" }}
            >
              <TextField
                label="Weight"
                helperText="enter weight"
                variant="standard"
                defaultValue={formData.weight}
                onChange={(e) =>
                  setFormData({ ...formData, weight: e.target.value })
                }
                type="number"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
              <TextField
                label="Measure Unit"
                helperText="Enter Measure unit"
                variant="standard"
                defaultValue={formData.unit_of_measure}
                onChange={(e) =>
                  setFormData({ ...formData, unit_of_measure: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                label="Sales Description"
                helperText="Please enter items sales description."
                fullWidth
                variant="standard"
                value={formData.sales_description}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sales_description: e.target.value,
                  })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                label="Purchase Decription"
                helperText="Enter items purchase description.  NOTE: this will not display under pdf"
                fullWidth
                value={formData.purchase_description}
                variant="standard"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    purchase_description: e.target.value,
                  })
                }
                type="text"
                margin="10"
              />
            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
        <Paper sx={{ mx: "20%", py: 2 }}>
          <Form
            size="large"
            onSubmit={() => UploadImage(formData.logo)}
            encType="multipart/form-data"
          >
            <input
              name="logo"
              type="file"
              accept="image/*"
              onChange={(e) => handleSelectChange(e)}
            />
            <br />
            <br />
            <Button fluid secondary>
              Upload Image
            </Button>
          </Form>
        </Paper>
      </Box>
    </>
  );
}
