import React from "react";
import "./../admin/create.css";
import { Input, Modal, Checkbox, Form } from "semantic-ui-react";

import NotificationMessage from "./NotificationMessage";
import magento from "../api/magento";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RejectionInDc = ({ data, navigate }) => {
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const nav = useNavigate();
  data.user = ActiveUser;
  const [open, setOpen] = React.useState(false);
  const [ConvertinData, setConvertinData] = React.useState({
    Items: data.Items,
    checked: true,
  });
  console.log(data.Data[0].id);
  const [message, setMessage] = React.useState({
    message: "",
    status: "",
  });
  const myData = [];

  const HandleCheckBox = (itemData, array) => {
    itemData.checked = !itemData.checked;
    itemData.checked !== false
      ? myData.splice((i) => i.id !== itemData.id, 1)
      : myData.push(itemData);

    console.log(array);
    return;
  };

  const RejectItem = async (vdata) => {
    console.log(vdata);
    if (vdata.length == 0) {
      setMessage({
        message:
          "Please select at least one item to create new delivery challan",
        status: "error",
      });
    } else {
      setMessage({
        message: "",
        status: "",
      });
      await magento
        .post(
          "https://webapi.automarts.pk/rejection/create",
          // "http://localhost:3001/rejection/create",
          { vdata, data },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          nav(0);
          // return setOpen(false);
          // console.log(response.data.insertId);

          // setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      // navigate("/admin/dashboard");
    }
  };

  return (
    <>
      <Modal
        size="large"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        centered={false}
        trigger={
          <Button
            sx={{ mr: 0.5 }}
            size="small"
            color="error"
            variant="contained"
            onClick={() => console.log("Error")}
          >
            Add Rejection
          </Button>
        }
      >
        <Modal.Header>Create Rejection for Items</Modal.Header>

        <Modal.Content>
          {/* <ItemtSearch onSerchClick={SearchItemsList} /> */}

          <>
            <h4>Please select rejected items and their quantity</h4>
            <NotificationMessage
              msg={message.message}
              mstatus={message.status}
            />
            <table className="ui celled table">
              <thead>
                <tr>
                  <th></th>
                  <th>Product</th>
                  <th>Code</th>
                  <th>Qty</th>
                  <th>Rejected</th>
                  <th>Reject Qty</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {ConvertinData.Items.map((item) => {
                  // let v = checkSku(item.sku);
                  item.checked = ConvertinData.checked;
                  item.RejectNewQty = item.item_qty - item.item_rejected;
                  item.left = item.item_qty - item.item_rejected;
                  item.reason = "";
                  // console.log(ConvertinData.DCItems);
                  return (
                    <>
                      {item.RejectNewQty !== 0 ? (
                        <tr key={item.id}>
                          <td>
                            <Checkbox
                              // defaultChecked
                              value="1"
                              // onClick={() => {
                              //   myData.length === 0
                              //     ? setBtnActive(true)
                              //     : setBtnActive(false);

                              //   // return myData;
                              // }}
                              onChange={() => {
                                HandleCheckBox(item, myData);
                                // console.log(myData);
                              }}
                            />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_rejected}</td>
                          <td data-label="DcQty" width="20%">
                            <Input
                              size="13"
                              max={item.left}
                              min="1"
                              defaultValue={item.left}
                              type="number"
                              onChange={(e) => {
                                item.RejectNewQty = e.target.value;
                              }}
                            />
                          </td>
                          <td width="50%">
                            <Input
                              style={{ width: "100%" }}
                              type="text"
                              placeholder="Enter complete reason of item rejection"
                              onChange={(e) => {
                                item.reason = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr key={item.id} className="disabled " color="error">
                          <td>
                            <Checkbox checked />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_rejected}</td>
                          <td data-label="itemQty">{item.RejectNewQty}</td>

                          <td data-label="DcQty" width="20%" colSpan={2}>
                            <strong>All Items are Rejected</strong>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        </Modal.Content>
        <Modal.Actions>
          <Form onSubmit={() => RejectItem(myData)}>
            <Button variant="outlined" color="error" type="submit">
              Submit Rejection
            </Button>
          </Form>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default RejectionInDc;
