import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";
import {
  ArrowBack,
  Send,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel } from "@mui/material";
import magento from "../../api/magento";

export default function AddTax({ navigate }) {
  const [formData, setFormData] = useState({
    name: "",
    percentage: "",
    isDefault: 0,
  });

  const handleSelectChange = (e) => {
    setFormData({ ...formData, taxtype: `${e.target.value}` });
    return formData;
  };
  const OnSubmit = async (data) => {
    await magento
      .post("tax/create", data,)
      .then(function (response) {
        navigate(`/admin/taxes`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Add New Tax
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "38%" }}
            >
              <TextField
                id="name"
                name="name"
                label="GST Title"
                variant="standard"
                value={formData.name}
                helperText="Enter GST Title"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                label="Percentage (%)"
                helperText="Enter percentage without % sign"
                variant="standard"
                value={formData.percentage}
                onChange={(e) =>
                  setFormData({ ...formData, percentage: e.target.value })
                }
                type="number"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
            <InputLabel id="taxtype">Tax Type</InputLabel>
           <Select
            labelId="taxtype"
            id="taxtype"
            onChange={handleSelectChange}
            placeholder="Tax Type"
            label="Tax Type"
            native
            variant="standard"
          >
            <option key="sales" value="Sales" selected>Sales</option>
            <option key="Service" value="Service">Services</option>
            <option key="Income" value="Income">Income</option>
          </Select>
          

            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
