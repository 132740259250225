import React, { useEffect, useState } from "react";
import "./../create.css";
import { Segment, SegmentGroup, GridColumn } from "semantic-ui-react";
import magento from "../../api/magento";
import {
  AppBar,
  Divider,
  FormHelperText,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import { ArrowBack, Send } from "@mui/icons-material";
import { Form, Input, Icon } from "semantic-ui-react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
const currency = [
  { key: "R", text: "Rs.", value: "Rs." },
  { key: "u", text: "US Dollar", value: "$" },
  { key: "p", text: "UK Pounds", value: "£" },
  { key: "d", text: "UAE Dirham", value: "AED" },
];
const unit_of_measure = [
  { key: "k", text: "Kilogram", value: "kg" },
  { key: "p", text: "Pounds", value: "Lbs." },
  { key: "l", text: "Litre", value: "Ltr." },
  { key: "c", text: "Carton", value: "Ctn." },
];
export default function ViewItem({ navigate }) {
  const { itemId } = useParams();
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [formData, setFormData] = useState([]);

  const CallItem = async () => {
    await magento
      .get("https://webapi.automarts.pk/item/" + itemId)
      .then(function (response) {
        setFormData(response.data);
        console.log(response.data);
        return formData;
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    CallItem();
    console.log(formData.inQuote, "New");
  }, []);

  return (
    <>
      {formData.data && (
        <Box bgcolor="#f9f9f9" fluid height="100%">
          <CssBaseline />

          <AppBar
            position="static"
            color="default"
            enableColorOnDark
            sx={{ mb: 3 }}
          >
            <Toolbar sx={{ textAlign: "left" }}>
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {profile && profile[0].channel} - {formData.data[0].item_title}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="" style={{ display: "flex" }}>
            <Paper style={{ width: "30%" }} sx={{ mx: "2%", py: 0 }}>
              <Grid fluid sx={{ p: 2 }}>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  sx={{ mb: 1 }}
                  textAlign="left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Item Details</span>
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography textAlign="left">
                  Title : {formData.data[0].item_title}
                </Typography>
                <Typography textAlign="left">
                  Code : {formData.data[0].item_code}
                </Typography>
                <Typography textAlign="left">
                  Part No. : {formData.data[0].part_no}
                </Typography>
                <Typography sx={{}} textAlign="left">
                  SKU : {formData.data[0].sku}
                </Typography>
                <Typography sx={{}} textAlign="left">
                  Sales Description : {formData.data[0].sales_description}
                </Typography>
                <Typography sx={{ mb: 1 }} textAlign="left">
                  Purchase Description : {formData.data[0].purchase_description}
                </Typography>
                <Divider />
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  sx={{ my: 1 }}
                  textAlign="left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Rates Details</span>
                </Typography>

                <Typography textAlign="left">
                  Rates : {formData.data[0].currency} {formData.data[0].price}
                </Typography>
                <Typography textAlign="left">
                  Cost : {formData.data[0].currency} {formData.data[0].cost}
                </Typography>
                <Typography textAlign="left">
                  Weight : {formData.data[0].weight}{" "}
                  {formData.data[0].unit_of_measure}
                </Typography>
              </Grid>
              <Divider />
              <Button
                onClick={() => navigate(-1)}
                sx={{ textAlign: "right", my: 1 }}
                variant="outlined"
                endIcon={<ArrowBack />}
              >
                Back
              </Button>
            </Paper>
            <div style={{ width: "70%" }}>
              <div style={{ display: "flex" }}>
                <Paper style={{ width: "30%" }} sx={{ mx: "2%", p: 1, mb: 2 }}>
                  <small>Total Invoice Earning</small>
                  <br />
                  <h2 style={{ marginTop: 0 }}>
                    <small>Rs.</small>
                    {formData.totals[0].sumtotal &&
                      formData.totals[0].sumtotal.toFixed(2)}
                  </h2>
                </Paper>
                <Paper style={{ width: "30%" }} sx={{ mx: "2%", p: 1, mb: 2 }}>
                  <small>Total Invoiced Cost</small>
                  <br />
                  <h2 style={{ marginTop: 0 }}>
                    <small>Rs.</small>
                    {formData.totals[0].sumtotal &&
                      formData.totals[0].sumcost.toFixed(2)}
                  </h2>
                </Paper>
                <Paper style={{ width: "30%" }} sx={{ mx: "2%", p: 1, mb: 2 }}>
                  <small>Total Invoiced Qty </small>
                  <br />
                  <h2 style={{ marginTop: 0 }}>
                    {formData.totals[0].sumtotal && formData.totals[0].sumqty}
                  </h2>
                </Paper>
              </div>
              <Paper sx={{ mx: "2%", p: 2, mb: 2 }}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  sx={{ mb: 1 }}
                  textAlign="left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{formData.data[0].item_code} on Quotation </span>
                </Typography>
                <Divider />
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Quote#</TableCell>
                      <TableCell>Ref#</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Left/DC</TableCell>
                      <TableCell>Rates</TableCell>
                      <TableCell>GST</TableCell>
                      <TableCell>Row Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.inQuote &&
                      formData.inQuote.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Link to={`/admin/quotation/view/${row.id}`}>
                              {row.quote_number}{" "}
                            </Link>
                          </TableCell>
                          <TableCell>{row.ref_number}</TableCell>

                          <TableCell>
                            {" "}
                            <Link to={`/admin/company/view/${row.company_id}`}>
                              {row.quote_to_company}
                            </Link>
                          </TableCell>
                          <TableCell>{row.item_qty}</TableCell>
                          <TableCell>
                            {row.item_left}/{row.item_dc_qty}
                          </TableCell>
                          <TableCell>{row.item_price}</TableCell>
                          <TableCell>{row.item_gst}</TableCell>
                          <TableCell>{row.item_row_total_gst}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
              <Paper sx={{ mx: "2%", p: 2, mb: 2 }}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  sx={{ mb: 1 }}
                  textAlign="left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{formData.data[0].item_code} on DC </span>
                </Typography>
                <Divider />
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>DC#</TableCell>
                      <TableCell>Quote#</TableCell>
                      <TableCell>Ref#</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Qty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.inDc &&
                      formData.inDc.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Link to={`/admin/dc/view/${row.id}`}>
                              {row.dc_number}{" "}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/admin/quotation/view/${row.quote_id}`}>
                              {row.quote_number}
                            </Link>
                          </TableCell>
                          <TableCell>{row.ref_number}</TableCell>

                          <TableCell>
                            {" "}
                            <Link to={`/admin/company/view/${row.company_id}`}>
                              {row.dc_to_company}
                            </Link>
                          </TableCell>
                          <TableCell>{row.item_qty}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
              <Paper sx={{ mx: "2%", p: 2, mb: 2 }}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  sx={{ mb: 1 }}
                  textAlign="left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{formData.data[0].item_code} on Invoice </span>
                </Typography>
                <Divider />
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Inovice#</TableCell>
                      <TableCell>PO#</TableCell>
                      <TableCell>Quote#</TableCell>
                      <TableCell>Ref#</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Rates</TableCell>
                      <TableCell>GST</TableCell>
                      <TableCell>Row Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.inInv &&
                      formData.inInv.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Link to={`/admin/invoice/view/${row.id}`}>
                              {row.invoice_number}{" "}
                            </Link>
                          </TableCell>
                          <TableCell>{row.po_number}</TableCell>
                          <TableCell>
                            {" "}
                            <Link
                              to={`/admin/quotation/view/${row.quotation_id}`}
                            >
                              {row.quotation_number}
                            </Link>
                          </TableCell>
                          <TableCell>{row.ref_number}</TableCell>

                          <TableCell>
                            {" "}
                            <Link to={`/admin/company/view/${row.company_id}`}>
                              {row.bill_to_company}
                            </Link>
                          </TableCell>
                          <TableCell>{row.item_qty}</TableCell>

                          <TableCell>{row.item_price}</TableCell>
                          <TableCell>{row.item_gst}</TableCell>
                          <TableCell>{row.item_row_total_gst}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}
