import React from "react";

const SignOut = ({ navigate }) => {

  sessionStorage.removeItem("channel");
  sessionStorage.setItem("LoggedIn", "0");
  localStorage.clear();
  localStorage.removeItem("taxes");

  const test = () => {
    sessionStorage.removeItem("usd");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("channel");
    sessionStorage.setItem("LoggedIn", "0");

    if (sessionStorage.getItem("LoggedIn") === 0) {
      return navigate("login");
    } else {
      return navigate("login");
    }
  };
  
  React.useEffect(() => {
    test();
    
  }, []);

  return <>{test}</>;
};

export default SignOut;
