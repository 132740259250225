import React from "react";

const NumberIntoWord = ({ number }) => {
  const arr = (x) => Array.from(x);
  const num = (x) => Number(x) || 0;
  const str = (x) => String(x);
  const isEmpty = (xs) => xs.length === 0;
  const take = (n) => (xs) => xs.slice(0, n);
  const drop = (n) => (xs) => xs.slice(n);
  const reverse = (xs) => xs.slice(0).reverse();
  const comp = (f) => (g) => (x) => f(g(x));
  const not = (x) => !x;
  const chunk = (n) => (xs) =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

  // numToWords :: (Number a, String a) => a -> String
  let numToWords = (n) => {
    let a = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    let b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    let g = [
      "",
      "Thousand",
      "Million",
      "Billion",
      "Trillion",
      "Quadrillion",
      "Quintillion",
      "Sextillion",
      "Septillion",
      "Octillion",
      "Nonillion",
    ];

    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    let makeGroup = ([ones, tens, huns]) => {
      return [
        num(huns) === 0 ? "" : a[huns] + " Hundred ",
        num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
        a[tens + ones] || a[ones],
      ].join("");
    };

    let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);

    if (typeof n === "number") return numToWords(String(n));
    else if (n === "0") return "zero";
    else
      return comp(chunk(3))(reverse)(arr(n))
        .map(makeGroup)
        .map(thousand)
        .filter(comp(not)(isEmpty))
        .reverse()
        .join(" ");
  };

  return <>{numToWords(number)}</>;
};

export default NumberIntoWord;
