import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";
import magento from "../api/magento";
const CurrencyConvertor = ({
  CurrFrom = "USD",
  Currto = "PKR",
  view = "normal",
}) => {
  const [Currency, setCurrency] = useState(0);

  const callExchange = async () => {
    await magento
      .get(`https://webapi.automarts.pk/settings/currency/${CurrFrom}`)
      .then(function (response) {
        console.log(response.data, "currency");
        localStorage.setItem(CurrFrom, response.data);
        setCurrency(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const checkCurr = (curr) => {
    return (
      localStorage.getItem(`${curr}`) &&
      JSON.parse(localStorage.getItem(`${curr}`)).toFixed(2)
    );
  };

  useEffect(() => {
    callExchange();
  }, []);

  if (view === "normal") {
    return (
      <>
        <Typography style={{ fontWeight: "bold" }} variant="h5">
          PKR {checkCurr(CurrFrom)}
        </Typography>
      </>
    );
  } else {
    return Currency === 0 ? Currency : Currency.toFixed(2);
  }
};

export default CurrencyConvertor;
