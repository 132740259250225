import React, { useState, useEffect } from "react";
import "./../create.css";
import { AppBar, Divider, FormHelperText, Paper, Toolbar } from "@mui/material";

import { ArrowBack, Send } from "@mui/icons-material";
import magento from "../../api/magento";
import { useLocation, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
import SubNavBar from "../../components/SubNavBar";
import { getCall, profile } from "../../Helper/Helper";

export default function EditCompany({ navigate }) {
  const { companyId } = useParams();
  const [formData, setFormData] = useState({
    channel_id: profile[0].id,
  });

  const CallData = async () => {
    await getCall("company/" + companyId).then((response) => {
      setFormData(response.Data[0])
    })
  };
      
  
  const OnSubmit = async (data) => {
    console.log(companyId);
    await magento
      .post("company/edit/" + companyId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/company/view/${formData.id}`, { state: formData });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    CallData();
  }, []);

  return (
    <>
      {formData.company && (
        <Box bgcolor="#f9f9f9" fluid height="100vh">
          <CssBaseline />

          <AppBar
            position="static"
            color="default"
            enableColorOnDark
            sx={{ mb: 3 }}
          >
            <Toolbar sx={{ textAlign: "left" }}>
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {profile && profile[0].channel} - Edit Company
              </Typography>
            </Toolbar>
          </AppBar>
          <SubNavBar />
          <Paper sx={{ mx: "20%", py: 2 }}>
            <Grid fluid sx={{ px: 2 }}>
              <Typography variant="h5" textAlign="left">
                {formData.company}
              </Typography>
            </Grid>
            <Divider />

            <Grid fluid sx={{ p: 2 }}>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "4%", width: "42%" }}
              >
                <TextField
                  label="Company Name"
                  helperText="Enter complete company Name"
                  fullWidth
                  variant="standard"
                  value={formData.company}
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "4%", width: "25%" }}
              >
                <TextField
                  label="Abbrivation"
                  helperText="Enter Short name"
                  fullWidth
                  variant="standard"
                  value={formData.abbr}
                  onChange={(e) =>
                    setFormData({ ...formData, abbr: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>

              <FormControl textAlign="left" sx={{ mb: 2, width: "25%" }}>
                <InputLabel id="business_type">Business Type</InputLabel>

                <Select
                  labelId="business_type"
                  fullWidth
                  variant="standard"
                  value={formData.business}
                  label="Business Type"
                  helperText="Please enter complete company Name"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Automation">Automation</MenuItem>
                  <MenuItem value="Shipping">Shipping</MenuItem>
                  <MenuItem value="Education">Education</MenuItem>
                  <MenuItem value="Army & Law Agencies">
                    Army and Law Agencies
                  </MenuItem>
                  <MenuItem value="Medical">Medical</MenuItem>
                  <MenuItem value="Corporate">Corporate</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                <FormHelperText>select business type</FormHelperText>
              </FormControl>

              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "48%" }}
              >
                <TextField
                  id="ntn"
                  name="ntn"
                  label="NTN#"
                  variant="standard"
                  value={formData.ntn}
                  helperText="Please enter NTN Number"
                  onChange={(e) =>
                    setFormData({ ...formData, ntn: e.target.value })
                  }
                  type="text"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "2%", width: "47%" }}
              >
                <TextField
                  label="STN#"
                  helperText="Please enter STN Number"
                  variant="standard"
                  defaultValue={formData.stn}
                  onChange={(e) =>
                    setFormData({ ...formData, stn: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
                <TextField
                  label="Office Address"
                  helperText="Please enter complete office address"
                  fullWidth
                  variant="standard"
                  defaultValue={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "48%" }}
              >
                <TextField
                  label="Office Phone Number"
                  helperText="Please enter office phone"
                  variant="standard"
                  defaultValue={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  type="mail"
                  margin="10"
                />
              </FormControl>

              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "2%", width: "47%" }}
              >
                <TextField
                  label="Office Fax"
                  helperText="Please enter office Fax"
                  variant="standard"
                  defaultValue={formData.fax}
                  onChange={(e) =>
                    setFormData({ ...formData, fax: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "48%" }}
              >
                <TextField
                  label="Email Address"
                  helperText="Please enter Email address"
                  variant="standard"
                  defaultValue={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  type="mail"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "2%", width: "47%" }}
              >
                <TextField
                  label="Office Website"
                  helperText="Please enter website "
                  variant="standard"
                  defaultValue={formData.website}
                  onChange={(e) =>
                    setFormData({ ...formData, website: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "2%", width: "65%" }}
              >
                <TextField
                  label="Balance Amount"
                  helperText="Enter Balance Amount "
                  variant="standard"
                  defaultValue={formData.balance_amt}
                  onChange={(e) =>
                    setFormData({ ...formData, balance_amt: e.target.value })
                  }
                  type="number"
                  margin="10"
                />
              </FormControl>
            </Grid>
            <Divider />
            <Button
              sx={{ textAlign: "right", mt: 1, mr: 1 }}
              variant="contained"
              color="success"
              endIcon={<Send />}
              onClick={() => OnSubmit(formData)}
            >
              Save
            </Button>
            <Button
              onClick={() => navigate(-1)}
              sx={{ textAlign: "right", mt: 1 }}
              variant="outlined"
              endIcon={<ArrowBack />}
            >
              Back
            </Button>
          </Paper>
        </Box>
      )}
    </>
  );
}
