import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  FormHelperText,
  IconButton,
  Paper,
  Toolbar,
  Input,
} from "@mui/material";

import {
  Add,
  ArrowBack,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
  Send,
} from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import magento from "../../api/magento";
import { useParams } from "react-router-dom";

export default function EditTax({ navigate }) {
  const { TaxId, m } = useParams();
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [formData, setFormData] = useState({
    name: "",
    percentage: "",
    isDefault: 0,
    taxtype:'Sales',
  });

  const handleSelectChange = (e) => {
    // alert(e.target.value);
    setFormData({ ...formData, taxtype: `${e.target.value}` });
    return formData;
  };
  const DataCall = async () => {
    await magento
      .get("https://webapi.automarts.pk/tax/" + TaxId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setFormData(response.data[0]);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const OnSubmit = async (data) => {
    await magento
      .post("https://webapi.automarts.pk/tax/edit/" + TaxId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/tax/edit/` + TaxId + "?m=succesfully updated");
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const DeleteTax = async (TId) => {
    await magento
      .post("https://webapi.automarts.pk/tax/delete/" + TId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        navigate("admin/taxes");
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    DataCall();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Tax: {formData.name}
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "38%" }}
            >
              <TextField
                id="name"
                name="name"
                label="GST Title"
                variant="standard"
                value={formData.name}
                helperText="Enter GST Title"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                label="Percentage (%)"
                helperText="Enter percentage without % sign"
                variant="standard"
                value={formData.percentage}
                onChange={(e) =>
                  setFormData({ ...formData, percentage: e.target.value })
                }
                type="number"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <InputLabel id="taxtype">Tax Type</InputLabel>
           <Select
            labelId="taxtype"
            id="taxtype"
            onChange={handleSelectChange}
            placeholder="Tax Type"
            label="Tax Type"
            native
            variant="standard"
            defaultValue={formData.taxtype}
          >
            {
             formData.taxtype === 'Sales' 
              ? <><option key="sales" value="Sales" selected>Sales</option> <option key="Service" value="Service">Services</option></>
              :<><option key="sales" value="Sales">Sales</option> <option key="Service" value="Service" selected>Services</option></>
            }

          </Select>
          
            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Button
            onClick={() => DeleteTax(TaxId)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </Paper>
      </Box>
    </>
  );
}
