import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";

import {
  ArrowBack,
  Send,
} from "@mui/icons-material";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControl} from "@mui/material";
import magento from "../../api/magento";
import {profile} from '../../Helper/Helper';
import { useParams } from "react-router-dom";

export default function ViewPerson({ navigate }) {

  const {pId} = useParams();

  const [DataList, setDataList] = useState({
    CompanyList:[],
    VendorList:[]
  });

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    department: "",
    contact_type: "Company",
    channel_id: profile[0].id,
    conn_id: 0,
    phone:'',
    mobile:'',
    email:'',
    remarks:'',

  });

  const DataCall = async () =>{
    await magento.get('person/'+ pId).then((response)=>{
      console.log('NEW LET  ',response.data)
      let d = response.data[0];
      
      console.log('let ',d);
      setFormData({...formData, name: d.name,
        role: d.role,
        department: d.department,
        channel_id: d.channel_id,
        contact_type: d.contact_type,
        conn_id: d.conn_id,
        conn_nam: d.conn_name,
        phone:d.phone,
        mobile:d.mobile,
        email:d.email,
        remarks:d.remarks,
      })
      
      return formData;
    })
  }
  
  const CompanyCall = async () =>{
    await magento.get('channel/call/'+ profile[0].id).then((response)=>{
      console.log(response.data);
      setDataList(response.data)
      return DataList;
    })
  }

  React.useEffect(()=>{
    DataCall();
    CompanyCall();
  },[])

  return (
    
    <>
    {console.log(formData)}
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Contact Person
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
          <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "35%" }}
            >
             <TextField
                variant="standard"
                value={formData.contact_type}
                helperText="Contact Type"
                type="text"
                
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "61%" }}
            >
              <TextField
                  
                variant="standard"
                value={formData.conn_name}
                helperText={formData.contact_type +  " Name"}
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "0", width: "38%" }}
            >
              <TextField
                
                variant="standard"
                value={formData.name}
                helperText="Contact Person Name"
               
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                helperText="Enter Role or Designation"
                variant="standard"
                value={formData.role}
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                
                variant="standard"
                value={formData.department}
                helperText="Enter Department Name"
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                variant="standard"
                value={formData.email}
                helperText="Enter Email Address"
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                variant="standard"
                value={formData.phone}
                helperText="Enter Phone"
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                variant="standard"
                value={formData.mobile}
                helperText="Enter Mobile Name"
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "98%" }}
            >
              <TextField
                variant="standard"
                value={formData.remarks}
                helperText="Enter Remarks"
                multiline
                rows={2}
                type="text"
              />
            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="primary"
            endIcon={<Send />}
            onClick={() => navigate('/admin/person/edit/' + pId)}
          >
            Edit Contact Person
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
