import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";

import {
  ArrowBack,
  Send,
} from "@mui/icons-material";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel} from "@mui/material";
import magento from "../../api/magento";
import {profile} from '../../Helper/Helper';
import SelectDataComp from "../../components/Company/SelectDataComp";
import { useParams } from "react-router-dom";

export default function EditBrand({ navigate }) {

  const {pId} = useParams();

  const [DataList, setDataList] = useState({
    CompanyList:[],
    VendorList:[]
  });

  const [formData, setFormData] = useState({
    name: "",
    logo: "",
    website: "",
    description: "Company",
    type: "Industrial",
  });

  const DataCall = async () =>{
    await magento.get('http://localhost:3001/brand/'+ pId).then((response)=>{
      let d = response.data[0];
      console.log('let ',d);
      setFormData({...formData, 
        name: d.name,
        logo: d.logo,
        website: d.website,
        description: d.description,
        type: d.type,
      })
      return formData
    })
  }

  const handleSelectChange = (e) => {
    // alert(e.target.value);
    setFormData({ ...formData, type: `${e.target.value}` });
    return formData;
  };
  const OnSubmit = async (data) => {
    console.log(data);
    await magento
      .post("http://localhost:3001/brand/edit/"+pId, data)
      .then(function (response) {
        console.log(response.data);
        // navigate(`/admin/tax/edit/` + response.data.insertId);
        navigate(`/admin/brand/view/${response.data.insertId}`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  React.useEffect(()=>{
    DataCall();
  },[])

  return (
    
    <>
    {console.log(formData)}
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Brand
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />
          <Grid fluid sx={{ p: 2 }}>
          <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "0", width: "38%" }}
            >
              <TextField
                label="Brand Name"
                variant="standard"
                value={formData.name}
                helperText="Contact Person Name"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
              />
            </FormControl>
          <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "25%" }}
            >
            <InputLabel id="taxtype">Brand Type</InputLabel>
           <Select
            labelId="brndtype"
            id="brndtype"
            onChange={handleSelectChange}
            placeholder="Brand Type"
            label="Brand Type"
            native
            variant="standard"
          >
            <option key="Industrial" value="Industrial" selected>Industrial</option>
            <option key="Automotive" value="Automotive">Automotive</option>
            <option key="Parts" value="Parts">Parts</option>
            <option key="Tools" value="Tools">Tools</option>
            <option key="Other" value="Other">Other</option>
          </Select>
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                label="Website"
                helperText="Enter Website"
                variant="standard"
                value={formData.website}
                onChange={(e) =>  
                  setFormData({ ...formData, website: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "98%" }}
            >
              <TextField
                label="Description"
                variant="standard"
                multiline
                rows={4}
                value={formData.description}
                helperText="Enter Description"
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                type="text"
              />
            </FormControl>
          
          </Grid>
          
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save Contact Person
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
