import React, { useState, useEffect } from "react";
import "./../create.css";
import { Icon } from "semantic-ui-react";
import {
  Divider,
  Snackbar,
  Tab,
  Tabs,
  OutlinedInput,
  FormControl,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import QuoteIntoDc from "../../components/QuoteIntoDc";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NumberIntoWord from "../../components/NumberIntoWord";

import MuiAlert from "@mui/material/Alert";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ItemListing from "../../components/ItemListing";
import DataLayout from "../../components/DataLayout";
import QuoteIntoInv from "../../components/QuoteIntoInv";
import CreateWSTPDF from "../backups/CreateWSTPDF";
import PopOverComponent from "../../components/popover/PopOverComponent";
import DetailInfoRowComponents from "../../components/information/DetailInfoRowComponents";
import {getCall, FormattingNumber, postCall, PDFCheck } from "../../Helper/Helper";
import QuoteIntoSo from "../../components/QuoteIntoSo";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

const options = ["Expired", "Processed", "Partial Processed", "Generated", "Submitted"];    

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewQuotation({ navigate }) {
  const location = useLocation();
  const { quoteId } = useParams();
  const [message, setmessage] = useState("");
  const [data, setData] = useState("");
  const [value, setValue] = useState(0);
  const [pdfTemplate, setPdfTemplate] = useState(1);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const ChangeHandle = (event: SelectChangeEvent) =>
    setStatus(event.target.value);

  const infoCall = async () => {
    await getCall(`quotation/${quoteId}`).then((response) => {
      console.log(response.reson);
        setData(response);
        setStatus(response.Data[0].status);
        setPdfTemplate(response.Data[0].channel_id);
      
      return {data, status, pdfTemplate};
    })
  };

  const CreateSelectedItemListing = () => {
    return <ItemListing data={data} />;
  };


  const headdetails = () => {
   
    return (
      <>
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Quotation #{data.Data[0].quote_number}
        </Typography>
        <QuoteIntoSo data={data} navigate={navigate} />
        <QuoteIntoInv data={data} navigate={navigate} />
        <QuoteIntoDc data={data} navigate={navigate} />
        <PopOverComponent ButtonIcon="print" buttonTitle="Print"  menu={[
                  <Link
                  to={`/print/envelope/brown/quotation/${data.Data[0].id}`}
                  target="_blank"
                  style={{color:'#000'}}
                >
                  <Icon name="print" /> Print Envelope      
                </Link>,
                <Link
                  to={`/view/wst/pdf/${data.Data[0].id}`}
                  target="_blank"
                  style={{color:'#000'}}
                >
                  <Icon name="print" /> Print Without GST  
                </Link>,
                <Link
                      to={`/view/pdf/${data.Data[0].id}`}
                      target="_blank"
                      style={{color:'#000'}}
                      >
                   <Icon name="print" /> Print GST
                </Link>]} />
          <PopOverComponent ButtonIcon="file pdf" buttonTitle="Download" menu={[<PDFDownloadLink
          style={{color:'#000'}}
          fileName={
            data.channel &&
            data.channel[0].channel +
              "-Ref#" +
              data.Data[0].ref_number +
              "-Quote#" +
              data.Data[0].quote_number +
              ".pdf"
          }
          document={<CreateWSTPDF data={data} />}
        >
          <Icon name="file pdf" /> Download Without GST  
        </PDFDownloadLink>,<PDFDownloadLink
          style={{color:'#000'}}
          fileName={
            data.channel &&
            data.channel[0].channel +
              "- Ref#" +
              data.Data[0].ref_number +
              "-Quote#" +
              data.Data[0].quote_number +
              ".pdf"
          }
        document={PDFCheck(data)}
        >
          <Icon name="file pdf" /> Download
        </PDFDownloadLink>]} />
        
         
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue.target.value);
    return status;
  };

  const UpdateStatus = async () => {
    await postCall("quotation/update/status/" + quoteId,
      { status: status }).then((response) => {
        handleClickOpen();
        setmessage({
          message: "Status has been sccuessfully updated",
          status: "success",
        });
        return message;
    })
  };
  const StatusBar = (sdata) => {
    return (
      <>
        <FormControl
          style={{ display: "flex", flexDirection: "row" }}
          sx={{ m: 0, width: '100%' }}
        >
          <Select
            labelId="statusLabel"
            id="newstatus"
            onChange={handleStatusChange}
            placeholder="Update Status"
            style={{background:'#fff'}}
            input={
              <OutlinedInput
                sx={{
                  flex: 1,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                label="Status"
              />
            }
            MenuProps={MenuProps}
            native
          >
            {options.map((i, index) =>
              sdata === i ? (
                <option key={index} value={i} selected>
                  {i}
                </option>
              ) : (
                <option key={index} value={i}>
                  {i}
                </option>
              )
            )}
          </Select>
          <Button
            color="success"
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            size="small"
            variant="contained"
            onClick={() => UpdateStatus()}
          >
            Change
          </Button>
        </FormControl>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    infoCall();

    if (location.state) {
      handleClickOpen();
      setmessage({ message: location.state.message, status: "success" });
    } else {
      console.log("No Message");
    }
  }, []);

  return (
    <>
      {data.Data && (
        <DataLayout data={data} navigate={navigate} headdetails={headdetails}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert
              elevation={6}
              variant="filled"
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity={message.status}
            >
              {message.message}
            </MuiAlert>
          </Snackbar>
          <DetailInfoRowComponents data={data}/>
          <Divider />
          <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  disabled
                  fullWidth
                  id="quote_number"
                  label="Quotation #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.Data[0].quote_number}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="ref_number"
                  label="Reference #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  marginBottom="0"
                  value={data.Data[0].ref_number}
                />
                <br />
                <br />
                {StatusBar(data.Data[0].status)}
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="create_at"
                  label="Quotation Issue Date"
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={moment(data.Data[0].create_at).format(
                    "YYYY/MM/DD"
                  )}
                  disabled
                />
                <br />
                <br />
                
              </Grid>
              <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="terms"
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions"
                  multiline
                  sx={{ background: "#fff" }}
                  variant="outlined"
                  disabled
                  rows={3}
                  value={data.Data[0].terms}
                />
                <br/>
                <br/>
                <TextField
                fullWidth
                rows={3}
                multiline
                sx={{ background: "#fff" }}
                id="remarks"
                label="Remarks"
                placeholder="Please enter remarks"
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                
                value={data.Data[0].remarks}
                disabled
              />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
              <Typography variant="h6" color={"#555"} fontSize={20}>
                Details{" "}
                <Link
                  to={{ pathname: `/admin/quotation/edit/${data.Data[0].id}` }}
                  state={{ data }}
                >
                  <small>- Edit</small>
                </Link>
              </Typography>
            </Grid>

            <Grid textAlign={"right"} md={12} sx={{ px: 4 }}>
              {<>{data.Items && CreateSelectedItemListing()}</>}
            </Grid>
            <Grid md={12} sx={{ px: 4, my: 2 }}>
              {" "}
            </Grid>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography
                  textAlign={"left"}
                  variant="h6"
                  borderBottom={1}
                  borderColor="#ccc"
                >
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography
                  textAlign={"right"}
                  variant="h6"
                  borderBottom={1}
                  borderColor="#ccc"
                >
                  {FormattingNumber(data.Data[0].subtotal)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Additional
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(data.Data[0].additional_charges)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                {data.Data[0].gstinpercentage ? (
                  <Typography textAlign={"left"} variant="h6">
                    GST({data.Data[0].gstinpercentage}%)
                  </Typography>
                ) : (
                  <Typography textAlign={"left"} variant="h6">
                    Sales Tax (GST)
                  </Typography>
                )}
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(data.Data[0].gst_total)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6" color="error">
                  Discount
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6" color="error">
                  - {FormattingNumber(data.Data[0].discount)}
                </Typography>
              </Grid>
              <Grid
                md={6}
                sx={{ py: 1 }}
                borderTop={1}
                borderBottom={1}
                borderColor="#ccc"
              >
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid
                md={6}
                sx={{ py: 1 }}
                borderTop={1}
                borderBottom={1}
                borderColor="#ccc"
              >
                <Typography textAlign={"right"} variant="h5">
                  {FormattingNumber(data.Data[0].grand_total_gst)}
                </Typography>
              </Grid>
              <Grid textAlign={"left"} fontSize="12" md={12} sx={{ py: 1 }}>
                <strong>
                  <NumberIntoWord
                    number={parseInt(data.Data[0].grand_total_gst)}
                  />
                </strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid textAlign={"left"} md={12} sx={{ px: 0, py: 2 }}>
              <Divider />
              <Box sx={{ p: 1, py: 0 }} bgcolor={"#f8f8f8"} height="100">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="#000000"
                  variant="standard"
                  aria-label="full width tabs example"
                >
                  <Tab label={"DC"} />
                  <Tab label={"Rejection"} />
                  <Tab label={"Invoice"} />
                  <Tab label={"Logs"} />
                </Tabs>
              </Box>
              <Divider />

              <TabPanel value={value} index={0}>
                <Typography variant="h6">
                  DC againist on quotation #{data.Data[0].quote_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    { field: "dc_number", hideable: true, headerName: "DC#" },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                    },
                    {
                      field: "create_at",
                      hideable: true,
                      headerName: "Create at",
                    },

                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items",
                    },
                    {
                      field: "username",
                      hideable: true,
                      headerName: "Created By",
                    },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                    },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/dc/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.dcOnQuote}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography variant="h6">
                  Items Rejection againist on quotation #
                  {data.Data[0].quote_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    { field: "dc_number", hideable: true, headerName: "DC#" },
                    {
                      field: "quote_number",
                      hideable: true,
                      headerName: "Quote#",
                    },
                    {
                      field: "create_at",
                      hideable: true,
                      headerName: "Create at",
                    },

                    {
                      field: "rejected_qty",
                      hideable: true,
                      headerName: "Reject Items",
                    },
                    {
                      field: "reason",
                      hideable: true,
                      headerName: "Reason",
                      width: 200,
                    },
                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/dc/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.rejections}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography variant="h6">
                  Invoice againist on quotation #{data.Data[0].quote_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    { field: "dc_number", hideable: true, headerName: "DC#" },
                    {
                      field: "po_number",
                      hideable: true,
                      headerName: "PO#",
                    },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                    },
                    {
                      field: "create_at",
                      hideable: true,
                      headerName: "Create at",
                    },

                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items",
                    },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                    },
                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/dc/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.invOnQuote}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                {data.logs.map((item, index) => {
                  return (
                    <>
                      <Typography variant="p" sx={{ pb: 1 }}>
                        {item.description}
                      </Typography>
                      <Divider />
                    </>
                  );
                })}
              </TabPanel>
            </Grid>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 2 }}></Grid>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 2 }}></Grid>
          </Grid>
          <Divider />
          <Grid container></Grid>
        </DataLayout>
      )}
    </>
  );
}
