import React, { useState, useEffect } from "react";
import "./../create.css";
import { Header, Icon, Segment } from "semantic-ui-react";

import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  Snackbar,
  InputAdornment,
  FormControl,
  InputLabel,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Collapse,
  IconButton,
  Input,
} from "@mui/material";


import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import magento from "../../api/magento";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Link, useLocation, useParams } from "react-router-dom";
import EditItemComponent from "../../components/EditItemComponent";
import PreviewPopUp from "../../components/PreviewPopUp";
import SearchResultPopup from "../../components/SearchResultPopup";
import moment from "moment";
import SubNavBar from "../../components/SubNavBar";
import ModelPopup from "../../components/ModelPopup";
import ItemAddComponenet from "../../components/ItemAddComponenet";
import { LocalUpdate, ActiveUser, postCall, getCall, profile, taxes,today, usd, FormattingNumber } from "../../Helper/Helper";
import SelectPerson from "../../components/Select/SelectPerson";

export default function EditQuotation({ navigate }) {
  const location = useLocation();
  const { quoteId } = useParams();
  const [GlobalGST, setGlobalGST] = useState(18);
  const [Profile, setProfile] = useState([]);
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [disablebtn, setDisablebtn] = useState(true);
  const EmptyItem = {
    item_title: "",
    item_code: "",
    status: 1,
    item_qty: 1,
    qty: 1,
    price: 0,
    cost: 0,
    item_cost: 0,
    weight: 0,
    currency: "Rs.",
    unit_of_measure: "",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    deliveryStatus: "",
    channel_id: profile[0].id,
    id: 0,
  };
  const [formData, setFormData] = useState({
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    DeletedItems: [],
    PersonList:[],
    Channel: location.state.data.channel,
    SearchResult: ["No Item Found"],
    SelectedItems: location.state.data.Items,
    SubTotal: location.state.data.Data[0].subtotal,
    GrandTotal: location.state.data.Data[0].grand_total,
    GrandTotalGST: location.state.data.Data[0].grand_total_gst,
    CostTotal: location.state.data.Data[0].total_cost,
    GSTTotal: location.state.data.Data[0].gst_total,
    addtionalCharges: location.state.data.Data[0].additional_charges,
    discount: location.state.data.Data[0].discount,
    item_count_total: location.state.data.Data[0].total_items_count,
    total_items_qty: location.state.data.Data[0].total_items_qty,
    items_left: location.state.data.Data[0].items_left,
    created_by: location.state.data.Data[0].u,
    user_id: location.state.data.Data[0].uid,
    username: location.state.data.Data[0].username,
    edit_by: ActiveUser[0].username,
    edit_user_id: ActiveUser[0].id,
    create_at: location.state.data.Data[0].create_at,
    edit_username: ActiveUser[0].username,
    ref_number: location.state.data.Data[0].ref_number,
    terms: location.state.data.Data[0].terms,
    remarks: location.state.data.Data[0].remarks,
    company_id: location.state.data.Data[0].company_id,
    created_at: location.state.data.Data[0].created_at,
    quote_number: location.state.data.Data[0].quote_number,
    quote_to: location.state.data.Data[0].quote_to,
    quote_to_company: location.state.data.Data[0].quote_to_company,
    quote_to_email: location.state.data.Data[0].quote_to_email,
    quote_to_ntn: location.state.data.Data[0].quote_to_ntn,
    quote_to_phone: location.state.data.Data[0].quote_to_phone,
    quote_to_stn: location.state.data.Data[0].quote_to_stn,
    name: location.state.data.Data[0].name,
    role: location.state.data.Data[0].role,
    phone: location.state.data.Data[0].phone,
    
    unit_of_measure : location.state.data.Data[0].unit_of_measure,
  });
  const [status, setStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const PriceCalculate = (
    qty = 1,
    item,
    gst = GlobalGST,
    type = null,
    val = null
  ) => {
    item.cost ? (item.item_cost = item.cost) : (item.item_cost = 0);
    var saleTaxTotal = parseInt((gst / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((gst / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;

    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }

    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  const CalulateTotal = (
    data = formData.SelectedItems,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;
    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;

    // console.log(Additionalcharges, "Checking addtional charges");
    if (data.length > 0) {
      // console.log(vdata.length);
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );
      total_items_qty = parseInt(
        data.reduce(
          (prevValue, currentValue) => prevValue + currentValue.item_qty,
          0
        )
      );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    } else {
      let gdtotal =
        parseInt(SubTotal) + parseInt(Additionalcharges) - parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(GSTTotal) +
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    }
    return formData;
  };

  // Delete Item
  const DeleteItem = (data, dindex) => {
    ValidationMessage( "Item row deleted","error")
    formData.DeletedItems.push(data);
    setFormData({
      ...formData,
      SelectedItems: formData.SelectedItems.filter((item, index) => {
        return index !== dindex;
      }),
    });
  };

  const AddSelected = (
    data = EmptyItem
  ) => {
    PriceCalculate(1, data);

    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    handleClickOpen();
    return formData;
  };

  var ValidationMessage = (msg, status)=>{
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  } 
  const InsertQuotation = async (data) => {
    await postCall("quotations/edit/" + quoteId, data).then((response)=>{
      navigate("/admin/quotation/view/" + quoteId, {
        state: {
            message: "Quotation has been sucessfully Updated",
          },
      })
    }).catch(function(err){
      ValidationMessage(err.message,"error")
    })
  };

  const OnSubmitData = (data, profile) => {
    setDisablebtn(true);
    CalulateTotal(data);
    handleClickOpen();
    if (data.SelectedItems.length < 1) {
      setDisablebtn(false);
      setmessage({
        message: "Please add items in quotataion to proceed further",
        status: "error",
      });
      CalulateTotal();
      return message;
    } else if (data.created_at === null) {
      setDisablebtn(false);
      setmessage({ message: "Please select qotation date", status: "error" });
      return message;
    } else {
      CalulateTotal(data);
      InsertQuotation({ data, profile });
      return true;
    }
  };

  const  UpdateItem = (Uitem, extItem) => {
    extItem.item_id = Uitem.id;
    extItem.unit_of_measure = Uitem.unit_of_measure;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.item_price = Uitem.price;
    extItem.price = Uitem.price;
    extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    PriceCalculate(extItem.item_qty, extItem);
    return extItem;
  };

  const TableRowNew = (item, index) => {
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {/* <ItemSearchComponent addItems={(d) => {AddSelected(d); console.log(d)} } /> */}

            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              defaultValue={item.item_code}
              fullwidth
              size="small"
              width="100%"
              onChange={(e) =>
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "code",
                  e.target.value
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message: "Item information has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              value={item.item_title}
              fullWidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "title",
                  e.target.value
                );
              }}
            />
          </TableCell>
          <TableCell align="center" sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
              
            /> 
          </TableCell>
          <TableCell align="center" sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
          <TextField
              id={"item-mou-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="text"
              size="small"
              value={item.unit_of_measure}
              onChange={(e) => {
                item.unit_of_measure = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
          </TableCell>
          
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              sx={{
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              fullwidth
              type="number"
              size="small"
              width="100%"
              value={item.item_price}
              onChange={(e) => {
                item.item_price = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            align="right"
          >
            {item.item_gst_total && FormattingNumber(item.item_gst_total)}.00
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            align="right"
          >
            {item.item_row_total_gst && FormattingNumber(item.item_row_total_gst)}.00
          </TableCell>
        

          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            align="right"
          >
            <FormControl sx={{ minWidth: 100 }}>
              <Select
                labelId="status"
                id="status"
                value={item.delivery_status}
                onChange={(e) => {
                  item.delivery_status = e.target.value;
                  PriceCalculate(item.item_qty, item);
                  return formData;
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", p: 0, mx: 0, my: 0 }}
                size="small"
              >
                  <MenuItem sx={{ textAlign: "left" }} value="N/A">
                  N/A
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="Ex Stock">
                  Ex Stock
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Week">
                  1 Week
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1-2 Week">
                  1-2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2-3 Weeks">
                  2-3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Weeks">
                  3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3-4 Weeks">
                  3-4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4 Weeks">
                  4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4-5 Weeks">
                  4-5 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Month">
                  1 Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Months">
                  2 Months
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Months">
                  3 Months
                </MenuItem>
              </Select>
            </FormControl>
          </TableCell>

          <TableCell sx={{ px: "5px" }}>
            {item.item_id !== 0 ? (
              <ModelPopup
                mopen={true}
                title="Edit Item"
                content={<Edit fontSize="small" />}
                childComp={
                  <ItemAddComponenet
                    type="edit"
                    edata={item}
                    iid={item.item_id}
                    addItems={(newItem) => {
                      // console.log(newItem);
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message:
                            "Item information has been updated and row has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                }
              />
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  const ContactCall = async (data) =>{
    await getCall('persons/company/'+ data.id).then((response)=>{
      setFormData({...formData, PersonList: response})})
  }

  const infoCall = async () =>{
    Promise.all([
      setFormData({ ...formData, CompanyList: await getCall("company/channel/" + profile[0].id)})
    ])

  }
  const ChangeHandle = (event: SelectChangeEvent) =>
    setStatus(event.target.value);

  useEffect(() => {
    LocalUpdate();
    infoCall();
    
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Quotation #{formData.quote_number}
            </Typography>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <SubNavBar />
        <Paper sx={{ mx: "2%", mb: 5, borderRadius: 5, overflow: "hidden" }}>
       
          <Grid sx={{ px: 2, pt: 2 }} container spacing={2}>
            <Grid textAlign={"left"}  md={7} sx={{ px: 2, py: 4, pb: 0 }}>
              <CompanySelection data={formData} newCompany={false} fnCall={(e)=> e.id ? ContactCall(e): ''} />
            </Grid>
            <Grid md={5} sx={{ px: 2, py: 4, pb: 0 }}>
                <SelectPerson data={formData} dataList={formData.PersonList}  />
            </Grid>
            
            <Grid textAlign={"left"} md={5} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                TO: {formData.quote_to_company}
              </Typography>
              <Typography variant="p" marginBottom={0}>
                {formData.quote_to}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                STN#: {formData.quote_to_stn}
              </Typography>{" "}
              &nbsp; &nbsp;
              <Typography variant="p" marginBottom={0}>
                NTN#: {formData.quote_to_ntn}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                By: {profile[0].channel}{" "}
              </Typography>
              <Typography variant="p" marginBottom={0}>
                STN#: {profile[0].stn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                NTN#: {profile[0].ntn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                {profile[0].address}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {profile[0].phone}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                Generated By:
              </Typography>
              <Typography variant="p" marginBottom={0}>
                Name: {formData.name}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Role: {formData.role}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {formData.phone}
              </Typography>
              <br />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="quote_number"
                  label="Quotation #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.quote_number}
                  disabled
                />
                <Link to={"/admin/channel/edit/" + profile[0].id}>Edit</Link>

                <br />
                <br />
                <TextField
                  fullWidth
                  id="ref_number"
                  label="Reference #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.ref_number}
                  marginBottom="0"
                  onChange={(e) => {
                    setFormData({ ...formData, ref_number: e.target.value });
                    return formData;
                  }}
                />
                <br/>
                <br/>
                <Select
                  sx={{ background: "#fff" }}
                  label="Status"
                  value={status}
                  onChange={ChangeHandle}
                  defaultValue={() => {
                    return (
                      <>
                        <MenuItem value="Draft">Draft</MenuItem>
                      </>
                    );
                  }}
                  fullWidth
                  required
                  placeholder="Select Status"
                >
                  <MenuItem value="Draft">Draft</MenuItem>
                </Select>
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="create_at"
                  label="Quotation Issue Date"
                  type="date"
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(formData.create_at).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log(formData.create_at);
                    setFormData({ ...formData, QuoteDate: e.target.value, create_at: e.target.value });
                    return formData;
                  }}
                />
                <br />
                <br />
                <br />
               
              </Grid>

              <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="terms"
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions"
                  multiline
                  variant="outlined"
                  rows={6}
                  sx={{ background: "#fff" }}
                  value={formData.terms}
                  onChange={(e) => {
                    setFormData({ ...formData, terms: e.target.value });
                    return formData;
                  }}
                />
                <br/>
                <br/>
                <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                sx={{ background: "#fff" }}
                placeholder="Please enter remarks"
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                
                value={formData.remarks}
                rows={2}
                multiline
                onChange={(e) => {
                  setFormData({ ...formData, remarks: e.target.value });
                  return formData;
                }}
              />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
              <Typography variant="h6" color={"#777"} fontSize={20}>
                Details
              </Typography>
            </Grid>
            <Grid textAlign={"right"} md={12} sx={{ px: 4, pb: 4 }}>
              {formData.SelectedItems && (
                <>
                  {/* <FormControl fullWidth="true" style={{fontSize:"8px"}} >               */}
                  <Table
                    sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                    size="small"
                    aria-label="a dense table"
                    padding="0"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"50px"}
                        >
                          Sno.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"200px"}
                          align="left"
                        >
                          Item Code
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"500px"}
                        >
                          Product Description
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"100px"}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5", textAlign:'center' }}
                          align="center"
                          width={"50px"}
                        >
                          MOU
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          GST
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"200px"}
                        >
                          Row Total{" "}
                        </TableCell>
                        <TableCell sx={{ p: "2px" }} width={100}>
                          Status
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px" }}
                          width={50}
                          colSpan={2}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.SelectedItems.map((item, index) => {
                        return TableRowNew(item, index);
                      })}
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Button
                            onClick={() => {
                              AddSelected();
                              handleClickOpen();
                              setmessage({
                                message: "Item row has been added",
                                status: "success",
                              });
                            }}
                          >
                            Add New Row
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </FormControl> */}
                </>
              )}
            </Grid>

            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
             
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid textAlign={"left"} md={12} sx={{ pb: 2 }}>
                <TextField
                  fullWidth
                  id="discount"
                  label="Discount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.discount}
                  onChange={(e) => {
                    CalulateTotal(
                      formData,
                      formData.addtionalCharges,
                      e.target.value
                    );
                    return formData;
                  }}
                />
              </Grid>
              <Grid textAlign={"left"} md={12}>
                <TextField
                  fullWidth
                  id="additional_charges"
                  label="Additonal Charges"
                  type="number"
                  value={formData.addtionalCharges}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    CalulateTotal(formData, e.target.value);
                    return formData;
                  }}
                />
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.SubTotal)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <FormControl sx={{ minWidth: 300 }}>
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                  >
                    GST
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={GlobalGST}
                    onChange={(e) => {
                      setGlobalGST(e.target.value);
                      formData.SelectedItems.map((item) =>
                        PriceCalculate(item.item_qty, item, e.target.value)
                      );
                      return GlobalGST;
                    }}
                    autoWidth
                    fullWidth
                    sx={{ textAlign: "left" }}
                    size="small"
                    label="GST"
                  >
                    <MenuItem sx={{ textAlign: "left" }} value="0">
                      No Tax
                    </MenuItem>
                    {taxes.map((item) => {
                      return (
                        <MenuItem
                          sx={{ textAlign: "left" }}
                          value={item.percentage}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <Typography textAlign={"left"} variant="h6">
                Sales Tax (GST)
                </Typography> */}
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.GSTTotal)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h5">
                  {FormattingNumber(formData.GrandTotalGST)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              <PreviewPopUp data={[formData, profile[0]]} />
            </Grid>
            <Grid textAlign={"right"} md={5} sx={{ px: 4, py: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="inherit"
                onClick={() => {
                  CalulateTotal();
                  setDisablebtn(false);
                  console.log(formData);
                  localStorage.setItem(
                    "previewdata",
                    JSON.stringify(formData, profile)
                  );
                }}
                sx={{ mx: 3 }}
              >
                Re-Calculate
              </Button>
              <Button
                onClick={() => OnSubmitData(formData, Profile)}
                type="submit"
                variant="contained"
                color="success"
                size="large"
                disabled={disablebtn}
              >
                Save Quotation
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
