import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import magento from "../api/magento";
import { AuthContext } from "../Context/AuthContext";
import { FormControlUnstyled } from "@mui/base";
import NotificationMessage from "../components/NotificationMessage";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.automarts.pk">
        ZA Auto
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
export default function SignIn({ navigate }) {
  const { setAuth } = React.useContext(AuthContext);
  const [message, setmessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Data, setData] = React.useState({
    username: "",
    password: "",
    SelectedChannel: "",
    ChannelList: [],
  });
  const TaxCall = async () => {
    await magento
      .get("taxes", {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        localStorage.setItem("taxes", JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const ProfileCall = async () => {
    await magento
      .get("channels", {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        const Ch = response.data;
        const ListCH = [];
        Ch.map((item) =>
          ListCH.push({ key: item.id, text: item.channel, value: item.id })
        );
          setData({ ...Data, ChannelList: Ch, channelOptionList: ListCH });
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const ChannelDataSelected = (cid) => {
    let iData = [];
    Data.ChannelList.map((i, index) => {
      if (i.id === cid) {
        return iData.push(i);
      }
    });
    return iData;
  };

  const call = async (username, password, channel) => {
    if (channel !== "") {
      await magento
        .post(
          "user",
          // "http://localhost:3001/user",

          {
            username,
            password,
            channel,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          if (response.data === "Please check username is Incorrect") {
            setmessage({ message: response.data, status: "error" });
            handleClickOpen();
            return message;
          } else if (
            response.data ===
            "Please Check you Password, Password is in Correct"
          ) {
            setmessage({ message: response.data, status: "error" });
            handleClickOpen();
            return message;
          } else if (
            response.data === "The User Is Not Authorised To This Channel"
          ) {
            setmessage({ message: response.data, status: "error" });
            handleClickOpen();
            return message;
          } else {
            handleClickOpen();
            setmessage({
              message: "Successfully logged In",
              status: "success",
            });
            setAuth();
            localStorage.setItem("channel", channel);
            localStorage.setItem(
              "channelDetail",
              JSON.stringify(ChannelDataSelected(channel))
            );
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("LoggedIn", 1);
            navigate("admin/dashboard");
          }
          // console.log(ChannelDataSelected(channel));
        })
        .catch(function (err) {
          console.log(err.response);
        });
    } else {
      handleClickOpen();
      setmessage({ message: "Please select Channel first", status: "error" });
      return message;
    }
  };
 
  const handleChange = (event: SelectChangeEvent) => {
    setData({ ...Data, SelectedChannel: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("username"),
      password: data.get("password"),
      channel: data.get("channel"),
    });
    call(data.get("username"), data.get("password"), Data.SelectedChannel);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  React.useEffect(() => {
    TaxCall();
    if (localStorage.getItem("LoggedIn") === "1") {
      navigate("admin/dasboard");
    }
    ProfileCall();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <NotificationMessage open={open} message={message} handleClose={handleClose} />
          <img
            src={() => {
              console.log('login',Data.ChannelList[0]);
              if (Data.SelectedChannel.length > 0) {
                return (
                  "https://accounting.automarts.pk/assets/products/" +
                  Data.SelectedChannel.logo
                );
              } else {
                return "https://accounting.automarts.pk/assets/products/logo512.png";
              }
            }}
            width={"100px"}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl fullWidth defaultValue="" required>
              <InputLabel id="channel">Select Channel</InputLabel>
              <Select
                required
                labelId="channel"
                id="channel"
                name="channel"
                value={Data.SelectedChannel}
                label="Select Channel"
                onChange={(e, data) => handleChange(e)}
                defaultValue={() => <MenuItem value="1">ZA Autos</MenuItem>}
              >
                {Data.ChannelList.map((i) => {
                  return <MenuItem value={i.id}>{i.channel}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControlUnstyled
              onSubmit={() =>
                call(Data.username, Data.password, Data.SelectedChannel)
              }
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={Data.username}
                onChange={(e) => setData({ ...Data, username: e.target.value })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={Data.password}
                onChange={(e) => setData({ ...Data, password: e.target.value })}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </FormControlUnstyled>
            <Grid container>
              <Grid item xs>
                
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
