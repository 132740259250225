import React, { useEffect, useState } from "react";
import "./../create.css";
import { Segment, SegmentGroup, GridColumn } from "semantic-ui-react";
import magento from "../../api/magento";
import {
  AppBar,
  Divider,
  FormHelperText,
  InputAdornment,
  Paper,
  Switch,
  Toolbar,
} from "@mui/material";
import country from "./country.json"; 
import { ArrowBack, Send } from "@mui/icons-material";
import { Form, Input, Icon } from "semantic-ui-react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocation, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
const currency = [
  { key: "R", text: "Rs.", value: "Rs." },
  { key: "u", text: "US Dollar", value: "$" },
  { key: "p", text: "UK Pounds", value: "£" },
  { key: "d", text: "UAE Dirham", value: "AED" },
];
const unit_of_measure = [
  { key: "k", text: "Kilogram", value: "kg" },
  { key: "p", text: "Pounds", value: "Lbs." },
  { key: "l", text: "Litre", value: "Ltr." },
  { key: "c", text: "Carton", value: "Ctn." },
];
export default function MageEditProducts({ popup, navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const {pId} = useParams();
  // const ChannelData = location.state;
  const [data, setData] = useState([]);
  const [Token, setToken] = useState([]);
  const [MgsBrand, setMgsBrand] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    productname: null,
    sku: null,
    part_no: null,
    weight: 1,
    currency: "USD",
    unit_of_measure: "kg",
    vendorName: null,
    vendorPrice: 0,
    cost: 0,
    status: true,
    shippingRates: 0,
    finalPriceCalculated: 0,
    shippingCalculatedRates: 0,
    level0: [],
    level1: [],
    level2: [],
    level3: [],
  });


  const ItemCall = async()=>{
    await magento.get('https://www.automarts.pk/rest/default/V1/products/'+ pId,{
      headers: {
        Authorization: "Bearer roupt89d3ocxn677kemh7vfb64s1jkx3",
        'Content-Type': "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    }).then((response)=>{
      console.log(response.data);
      setData(response.data);
      setData({
        productname: response.data.name,
        sku: response.data.sku,
        part_no: null,
        weight: 1,
        currency: "USD",
        unit_of_measure: "kg",
        vendorName: null,
        vendorPrice: 0,
        cost: 0,
        status: true,
        shippingRates: 0,
        finalPriceCalculated: response.data.price,
        shippingCalculatedRates: 0,
        data: response.data});
      return formData;
    }).catch((err)=>{
      console.log(err)
    })

  }
  const convert = (e) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(e);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
    // check max. file size is not exceeded
  };
  const UploadImage = async (data) => {
    console.log(formData);
  };

  const BrandSelected = (event: SelectChangeEvent, data) => {
    // setAge(event.target.value as string);
    formData.mgs_brand = event.target.value;
    setFormData({ ...formData, mgs_brand: event.target.value });
    console.log(formData.mgs_brand);
  };

  const SelectCategories = (event: SelectChangeEvent, data, level) => {
    // setFormData({ ...formData, mgs_brand: event.target.value });
    // console.log(data.length);
    data.forEach((element) => {
      if (
        element.children_data.length > 0 &&
        element.id === event.target.value
      ) {
        if (level === "level0") {
          formData.level2 = [];
          formData.level3 = [];
          formData.level1 = element.children_data;
          console.log(formData);
          return setFormData({
            ...formData,
            level1: element.children_data,
            level2: [],
            level3: [],
          });
        }

        if (level === "level1") {
          formData.level3 = [];
          formData.level2 = element.children_data;
          console.log(formData);
          return setFormData({
            ...formData,
            level3: [],
            level2: element.children_data,
          });
        }

        if (level === "level2") {
          formData.level3 = element.children_data;
          console.log(formData.level3);
          return setFormData({ ...formData, level3: element.children_data });
        }
      }
      if (
        element.children_data.length === 0 &&
        element.id === event.target.value
      ) {
        formData.category_id = element.id;
        console.log(formData.category_id, "Selected category");
        if (level === "level0") {
          return setFormData({
            ...formData,
            category_id: element.id,
            level1: [],
            level2: [],
            level3: [],
          });
        }
        if (level === "level1") {
          return setFormData({
            ...formData,
            category_id: element.id,
            level2: [],
            level3: [],
          });
        }
        if (level === "level2") {
          return setFormData({
            ...formData,
            category_id: element.id,
            level3: [],
          });
        }
      }
    });
    // data.children_data.map((i) => {
    //   if (event.target.value === i.id && i.children_data.length > 0) {
    //     console.log("yes I have Children");
    //   } else {
    //     console.log("No I have Children");
    //   }
    // });

    // console.log(data);
  };
  const handleChange = (event: SelectChangeEvent, data) => {
    // setAge(event.target.value as string);
    setFormData({ ...formData, currency: event.target.value });
    CalculateDetails("", data, "Currency", event.target.value);
  };

  const handleSelectChange = (e, data, update = "currency") => {
    switch (update) {
      case "currency":
        setFormData({ ...formData, currency: data.value });
        break;
      case "unit_of_measure":
        setFormData({ ...formData, unit_of_measure: data.value });
        break;
      case "status":
        setFormData((prevState) => ({
          ...formData,
          status: !prevState.status,
        }));
        console.log(formData.status);
        break;
      case "image":
        // console.log(e.target.files)
        convert(e.target.files[0]).then((result) => {
          setFormData({
            ...formData,
            image: e.target.files,
            imageName: e.target.files[0].name,
            base: result.substr(result.indexOf(",") + 1),
          });
        });

        // console.log(
        //   Buffer.from(e.target.files[0].name, "utf-8").toString("base64"),
        //   "esttt"
        // );

        break;
      default:
        setFormData({ ...formData, currency: `${data.value}` });
        break;
    }
  };

  const CalculateDetails = (value, data, type, curr = "USD") => {
    if (type === "Currency") {
      console.log(
        JSON.parse(localStorage.getItem(`${curr.toLowerCase()}`)) *
          data.vendorPrice
      );
      data.shippingCalculatedRates =
        Number(data.shippingRates) * Number(data.weight);
      data.cost =
        Number(JSON.parse(localStorage.getItem(`${curr.toLowerCase()}`))) *
        Number(data.vendorPrice);
      data.finalPriceCalculated =
        Number(data.shippingCalculatedRates) + Number(formData.cost);
    }
    if (type === "shippingRates") {
      data.shippingCalculatedRates = Number(value) * Number(data.weight);
      data.shippingRates = Number(value);
    }
    if (type === "vendorPrice") {
      data.vendorPrice = Number(value);
      data.cost =
        Number(JSON.parse(localStorage.getItem(`${curr.toLowerCase()}`))) *
        Number(value);
      data.finalPriceCalculated =
        Number(data.shippingCalculatedRates) + Number(formData.cost);
    }
    return data;
  };

  const getbrand = async () => {
    await magento
      .get(
        "https://www.automarts.pk/rest/default/V1/products/attributes/mgs_brand/options",
        {
          headers: {
            Authorization: "Bearer " + Token,
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMgsBrand(res.data);
        getCategories();
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = async () => {
    await magento
      .get("https://www.automarts.pk/rest/default/V1/categories/", {
        headers: {
          Authorization: "Bearer " + Token,
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategories(res.data.children_data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ImageUpload = async (data) => {
    // media_gallery_entries: [
    //   {
    //     id: 0,
    //     media_type: "image",
    //     label: formData.productname,
    //     position: 0,
    //     disabled: false,
    //     file: formData.imageName,
    //     types: ["image", "small_image", "thumbnail"],
    //     content: {
    //       base64_encoded_data: base64,
    //       type: formData.image[0].type,
    //       name: formData.imageName,
    //     },
    //   },
    // ],
    await magento
      .post(
        "https://www.automarts.pk/rest/V1/products/" + data.sku + "/media",
        {
          entry: {
            media_type: "image",
            label: formData.productname,
            position: 1,
            disabled: false,
            types: ["image", "small_image", "thumbnail"],
            content: {
              base64_encoded_data: formData.base,
              type: formData.image[0].type,
              name: formData.imageName,
            },
          },
        },
        {
          headers: {
            Authorization: "Bearer "  + Token,
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return true;
        // window.open(
        //   "https://www.automarts.pk/" + data.custom_attributes[0].value,
        //   "noreferrer"
        // );
      })
      .catch((err) => {
        console.log(err, "imageupload");
      });
  };
  const OnSubmit = (data) => {
    handleClickOpen();
    if (data.sku === null || data.sku === "") {
      setmessage({
        message: "Please fill required Field SKU",
        status: "error",
      });
    } else if (data.productname === null || data.productname === "") {
      setmessage({
        message: "Please fill required field Product Name",
        status: "error",
      });
    } else if (data.finalPriceCalculated === 0) {
      setmessage({
        message: "Please Calculate Final Price",
        status: "error",
      });
    } else if (!data.mgs_brand) {
      setmessage({
        message: "Please Select Brand",
        status: "error",
      });
    } else if (!data.country) {
      setmessage({
        message: "Please Select Made In Country",
        status: "error",
      });
    } else if (!data.category_id) {
      setmessage({
        message: "Please Select Category",
        status: "error",
      });
    } else {
      InsertItem(data);
    }
  };
  const AddItem = async (data, mageData) => {
    console.log(mageData);
    await magento
      .post(
        "https://webapi.automarts.pk/item/create",
        {
          item_title: data.productname,
          item_code: "MA-" + data.sku,
          status: data.sta,
          price: data.finalPriceCalculated,
          cost: data.cost,
          weight: data.weight,
          currency: data.currency,
          unit_of_measure: data.unit_of_measure,
          image: [],
          imageName: "",
          sku: data.sku,
          purchase_description: data.short_description,
          sales_description: data.long_description,
          part_no: data.part_no,
          item_types: "",
          location: data.country,
          channel_id: 0,
          url_key: mageData.custom_attributes[0].value,
          magento_id: mageData.id,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        window.open(
          "https://www.automarts.pk/" + mageData.custom_attributes[0].value,
          "noreferrer"
        );
        navigate(-1);
        // popup();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const InsertItem = async (data) => {
    await magento
      .post(
        "https://www.automarts.pk/rest/V1/products",
        {
          product: {
            sku: formData.sku,
            name: formData.productname,
            attribute_set_id: 4,
            price: formData.finalPriceCalculated,
            status: formData.status === true ? 1 : 0,
            visibility: 4,
            type_id: "simple",
            weight: formData.weight,

            extension_attributes: {
              category_links: [
                {
                  position: 0,
                  category_id: String(formData.category_id),
                },
              ],
            },
            custom_attributes: [
              {
                attribute_code: "mgs_brand",
                value: String(formData.mgs_brand),
              },
              {
                attribute_code: "country_of_manufacture",
                value: String(formData.country),
              },
            ],
          },
        },
        {
          headers: {
            Authorization: "Bearer " + Token,
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        formData.image && ImageUpload(response.data);
        AddItem(data, response.data);
        // navigate(-1);
        // popup();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  
  const CallingAuth = async () => { 
    await magento.post(
      "https://www.automarts.pk/rest/default/V1/integration/admin/token",
      {
        username:"admin",
        password:"ahsan123"
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response)=>{
      setToken(response.data)
      console.log('Checking Pasword', response.data)
      
      
    }).catch((error) => console.log(error))



  }


  useEffect(() => {
    CallingAuth();
    // console.log(ChannelData, "New");
    getbrand();
    ItemCall();
    
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Magento: {profile && formData.name} 
            </Typography>
          </Toolbar>
        </AppBar>

        
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Paper sx={{ mx: "2%", py: 2, width: "70%" }}>
            <Grid
              sx={{
                p: 2,
                justifyContent: "start",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                textAlign="left"
                sx={{
                  mb: 2,
                  mr: "2%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <label>Status</label>
                <Switch
                  checked={data.status}
                  onChange={(e) => handleSelectChange(e, [], "status")}
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "70%" }}
              >
                <TextField
                  helperText="Enter product name for Magento"
                  fullWidth
                  variant="standard"
                  value={data.productname}
                  onChange={(e) =>
                    setData({ ...data, productname: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <Select
                  required
                  labelId="mgs_brand"
                  id="mgs_brand"
                  name="mgs_brand"
                  variant="standard"
                  value={data.msg_brand}
                  label="Select Brand"
                  onChange={(e, data) => BrandSelected(e)}
                  defaultValue={() => (
                    <MenuItem value="">Select Brand</MenuItem>
                  )}
                >
                  {MgsBrand.map((i) => {
                    return <MenuItem value={i.value}>{i.label}</MenuItem>;
                  })}
                </Select>
                <small className="mutedMuiFormHelperText-root MuiFormHelperText-sizeMedium css-1d1r5q-MuiFormHelperText-root">
                  Select Brand
                </small>
              </FormControl>

              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "35%" }}
              >
                <Select
                  required
                  labelId="country"
                  id="country"
                  variant="standard"
                  name="country"
                  value={data.country}
                  label="Select Brand"
                  onChange={(e, data) => {
                    data.country = e.target.value;
                    console.log(data.country);
                  }}
                  defaultValue={() => (
                    <MenuItem value="">Select Brand</MenuItem>
                  )}
                >
                  {country.country.map((i) => {
                    return <MenuItem value={i.value}>{i.label}</MenuItem>;
                  })}
                </Select>
                <small className="mutedMuiFormHelperText-root MuiFormHelperText-sizeMedium css-1d1r5q-MuiFormHelperText-root">
                  Select Country to Display Seal
                </small>
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "43%" }}
              ></FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "30%" }}
              >
                <TextField
                  helperText="Enter Product sku"
                  variant="standard"
                  value={data.sku}
                  onChange={(e) =>
                    setData({ ...data, sku: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="Enter Part No."
                  variant="standard"
                  value={data.part_no}
                  onChange={(e) =>
                    setData({ ...data, part_no: e.target.value })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "15%" }}
              >
                <TextField
                  helperText="enter weight"
                  variant="standard"
                  value={data.weight}
                  type="number"
                  onChange={(e) => {
                    setData({
                      ...data,
                      weight: e.target.value,
                      shippingCalculatedRates:
                        Number(e.target.value) * Number(data.shippingRates),
                      finalPriceCalculated:
                        Number(e.target.value) *
                          Number(data.shippingRates) +
                        Number(data.cost),
                    });
                  }}
                  margin="10"
                />
              </FormControl>
              <FormControl textAlign="left" sx={{ mb: 2, width: "15%" }}>
                <TextField
                  disabled
                  helperText="KG or LBs"
                  variant="standard"
                  defaultValue={formData.unit_of_measure}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      unit_of_measure: e.target.value,
                    })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <br />
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "30%" }}
              >
                <TextField
                  id="VendorName"
                  name="vendorName"
                  variant="standard"
                  value={formData.vendorName}
                  helperText="Enter Item Code"
                  onChange={(e) =>
                    setFormData({ ...formData, vendorName: e.target.value })
                  }
                  type="text"
                />
              </FormControl>

              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="Enter vendor Price"
                  variant="standard"
                  value={formData.vendorPrice}
                  onChange={(e) => {
                    CalculateDetails(
                      e.target.value,
                      formData,
                      "vendorPrice",
                      formData.currency
                    );
                    setFormData({
                      ...formData,
                      vendorPrice: Number(e.target.value),
                      cost: Number(
                        JSON.parse(
                          localStorage.getItem(
                            `${formData.currency.toLowerCase()}`
                          )
                        ) * e.target.value
                      ),
                      finalPriceCalculated:
                        Number(formData.shippingCalculatedRates) +
                        Number(formData.cost),
                    });
                    console.log(formData);
                    return formData;
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Select
                          sx={{}}
                          labelId="currency"
                          id="currency"
                          name="currency"
                          variant="standard"
                          required
                          value={formData.currency}
                          onChange={(e) => {
                            handleChange(e, formData);
                            setFormData({
                              ...formData,
                              currency: e.target.value,
                              shippingCalculatedRates:
                                Number(formData.shippingRates) *
                                Number(formData.weight),
                              cost:
                                Number(
                                  JSON.parse(
                                    localStorage.getItem(
                                      `${e.target.value.toLowerCase()}`
                                    )
                                  )
                                ) * Number(formData.vendorPrice),
                              finalPriceCalculated:
                                Number(formData.shippingCalculatedRates) +
                                Number(formData.cost),
                            });
                          }}
                        >
                          <MenuItem value="PKR">PKR</MenuItem>

                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="GBP">GBP</MenuItem>
                          <MenuItem value="AED">AED</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="This is Item Actual Cost"
                  variant="standard"
                  disabled
                  value={formData.cost}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">PKR</InputAdornment>
                    ),
                  }}
                  margin="10"
                />
              </FormControl>

              <br />
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="Enter shipping Rates"
                  variant="standard"
                  value={formData.shippingRates}
                  onChange={(e) => {
                    CalculateDetails(
                      e.target.value,
                      formData,
                      "shippingRates",
                      formData.currency
                    );
                    setFormData({
                      ...formData,
                      shippingRates: Number(e.target.value),
                      shippingCalculatedRates:
                        Number(e.target.value) * Number(formData.weight),
                      finalPriceCalculated:
                        Number(e.target.value) * Number(formData.weight) +
                        Number(formData.cost),
                    });
                    console.log(formData);
                    return formData;
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">PKR</InputAdornment>
                    ),
                  }}
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="Shipping Rate Calculated"
                  variant="standard"
                  value={formData.shippingCalculatedRates}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">PKR</InputAdornment>
                    ),
                  }}
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "25%" }}
              >
                <TextField
                  helperText="This is Final Price Calculated"
                  variant="standard"
                  disabled
                  value={formData.finalPriceCalculated}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">PKR</InputAdornment>
                    ),
                  }}
                  margin="10"
                />
              </FormControl>
              <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
                <TextField
                  class="textArea"
                  multiline
                  maxRows={8}
                  minRows={2}
                  label="Short Description"
                  helperText="Please enter Short Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      short_description: e.target.value,
                    });
                    console.log(formData.short_description);
                    return formData;
                  }}
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
                <TextField
                  multiline
                  maxRows={8}
                  minRows={2}
                  label="Long Decription"
                  helperText="Enter items long Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      long_description: e.target.value,
                    })
                  }
                  type="text"
                  margin="10"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mt: 1, mb: 2, mr: "2%", width: "100%" }}
              >
                <h3 style={{ textAlign: "left" }}>Category Selection</h3>
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mr: "2%", width: "23%" }}
              >
                {/* <label>Categories</label> */}
                <Select
                  required
                  labelId="catLevel0"
                  id="catLevel0"
                  name="catLevel0"
                  value={formData.catLevel0}
                  label="Caegories Level0"
                  onSelect={(e) => console.log(e.target.value)}
                  onChange={(e, data) =>
                    SelectCategories(e, Categories, "level0")
                  }
                  defaultValue={() => (
                    <MenuItem value="">Category Level0</MenuItem>
                  )}
                >
                  {Categories.map((i, index) => {
                    return (
                      <MenuItem key={index} value={i.id}>
                        {i.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {formData.level1.length > 0 ? (
                <FormControl
                  textAlign="left"
                  sx={{ mb: 2, mr: "2%", width: "23%" }}
                >
                  {/* <label>Categories</label> */}
                  <Select
                    required
                    labelId="catLevel1"
                    id="catLevel1"
                    name="catLevel1"
                    value={formData.catLevel1}
                    label="Select Caegories"
                    onChange={(e, data) =>
                      SelectCategories(e, formData.level1, "level1")
                    }
                    defaultValue={() => (
                      <MenuItem value="">Select Category Level1</MenuItem>
                    )}
                  >
                    {formData.level1.map((i, index) => {
                      return (
                        <MenuItem key={index} value={i.id}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
              {formData.level2.length > 0 ? (
                <FormControl
                  textAlign="left"
                  sx={{ mb: 2, mr: "2%", width: "23%" }}
                >
                  {/* <label>Categories</label> */}
                  <Select
                    required
                    labelId="catLevel2"
                    id="catLevel2"
                    name="catLevel2"
                    value={formData.catLevel2}
                    label="Select Caegories"
                    onChange={(e, data) =>
                      SelectCategories(e, formData.level2, "level2")
                    }
                    defaultValue={() => (
                      <MenuItem value="">Select Category Level2</MenuItem>
                    )}
                  >
                    {formData.level2.map((i, index) => {
                      return (
                        <MenuItem key={index} value={i.id}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}

              {formData.level3.length > 0 ? (
                <FormControl
                  textAlign="left"
                  sx={{ mb: 2, mr: "2%", width: "23%" }}
                >
                  {/* <label>Categories</label> */}
                  <Select
                    required
                    labelId="catLevel3"
                    id="catLevel3"
                    name="catLevel3"
                    value={formData.catLevel3}
                    label="Select Caegories"
                    onChange={(e, data) =>
                      SelectCategories(e, formData.level3, "level3")
                    }
                    defaultValue={() => (
                      <MenuItem value="">Select Category Level2</MenuItem>
                    )}
                  >
                    {formData.level3.map((i, index) => {
                      return (
                        <MenuItem key={index} value={i.id}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
            </Grid>
            <Divider />
            <Button
              sx={{ textAlign: "right", mt: 1, mr: 1 }}
              variant="contained"
              color="success"
              endIcon={<Send />}
              onClick={() => OnSubmit(formData)}
            >
              Save
            </Button>
            <Button
              onClick={() => navigate(-1)}
              sx={{ textAlign: "right", mt: 1 }}
              variant="outlined"
              endIcon={<ArrowBack />}
            >
              Back
            </Button>
          </Paper>
          <Paper sx={{ mx: "2%", py: 2, width: "30%" }}>
            <Form
              size="large"
              onSubmit={() => UploadImage(formData)}
              encType="multipart/form-data"
            >
              <input
                name="image"
                type="file"
                accept="image/*"
                onChange={(e) => handleSelectChange(e, [], "image")}
              />
              <br />
              <br />
              <Button type="submit" fluid secondary>
                Upload Image
              </Button>
            </Form>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
