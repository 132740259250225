import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";

export default function MageProducts({ navigate, props }) {
  const [Token, setToken] = useState('');
  const [terms, setTerm] = useState('plug');
  const { pageNumber} =  useParams();
  const [mProducts, setMProducts] = useState([]);
  const [FRow, setFRow] = useState({});
  const [CurrentPage, setCurrentPage] = useState(!pageNumber? 1 : pageNumber);
  const [ItemPerPage, setItemPerPage] = useState(100);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  // var CurrentItems = mProducts.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    // CurrentItems = mProducts.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };

  const CallingAuth = async () => { 
    await magento.post(
      "https://www.automarts.pk/rest/default/V1/integration/admin/token",
      {
        username:"admin",
        password:"ahsan123"
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response)=>{
      setToken(response.data)
      console.log('Checking Pasword', response.data)
      
      
    }).catch((error) => console.log(error))



  }

  const CallmProducts = async (t) => {
    console.log(t)
    await magento.get(
      "https://www.automarts.pk/rest/default/V1/products",    {
        params: {
          "searchCriteria[filterGroups][0][filters][0][field]": "name",
          "searchCriteria[filterGroups][0][filters][0][value]": `%${t}%`,
          "searchCriteria[filterGroups][0][filters][0][conditionType]": "like",
        //   // "searchCriteria[sortOrders][0][field]": "entity_id",
        //   // "searchCriteria[sortOrders][0][direction]": "ASC",
          "searchCriteria[pageSize]": ItemPerPage,
          "searchCriteria[currentPage]": CurrentPage,
          "fields":"items[id,sku,name,price,weight,status,custom_attributes]",
          
        },
        headers: {
          Authorization: "Bearer "+ Token,
          'Content-Type': "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      }
    ).then(function (response) {
        console.log('responses', response.data)
          setMProducts(response.data.items);
        
        // setFRow(response.data);
        // return mProducts;
      })
      .catch(function (error) {
        console.log("Error: ", error);
      });
  };

  const SearchQuote = async (quoteNo) => {
    await magento
      .get(
        "https://webapi.automarts.pk/search/quote/channel/" +
          localStorage.getItem("channel") +
          "/" +
          quoteNo,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if(response.data.length !== null){
          setMProducts(response.data);
          return mProducts;

        }
        return
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallingAuth();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Magento Products
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left">
            <FormControl sx={{mb:3, width:'60%', display:'flex', flexDirection:'row', justifyContent:'space-between'}} >
              <TextField sx={{background:'#fff', flex:4, marginRight:2}} size="small" value={terms} label='Search Magento Item' onChange={(e)=>{
                setTerm(e.target.value);
                return  setTerm;
              }
              
              } />
               <Button sx={{flex:1}}type="submit" onClick={()=>CallmProducts(terms)} 
                            variant="contained"
                            >
                Search Item
                </Button>
            </FormControl>
             
          </Grid>
        </Grid>

        <DataGrid
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            created_by: false,
            id: false,
            grand_total_gst: false,
          }}
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 20 },
            {
              field: "id",
              hideable: true,
              headerName: "id#",
              width: 2,
            },
            {
              field: "sku",
              hideable: true,
              headerName: "SKU",
              width: 150,
            },
            {
              field: "name",
              hideable: true,
              headerName: "Product Name",
              width: 300,
             
            },
            { field: "price", hideable: true, headerName: "Price" },
            
            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`edit/${GridRowParams.row.sku}/`}>
                  Edit  
                </Link>,
                <a target="_blank"  href={`https://www.automarts.pk/${GridRowParams.row.custom_attributes}/`}>
                View
              </a>,
              ],
            },
          ]}
          rows={mProducts}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/mproducts/create")}
          >
            <Add font-size="18" />
          </Fab>
          {mProducts && mProducts.length}
        </div>
      </Box>
    </>
  );
}
