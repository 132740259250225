import React, { useEffect, useState } from "react";
import "./brands.css";
import magento                    from "../../api/magento";
import SubNavBar                  from "../../components/SubNavBar";
import { Link }                   from "react-router-dom";
import { Add, MenuOutlined }      from "@mui/icons-material";
import { DataGrid, GridToolbar }  from "@mui/x-data-grid";
import { profile }                from '../../Helper/Helper';
import {
  AppBar,
  Box,
  Fab,
  IconButton, 
  Toolbar,
  Typography,
}                                 from "@mui/material";


export default function Brands({ navigate }) {
  const [Data, setData] = useState([]);
  
  const CallData = async () => {
    await magento
      .get("http://localhost:3001/brands")
      .then(function (response) {
        setData(response.data);
        return Data;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallData();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          className="mb15"
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Brands
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <DataGrid
          className="m20 mt0 p20 basecolor"
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "ID#", width: 15 },
            { field: "name", hideable: true, headerName: "Brand Name", width: 200 },
            { field: "type", hideable: true, headerName: "Type" },
            { field: "website", hideable: true, headerName: "Website" },
            // { field: "mobile", hideable: true, headerName: "Mobile" },
              { 
                field: "actions",
                type: "actions",
                width: 200,
                getActions: (GridRowParams) => [
                  <Link target="_blank" to={`/admin/brand/view/${GridRowParams.row.id}`}>
                    View
                  </Link>,
                  <Link target="_blank" to={`/admin/brand/edit/${GridRowParams.row.id}`}>
                  Edit
                </Link>,
                ],
              },
          ]}
          rows={Data}
          pageSize="50"
        />
        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            
            onClick={() => navigate("admin/brand/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
