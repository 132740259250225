import React, { useEffect, useState } from "react";
import magento from "../api/magento";
import { Divider, InputAdornment } from "@mui/material";
import { Send } from "@mui/icons-material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import { FormControl } from "@mui/material";

export default function ItemAddComponenet({
  popup,
  addItems,
  type = "add",
  edata = null,
  addterm,
  iid = 0,
}) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [ItemData, setItemData] = useState({});

  const [formData, setFormData] = useState({
    item_title: "",
    item_code: addterm,
    status: 1,
    price: 0,
    cost: 0,
    weight: 0,
    currency: "Rs.",
    unit_of_measure: "",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    channel_id: profile[0].id,
    id: "",
    dc_id: 0,
    url_key: null,
    magento_id: null,
    invoice_id: 0,
    quotation_id: 0,
    ListofItems: [],
  });

  const ItemDataCall = async () => {
    console.log(iid);
    await magento
      .get("/item/" + iid, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data.data[0])
        let mdata = response.data.data[0];
        setFormData({
          item_title: mdata.item_title,
          item_code: mdata.item_code,
          status: 1,
          price: mdata.price,
          cost: mdata.cost,
          weight: mdata.weight,
          currency: mdata.currency,
          unit_of_measure: mdata.unit_of_measure,
          sku: mdata.sku,
          purchase_description: mdata.purchase_description,
          sales_description: mdata.sales_description,
          part_no: mdata.part_no,
          item_types: mdata.item_types,
          location: "",
          channel_id: mdata.channel_id,
          id: mdata.id,
          ListofItems: [],
        });

        console.log("Database: ", response.data);
        return formData;
      });
  };

  const OnSubmit = async (data) => {
    console.log(data);
    await magento
      .post("item/create", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        formData.id = response.data.insertId;
        console.log(response.data);
        addItems(formData, response.data.insertId);
        popup();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const OnSaveUpdate = async (data) => {
    await magento
      .post("item/edit/" + data.id, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        addItems(formData, response.data.insertId);
        popup();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    type !== "add" ? ItemDataCall() : console.log("add");
    // console.log(edata.id, "New");

    type !== "add" ? console.log(formData, "Edit") : console.log(edata, "New");
  }, []);
  return (
    <>
      <Grid fluid sx={{ p: 0 }}>
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "25%" }}>
          <TextField
            id="item_code"
            name="item_code"
            // label="Item Code"
            variant="standard"
            value={formData.item_code}
            helperText="Enter Item Code"
            onChange={(e) =>
              setFormData({ ...formData, item_code: e.target.value })
            }
            type="text"
          />
        </FormControl>
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "71%" }}>
          <TextField
            // label="Item Description "
            helperText="Enter Item Description"
            fullWidth
            variant="standard"
            value={formData.item_title}
            onChange={(e) =>
              setFormData({ ...formData, item_title: e.target.value })
            }
            type="text"
            margin="10"
          />
        </FormControl>
        {/* 
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "20%" }}>
          <TextField
            label="SKU"
            helperText="Enter sku"
            variant="standard"
            defaultValue={formData.sku}
            onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
            type="text"
            margin="10"
          />
        </FormControl> */}
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "20%" }}>
          <TextField
            // label="Part No."
            helperText="Enter Part No."
            variant="standard"
            value={formData.part_no}
            onChange={(e) =>
              setFormData({ ...formData, part_no: e.target.value })
            }
            type="text"
            margin="10"
          />
        </FormControl>
        {/* <FormControl sx={{ mb: 2, mr: "2%", width: "20%" }}>
          <InputLabel id="channel">Select Currency</InputLabel>
          <Select
            labelId="currency"
            id="currency"
            name="currency"
            variant="standard"
            required
            value={formData.currency}
            label="Select Currency"
            onChange={(e) => handleChange(e)}
            defaultValue={1}
          >
            <MenuItem value="Rs.">Pakistani Rs</MenuItem>
            <MenuItem value="$">US Dollar</MenuItem>
            <MenuItem value="£">UK Pounds</MenuItem>
            <MenuItem value="AED">UAE Dirham</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "18%" }}>
          <TextField
            // label="Price"
            helperText="Enter item price"
            variant="standard"
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">.00</InputAdornment>
              ),
            }}
            type="text"
            margin="10"
          />
        </FormControl>
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "18%" }}>
          <TextField
            // label="Cost"
            helperText="Enter item cost"
            variant="standard"
            value={formData.cost}
            onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">.00</InputAdornment>
              ),
            }}
            type="text"
            margin="10"
          />
        </FormControl>
        <FormControl textAlign="left" sx={{ mb: 2, mr: "2%", width: "15%" }}>
          <TextField
            // label="Weight"
            helperText="enter weight"
            variant="standard"
            value={formData.weight}
            onChange={(e) =>
              setFormData({ ...formData, weight: e.target.value })
            }
            type="number"
            margin="10"
          />
        </FormControl>
        <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
          <TextField
            // label="Measure Unit"
            helperText="Enter Measure unit"
            variant="standard"
            value={formData.unit_of_measure}
            onChange={(e) =>
              setFormData({ ...formData, unit_of_measure: e.target.value })
            }
            type="text"
            margin="10"
          />
        </FormControl>
        <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
          <TextField
            // label="Sales Description"
            helperText="Please enter items sales description."
            fullWidth
            variant="standard"
            onChange={(e) =>
              setFormData({
                ...formData,
                sales_description: e.target.value,
              })
            }
            type="text"
            margin="10"
          />
        </FormControl>
        <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
          <TextField
            label="Purchase Decription"
            helperText="Enter items purchase description.  NOTE: this will not display under pdf"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setFormData({
                ...formData,
                purchase_description: e.target.value,
              })
            }
            type="text"
            margin="10"
          />
        </FormControl>
      </Grid>
      <Divider />

      <Button
        sx={{ textAlign: "right", mt: 1, mr: 1 }}
        variant="contained"
        color="success"
        endIcon={<Send />}
        onClick={() =>
          type === "edit" ? OnSaveUpdate(formData) : OnSubmit(formData)
        }
      >
        {type === "edit" ? "Save" : "Add Item"}
      </Button>
    </>
  );
}
