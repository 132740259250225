import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  
} from "@mui/material";

import {
  ArrowBack,
  Send,
} from "@mui/icons-material";


import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControl} from "@mui/material";
import magento from "../../api/magento";
import { useParams } from "react-router-dom";

export default function EditVendor({ navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const { VendorId } = useParams();
  const [formData, setFormData] = useState({
    person_name: "",
    vendor_name: "",
    phone: "",
    address: "",
    city: "",
    mobile: "",
    note: "",
    channel_id: profile[0].id,
  });

  const handleSelectChange = (e, data, update = "city") => {
    switch (update) {
      case "business":
        setFormData({ ...formData, business: `${data.value}` });
        break;
      default:
        setFormData({ ...formData, city: `${data.value}` });
        break;
    }
  };
  const OnSubmit = async (data) => {
    await magento
      .post("https://webapi.automarts.pk/vendor/edit/"+ VendorId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/vendors`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {profile && profile[0].channel} - Add New Vendor
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.vendor_name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "4%", width: "42%" }}
            >
              <TextField
                label="Vendor Name"
                helperText="Enter vendor Name"
                fullWidth
                variant="standard"
                value={formData.vendor_name}
                onChange={(e) =>
                  setFormData({ ...formData, vendor_name: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                id="person_name"
                name="person_name"
                label="Person Name"
                variant="standard"
                value={formData.person_name}
                helperText="Enter contact person name"
                onChange={(e) =>
                  setFormData({ ...formData, person_name: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              <TextField
                label="Vendor Address"
                helperText="Enter complete address"
                variant="standard"
                defaultValue={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "30%" }}
            >
              <TextField
                label="Phone Number"
                helperText="Enter office phone"
                variant="standard"
                defaultValue={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "30%" }}
            >
              <TextField
                label="mobile"
                helperText="Enter mobile"
                variant="standard"
                defaultValue={formData.mobile}
                onChange={(e) =>
                  setFormData({ ...formData, mobile: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "30%" }}
            >
              <TextField
                label="Email Address"
                helperText="Enter Email address"
                variant="standard"
                defaultValue={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              <TextField
                label="Vendor Note"
                helperText="Enter vendor note "
                variant="standard"
                defaultValue={formData.note}
                onChange={(e) =>
                  setFormData({ ...formData, note: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "47%" }}
            ></FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
