import React, { useState, useEffect } from "react";
import "./../create.css";
import { Header, Icon, Segment } from "semantic-ui-react";

import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  Snackbar,
  InputAdornment,
  FormControl,
  InputLabel,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Collapse,
  IconButton,
  Autocomplete,
  OutlinedInput,
  Input,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useFormControl } from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import magento from "../../api/magento";

import CompanySelection from "../../components/CompanySelection";
import SearchBar from "../../components/SearchBar";
import MuiAlert from "@mui/material/Alert";
import {
  AddBoxRounded,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ModeEditOutlineRounded,
  Remove,
  Search,
} from "@mui/icons-material";
import { Link, useLocation, useParams } from "react-router-dom";
import EditItemComponent from "../../components/EditItemComponent";
import PreviewPopUp from "../../components/PreviewPopUp";
import ItemSearchComponent from "../../components/ItemSearchComponent";
import SearchResultPopup from "../../components/SearchResultPopup";
import moment from "moment";
import SubNavBar from "../../components/SubNavBar";
import ModelPopup from "../../components/ModelPopup";
import ItemAddComponenet from "../../components/ItemAddComponenet";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };
export default function EditQuotation({ navigate }) {
  const location = useLocation();
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const taxes = JSON.parse(localStorage.getItem("taxes"));
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const [GlobalGST, setGlobalGST] = useState(18);
  const { InvId } = useParams();
  const [Profile, setProfile] = useState([]);
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);
  function LoadingHandle() {
    setLoading(true);
  }
  const [disablebtn, setDisablebtn] = useState(true);
  console.log(location.state.data);
  const [formData, setFormData] = useState({
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    DeletedItems: [],
    Channel: location.state.data.channel,
    SearchResult: ["No Item Found"],
    SelectedItems: location.state.data.Items,
    SubTotal: location.state.data.Data[0].subtotal,
    GrandTotal: location.state.data.Data[0].grand_total,
    GrandTotalGST: location.state.data.Data[0].grand_total_gst,
    CostTotal: location.state.data.Data[0].total_cost,
    GSTTotal: location.state.data.Data[0].gst_total,
    addtionalCharges: location.state.data.Data[0].additional_charges,
    discount: location.state.data.Data[0].discount,
    item_count_total: location.state.data.Data[0].total_items_count,
    total_items_qty: location.state.data.Data[0].total_items_qty,
    items_left: location.state.data.Data[0].items_left,
    created_by: location.state.data.Data[0].u,
    user_id: location.state.data.Data[0].uid,
    username: location.state.data.Data[0].username,
    tax_type: location.state.data.Data[0].tax_type,
    invoice_number_type: location.state.data.Data[0].invoice_number_type,
    TTvalue: location.state.data.Data[0].tax_type,
    TTinvoice_number_type:location.state.data.Data[0].invoice_number_type,
    edit_by: ActiveUser[0].username,
    edit_user_id: ActiveUser[0].id,
    invoice_number: location.state.data.Data[0].invoice_number,
    create_at: moment(location.state.data.Data[0].create_at).format(
      "YYYY-MM-DD"
    ),
    InvoiceDate: moment(location.state.data.Data[0].invoice_create_at).format(
      "YYYY-MM-DD"
    ),
    due_at: moment(location.state.data.Data[0].invoice_due_at).format(
      "YYYY-MM-DD"
    ),
    edit_username: ActiveUser[0].username,
    ref_number: location.state.data.Data[0].ref_number,
    terms: location.state.data.Data[0].terms,
    remarks: location.state.data.Data[0].remarks,
    company_id: location.state.data.Data[0].company_id,
    created_at: location.state.data.Data[0].created_at,
    po_number: location.state.data.Data[0].po_number,
    quote_number: location.state.data.Data[0].quote_number,
    bill_to: location.state.data.Data[0].bill_to,
    bill_to_company: location.state.data.Data[0].bill_to_company,
    bill_to_email: location.state.data.Data[0].bill_to_email,
    bill_to_ntn: location.state.data.Data[0].bill_to_ntn,
    bill_to_phone: location.state.data.Data[0].bill_to_phone,
    bill_to_stn: location.state.data.Data[0].bill_to_stn,
    name: location.state.data.Data[0].name,
    role: location.state.data.Data[0].role,
    phone: location.state.data.Data[0].phone,
    
  });
  const [status, setStatus] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    return setGlobalGST(event.target.value);
  };

  const handleChangeTType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, TTvalue: event.target.value})
    return formData;
  }; 


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //** Profile Data Call */
  const ProfileCall = async () => {
    console.log("Channel Call");
    await magento
      .get(
        "channel/" +
          localStorage.getItem("channel"),
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        }
      )
      .then(function (response) {
        setProfile(response.data[0]);
        CompanyCall();
        return formData;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  //** Company Data Call */
  const CompanyCall = async () => {
    await magento
      .get("company/channel/" + profile[0].id, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        setFormData({ ...formData, CompanyList: response.data });
        return formData;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const PriceCalculate = (
    qty = 1,
    item,
    gst = GlobalGST,
    type = null,
    val = null
  ) => {
    item.cost ? (item.item_cost = item.cost) : (item.item_cost = 0);
    var saleTaxTotal = parseInt((gst / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((gst / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;

    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }

    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  const CalulateTotal = (
    data = formData.SelectedItems,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;
    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;

    // console.log(Additionalcharges, "Checking addtional charges");
    if (data.length > 0) {
      // console.log(vdata.length);
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );
      total_items_qty = parseInt(
        data.reduce(
          (prevValue, currentValue) => prevValue + currentValue.item_qty,
          0
        )
      );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    } else {
      let gdtotal =
        parseInt(SubTotal) + parseInt(Additionalcharges) - parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(GSTTotal) +
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    }
    return formData;
  };

  const EditItem = (data) => {
    // console.log('updatd data comes', data)
    PriceCalculate(data.qty, data);
    setFormData({
      ...formData,
      SelectedItems: formData.SelectedItems.map((item) =>
        item.id === data.id ? data : item
      ),
    });
    console.log(formData);
    return formData;
  };

  const DeleteItem = (data, dindex) => {
    handleClickOpen();
    setmessage({
      message: "Item row deleted",
      status: "error",
    });

    setFormData({
      ...formData,
      DeletedItems: formData.SelectedItems.filter(
        (item, index) => index === dindex
      ),
      SelectedItems: formData.SelectedItems.filter((item, index) => {
        return index !== dindex;
      }),
    });
    // console.log(formData)
  };

  const AddSelected = (
    data = {
      item_title: "",
      item_code: "",
      status: 1,
      price: "",
      cost: 0,
      weight: 0,
      currency: "Rs.",
      unit_of_measure: "kg",
      image: [],
      imageName: "",
      sku: "",
      purchase_description: "",
      sales_description: "",
      part_no: "",
      item_types: "",
      location: "",
      channel_id: profile[0].id,
      delivery_status: "",
      id: 0,
    }
  ) => {
    PriceCalculate(1, data);

    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    console.log(formData);
    handleClickOpen();
    return formData;
  };

  const InsertInvoice = async (data) => {
    await magento
      .post("invoice/edit/" + InvId, data, {
        // .post("http://localhost:3001/invoice/edit/" + InvId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setTimeout(function () {
          navigate("/admin/invoice/view/" + InvId, {
            state: {
              message: "Invoice has been sucessfully Updated",
            },
          });
        }, 1000);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const OnSubmitData = (data, profile) => {
    console.log(GlobalGST);
    setDisablebtn(true);
    CalulateTotal(data);
    handleClickOpen();
    if (data.SelectedItems.length < 1) {
      setDisablebtn(false);
      setmessage({
        message: "Please add items in Invoice to proceed further",
        status: "error",
      });
      CalulateTotal();
      return message;
    } else if (data.created_at === null) {
      setDisablebtn(false);
      setmessage({ message: "Please select Invoice date", status: "error" });
      return message;
    } else {
      CalulateTotal(data);
      InsertInvoice({ data, profile });
      return true;
    }
  };
  const [state, setState] = useState({ activeIndex: 1 });
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const handleSelectChange = () => {
    console.log("heelo");
  };
  const CreateItemsListRow = () => {
    return formData.SelectedItems.length > 0 ? (
      <>
        {formData.SelectedItems.map((item, index) => {
          return (
            <>
              <TableRow hover>
                <TableCell width={"2%"}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
                <TableCell sx={{ border: "1px solid #eee" }} width={"5%"}>
                  {" "}
                  {index + 1}. {item.item_name && item.item_name}{" "}
                </TableCell>
                <TableCell sx={{ border: "1px solid #eee" }}>
                  <p>{item.item_title && item.item_title}</p>
                </TableCell>

                <TableCell
                  sx={{ border: "1px solid #eee" }}
                  width={"10%"}
                  align="right"
                >
                  {item.item_code}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee", padding: 1 }}
                  align="center"
                  width={"7%"}
                >
                  <TextField
                    fullWidth
                    id="qty"
                    variant="standard"
                    size="small"
                    defaultValue={1}
                    type="number"
                    sx={{ p: "2px" }}
                    onChange={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee", padding: 1 }}
                  width={"15%"}
                  align="right"
                >
                  <TextField
                    fullWidth
                    variant="standard"
                    size="small"
                    id="price"
                    defaultValue={item.price}
                    onLoad={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                    onChange={(e) => {
                      item.price = e.target.value;
                      PriceCalculate(item.qty, item);
                      return formData;
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee", padding: 1 }}
                  width={"12%"}
                  align="right"
                >
                  {" "}
                  <TextField
                    variant="standard"
                    size="small"
                    id="gst"
                    disabled
                    value={item.saleTaxTotal}
                    onLoad={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                    onChange={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee" }}
                  align="right"
                  width={"12%"}
                >
                  <TextField
                    size="small"
                    variant="standard"
                    sx={{ textAlign: "right" }}
                    id="row_total"
                    value={item.rowTotal}
                    disabled
                    onLoad={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                    onChange={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                  />{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee" }}
                  align="right"
                  width={"3%"}
                >
                  <EditItemComponent
                    itemData={item}
                    dataEdit={(d) => EditItem(d)}
                  />
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #eee" }}
                  align="right"
                  width={"5%"}
                >
                  <Button
                    compact
                    size="small"
                    sx={{ color: "red" }}
                    onClick={(e) => {
                      return DeleteItem(item);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} sx={{ border: "none", p: "2px" }}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ padding: 1 }}>
                      <Typography>ItemDetails</Typography>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </>
    ) : (
      <>
        <TableRow>
          <TableCell colSpan={7}>
            <Segment textAlign="center">
              <Header icon>There is no Item added in Quote</Header>
            </Segment>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const ItemSearch = async (terms) => {
    await magento
      .post(
        "https://webapi.automarts.pk/item/search/terms/" + profile[0].id,
        {
          terms: terms,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [Fcs, setFcs] = useState(false);
  const handleInputFocus = () => {
    setFcs(true);
  };

  const handleInputBlur = () => {
    setFcs(false);
  };

  const DisplayResult = () => {
    return (
      <>
        <div style={{ position: "absolute", bottom: 0 }}>
          <Box height={200} width={200}>
            sdada
          </Box>
        </div>
      </>
    );
  };

  const AddItemToCart = (item) => {
    console.log(item);
  };

  const UpdateItem = (Uitem, extItem) => {
    console.log(Uitem.item_title);
    extItem.item_id = Uitem.id;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.item_price = Uitem.price;
    extItem.price = Uitem.price;
    extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    extItem.unit_of_measure = Uitem.unit_of_measure;
    PriceCalculate(extItem.qty, extItem);

    return extItem;
  };
  const TableRowNew = (item, index) => {
    // console.log(index, item, 'listing')
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {/* <ItemSearchComponent addItems={(d) => {AddSelected(d); console.log(d)} } /> */}

            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              defaultValue={item.item_code}
              fullwidth
              size="small"
              width="100%"
              onChange={(e) =>
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "code",
                  e.target.value
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message: "Item information has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              value={item.item_title}
              fullWidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "title",
                  e.target.value
                );
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              
              size="small"
              value={item.unit_of_measure}
              onChange={(e) => {
                item.unit_of_measure = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              sx={{
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              fullwidth
              type="number"
              size="small"
              width="100%"
              value={item.item_price}
              onChange={(e) => {
                item.item_price = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            align="right"
          >
            {item.item_gst_total && item.item_gst_total}
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            align="right"
          >
            {item.item_row_total_gst && item.item_row_total_gst}{" "}
          </TableCell>

          <TableCell sx={{ px: "5px" }}>
            {item.item_id !== 0 ? (
              <ModelPopup
                mopen={true}
                title="Edit Item"
                content={<Edit fontSize="small" />}
                childComp={
                  <ItemAddComponenet
                    type="edit"
                    edata={item}
                    iid={item.item_id}
                    addItems={(newItem) => {
                      // console.log(newItem);
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message:
                            "Item information has been updated and row has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                }
              />
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  const CreateSelectedItemListing = () => {
    return formData.SelectedItems.length > 0 ? (
      <>
        {formData.SelectedItems.map((item, index) => {
          return (
            <>
              <Grid
                textAlign={"left"}
                container
                fullWidth
                sx={{
                  mb: 2,
                  pb: 2,
                  borderBottomWidth: 1,
                  borderBlockColor: "#ccc",
                  borderBottomStyle: "dotted",
                }}
              >
                <Grid md={11} sx={{ px: 1, mb: 2 }}>
                  <Typography variant="h5">
                    {index + 1}. {item.item_name && item.item_name}{" "}
                    {item.item_title && item.item_title}{" "}
                    <Typography variant="small">({item.item_code})</Typography>
                  </Typography>
                </Grid>
                <Grid md={1} sx={{ px: 1, mb: 2 }}>
                  <Button
                    compact
                    onClick={(e) => {
                      return DeleteItem(item);
                    }}
                  >
                    <Icon size="large" name="trash alternate outline" />
                  </Button>
                </Grid>
                <Grid md={2} sx={{ px: 1 }}>
                  <TextField
                    fullWidth
                    id="qty"
                    defaultValue={1}
                    label="Quantity"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onLoad={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                    onChange={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                  />
                </Grid>
                <Grid md={2} sx={{ px: 1 }}>
                  <TextField
                    fullWidth
                    disabled
                    id="price"
                    value={item.price}
                    label="Price"
                    InputLabelProps={{
                      shrink: true,
                      label: "test",
                    }}
                    helperText="Each items price"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid md={3} sx={{ px: 1 }}>
                  <TextField
                    sx={{ p: 0 }}
                    disabled
                    fullWidth
                    id="sub_total"
                    label="Sub Total"
                    value={item.subTotalWithoutTax}
                    InputLabelProps={{
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    helperText={"Tax not included "}
                  />
                </Grid>
                <Grid md={2} sx={{ px: 1 }}>
                  <TextField
                    sx={{ p: 0 }}
                    disabled
                    fullWidth
                    id="gst"
                    label="Tax Total"
                    helperText={"Each: " + item.saleTaxTotal / item.qty}
                    value={item.saleTaxTotal}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid md={3} sx={{ px: 1 }}>
                  <TextField
                    disabled
                    fullWidth
                    id="row_total"
                    label="Row Total"
                    value={item.rowTotal}
                    InputLabelProps={{
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    helperText={""}
                  />
                </Grid>
                <Grid md={12} sx={{ mt: 2 }}>
                  <Typography varient={"p"} color={"#666"}>
                    <strong>Purchase Description:</strong>{" "}
                    {item.purchase_description}
                  </Typography>
                </Grid>
                <Grid md={12}>
                  <Typography varient={"p"} color={"#666"}>
                    <strong>Sales Description:</strong> {item.sales_description}
                  </Typography>
                </Grid>
              </Grid>
            </>
          );
        })}
      </>
    ) : (
      <>
        <Segment textAlign="center">
          <Header icon>There is no Item added in Quote</Header>
        </Segment>
      </>
    );
  };
  const ChangeHandle = (event: SelectChangeEvent) =>
    setStatus(event.target.value);

  useEffect(() => {
    ProfileCall();
    console.log(location);
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Invoice #{formData.invoice_number}
            </Typography>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <SubNavBar />
        <Paper sx={{ mx: "2%", mb: 5, borderRadius: 5, overflow: "hidden" }}>
          {/* <Grid textAlign={"left"} md={16} bgcolor={"#eee"}>
            <SubNavBar />
          </Grid> */}
          <Grid sx={{ px: 2, pt: 2 }} container spacing={2}>
            <Grid textAlign={"left"} md={16} sx={{ p: 2, pb:0 }}>
              <CompanySelection data={formData} newCompany={false} />
            </Grid>
            <Grid textAlign={"left"} md={5} sx={{ p: 2 }}>
              {/* <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                TO:
              </Typography> */}
              
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                TO: {formData.bill_to_company}
              </Typography>
              <Typography variant="p" marginBottom={0}>
                {formData.bill_to}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                STN#: {formData.bill_to_stn}
              </Typography>{" "}
              &nbsp; &nbsp;
              <Typography variant="p" marginBottom={0}>
                NTN#: {formData.bill_to_ntn}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                By: {Profile.channel}{" "}
              </Typography>
              <Typography variant="p" marginBottom={0}>
                STN#: {Profile.stn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                NTN#: {Profile.ntn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                {Profile.address}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {Profile.phone}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                Generated By:
              </Typography>
              <Typography variant="p" marginBottom={0}>
                Name: {formData.name}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Role: {formData.role}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {formData.phone}
              </Typography>
              <br />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="invoice_number"
                  label="Invoice #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.invoice_number}
                  disabled
                />
                <Link to={"/admin/channel/edit/" + profile[0].id}>Edit</Link>

                <br />
                <br />
                <TextField
                  fullWidth
                  id="po_number"
                  label="PO #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.po_number}
                  marginBottom="0"
                  onChange={(e) => {
                    setFormData({ ...formData, po_number: e.target.value });
                    return formData;
                  }}
                />
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="create_at"
                  label="Invoice Date"
                  type="date"
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.InvoiceDate}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, InvoiceDate: e.target.value, due_at: moment(e.target.value).add(15,'days').format("YYYY-MM-DD") });
                    return formData;
                  }}
                />
                <br />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="ref_number"
                  label="Reference #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.ref_number}
                  marginBottom="0"
                  onChange={(e) => {
                    setFormData({ ...formData, ref_number: e.target.value });
                    return formData;
                  }}
                />
              </Grid>

              <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="terms"
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions"
                  multiline
                  variant="outlined"
                  rows={6}
                  sx={{ background: "#fff" }}
                  value={formData.terms}
                  onChange={(e) => {
                    setFormData({ ...formData, terms: e.target.value });
                    return formData;
                  }}
                />
                <br/>
                <br/>
                <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                sx={{ background: "#fff" }}
                placeholder="Please enter remarks"
                multiline
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                rows={2}
                value={formData.remarks}
                onChange={(e) => {
                  setFormData({ ...formData, remarks: e.target.value });
                  return formData;
                }}
              />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
              <Typography variant="h6" color={"#777"} fontSize={20}>
                Details
              </Typography>
            </Grid>
            <Grid textAlign={"right"} md={12} sx={{ px: 4, pb: 4 }}>
              {formData.SelectedItems && (
                <>
                  {/* <FormControl fullWidth="true" style={{fontSize:"8px"}} >               */}
                  <Table
                    sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                    size="small"
                    aria-label="a dense table"
                    padding="0"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"50px"}
                        >
                          Sno.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"200px"}
                          align="left"
                        >
                          Item Code
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"500px"}
                        >
                          Product Description
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"100px"}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"100px"}
                        >
                          MOU
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          GST
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"200px"}
                        >
                          Row Total{" "}
                        </TableCell>

                        <TableCell
                          sx={{ p: "2px" }}
                          width={50}
                          colSpan={2}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.SelectedItems.map((item, index) => {
                        return TableRowNew(item, index);
                      })}
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Button
                            onClick={() => {
                              AddSelected();
                              handleClickOpen();
                              setmessage({
                                message: "Item row has been added",
                                status: "success",
                              });
                            }}
                          >
                            Add New Row
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </FormControl> */}
                </>
              )}
            </Grid>

            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
            <br />
              <br />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Invoice Types</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formData.TTvalue}
                  onChange={handleChangeTType}
                >
                  <FormControlLabel value="None" checked={formData.TTvalue == 'None'? true : false} control={<Radio />} label={`None`} />
                  <FormControlLabel value="Sales" checked={formData.TTvalue == 'Sales' ? true : false} control={<Radio />} label={`Sales Tax Invoice ${formData.tax_type == 'Sales' ? '(#' + formData.invoice_number_type + ') Current' : '(#' + Profile.salestax_series + ')' } `} /> 
                  <FormControlLabel value="Service" checked={formData.TTvalue == 'Service'? true : false} control={<Radio />} label={`Service Tax Invoice ${formData.tax_type == 'Service' ? '(#'+ formData.invoice_number_type + ') Current' : '(#' +Profile.servicetax_series+ ')'}`} />
                </RadioGroup>
              </FormControl>
              
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid textAlign={"left"} md={12} sx={{ pb: 2 }}>
                <TextField
                  fullWidth
                  id="discount"
                  label="Discount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.discount}
                  onChange={(e) => {
                    CalulateTotal(
                      formData,
                      formData.addtionalCharges,
                      e.target.value
                    );
                    return formData;
                  }}
                />
              </Grid>
              <Grid textAlign={"left"} md={12}>
                <TextField
                  fullWidth
                  id="additional_charges"
                  label="Additonal Charges"
                  type="number"
                  value={formData.addtionalCharges}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    CalulateTotal(formData, e.target.value);
                    return formData;
                  }}
                />
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {formData.SubTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <FormControl sx={{ minWidth: 300 }}>
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                  >
                    GST
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={GlobalGST}
                    onChange={(e) => {
                      setGlobalGST(e.target.value);
                      formData.SelectedItems.map((item) =>
                        PriceCalculate(item.item_qty, item, e.target.value)
                      );
                      return GlobalGST;
                    }}
                    autoWidth
                    fullWidth
                    sx={{ textAlign: "left" }}
                    size="small"
                    label="GST"
                  >
                    {taxes.map((item) => {
                      return (
                        <MenuItem
                          sx={{ textAlign: "left" }}
                          value={item.percentage}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <Typography textAlign={"left"} variant="h6">
                Sales Tax (GST)
                </Typography> */}
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {formData.GSTTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h5">
                  {formData.GrandTotalGST}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              <PreviewPopUp data={[formData, profile[0]]} />
            </Grid>
            <Grid textAlign={"right"} md={5} sx={{ px: 4, py: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="inherit"
                onClick={() => {
                  CalulateTotal();
                  setDisablebtn(false);
                  console.log(formData);
                  localStorage.setItem(
                    "previewdata",
                    JSON.stringify(formData, profile)
                  );
                }}
                sx={{ mx: 3 }}
              >
                Re-Calculate
              </Button>
              <Button
                onClick={() => OnSubmitData(formData, Profile)}
                type="submit"
                variant="contained"
                color="success"
                size="large"
                disabled={disablebtn}
              >
                Save Invoice
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
