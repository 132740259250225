import React, { useState, useEffect } from "react";
import "./../create.css";
import { AppBar, Divider, FormHelperText, Paper, Toolbar } from "@mui/material";

import { ArrowBack, Send } from "@mui/icons-material";
import magento from "../../api/magento";
import { useLocation, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";

import Typography from "@mui/material/Typography";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
import FileUpload from "../../components/FileUpload";

export default function EditChannel({ navigate }) {
  const location = useLocation();
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const { channelId } = useParams();
  const itemData = location.state;
  const [file, setFile] = React.useState("");
  const [formData, setFormData] = useState({});
  // const [formData, setFormData] = useState({
  //   id: itemData.id,
  //   channel: itemData.channel,
  //   address: itemData.address,
  //   email: itemData.email,
  //   phone: itemData.phone,
  //   ntn: itemData.ntn,
  //   stn: itemData.stn,
  //   logo: itemData.logo,
  //   invoice_series: itemData.invoice_series,
  //   quote_series: itemData.qoute_series,
  //   so_series: itemData.so_series,
  //   dc_series: itemData.dc_series,
  // });

  const ProfileCall = async () => {
    await magento
      .get("https://webapi.automarts.pk/channel/" + channelId, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        console.log(response.data);
        return setFormData(response.data[0]);
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const handleSelectChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFormData({ ...formData, logo: e.target.files[0].name });
    return file;
  };

  const UploadImage = async (data) => {
    const UploadFileData = new FormData();
    UploadFileData.append("image", data, data.name);
    console.log(UploadFileData);

    await magento
      .post("https://webapi.automarts.pk/upload", UploadFileData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("sub", response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const OnSubmit = async (data) => {
    console.log(channelId, data);

    await magento
      .post("https://webapi.automarts.pk/channel/edit/" + channelId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // UploadImage(file);
        console.log(response.data);
        navigate("/admin/channel/edit/" + channelId);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    ProfileCall();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Channel
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.channel}
            </Typography>
          </Grid>
          <Divider />
          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "35%" }}
            >
              <TextField
                helperText="Enter Channel Name"
                fullWidth
                variant="standard"
                value={formData.channel}
                onChange={(e) =>
                  setFormData({ ...formData, channel: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "26%" }}
            >
              <TextField
                id="ntn"
                name="ntn"
                variant="standard"
                value={formData.ntn}
                helperText="Enter NTN Number"
                onChange={(e) =>
                  setFormData({ ...formData, ntn: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "26%" }}
            >
              <TextField
                helperText="Enter STN Number"
                variant="standard"
                defaultValue={formData.stn}
                onChange={(e) =>
                  setFormData({ ...formData, stn: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                helperText="Enter complete office address"
                fullWidth
                variant="standard"
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                helperText="Enter office phone"
                variant="standard"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                helperText="Enter Email address"
                variant="standard"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "15%" }}>
              <TextField
                helperText="Invoice series"
                variant="standard"
                value={formData.invoice_series}
                onChange={(e) =>
                  setFormData({ ...formData, invoice_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="Quotation series"
                variant="standard"
                value={formData.qoute_series}
                onChange={(e) =>
                  setFormData({ ...formData, qoute_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="Delivery Challan series"
                variant="standard"
                value={formData.dc_series}
                onChange={(e) =>
                  setFormData({ ...formData, dc_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="SO series"
                variant="standard"
                value={formData.so_series}
                onChange={(e) =>
                  setFormData({ ...formData, so_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="SalesTax series"
                variant="standard"
                value={formData.salestax_series}
                onChange={(e) =>
                  setFormData({ ...formData, salestax_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="ServiceTax series"
                variant="standard"
                value={formData.servicetax_series}
                onChange={(e) =>
                  setFormData({ ...formData, servicetax_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                helperText="Payment series"
                variant="standard"
                value={formData.payment_series}
                onChange={(e) =>
                  setFormData({ ...formData, payment_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
          </Grid>
          <Divider />
          <Grid fluid sx={{ p: 2 }}>
            <input
              name="logo"
              type="file"
              accept="image/*"
              onChange={(e) => handleSelectChange(e)}
            />
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => {
              // UploadImage(file);
              OnSubmit(formData);
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
