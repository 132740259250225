import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
} from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation, useParams } from "react-router-dom";
import NotificationMessage from "../../components/NotificationMessage";
import moment from "moment";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  GridRowParams,
} from "@mui/x-data-grid";
import { FormattingNumber } from "../../Helper/Helper";
import SubNavBar from "../../components/SubNavBar";

export default function Invoices({ navigate }) {
  const [Invoices, setInvoices] = useState([]);
  const [FRow, setFRow] = useState({});
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);
  const {type} = useParams();
  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Invoices.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Invoices.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  const SearchQuote = async (quoteNo) => {
    await magento
      .get(
        "/search/quote/channel/" +
          localStorage.getItem("channel") +
          "/" +
          quoteNo,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setInvoices(response.data);
        return Invoices;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const CallInvoices = async () => {
    let callType = (!type) ? 'invoices/channel/' + localStorage.getItem("channel") : 'invoices/channel/'+ localStorage.getItem("channel") +"/" + type;
    
    await magento
      .get(
        callType,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setInvoices(response.data);
        response.data.map((item, index) => {
          setFRow(response.data);
        });
        return Invoices;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  console.log(FRow);

  useEffect(() => {
    CallInvoices();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {(!type)? '': type  } Invoices{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowHeight={30}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "invoice_number", hideable: true, headerName: "Invoice#", width:80,
            renderCell: (params) => <Link to={`/admin/invoice/view/${params.row.id}`}>{params.row.invoice_number}</Link> },
            { field: "tax_type", hideable: true, headerName: "Type", width:'60'},
            { field: "invoice_number_type", hideable: true, headerName: "SST/ST Inv#",width:80,
              renderCell: (params) => <Link to={`/admin/dc/view/${params.row.tax_type}/${params.row.id}`}>{params.row.invoice_number_type}</Link>, },
            { field: "po_number", hideable: true, headerName: "PO#", width:100 },
            { field: "ref_number", hideable: true, headerName: "Ref#", width: 100 },
            { field: "status", hideable: true, headerName: "Status", width:'60'},
            {
              field: "bill_to_company",
              hideable: true,
              width:150,
              headerName: "Company",
              renderCell: (params) => <Link target="_blank" to={`/admin/company/view/${params.row.company_id}`}>{params.row.bill_to_company}</Link>,
            },
            {
              field: "total_items_qty", hideable: true, headerName: "Items/Qty", width: '60',
              renderCell: (params) => <p>{params.row.total_items_count} / {params.row.total_items_qty}</p>,

            },
            // { field: "subtotal", hideable: true, headerName: "Subtotal",
            //   renderCell: (params) => FormattingNumber(params.row.subtotal) + '.00'
            // },
            // { field: "gst_total", hideable: true, headerName: "GST",
            // renderCell: (params) => FormattingNumber(params.row.gst_total) + '.00' },
            { field: "grand_total_gst", hideable: true, headerName: "Total",
              renderCell: (params) => FormattingNumber(params.row.grand_total_gst) + '.00' },
            
            {
              field: "actions",
              width: 200,
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/invoice/view/${GridRowParams.id}`}>
                  Inoivce 
                </Link>,
                <Typography>|</Typography>,
                <Link to={`/admin/invoice/view/${type}/${GridRowParams.id}`}>
                 {GridRowParams.row.tax_type} Invoice
              </Link>
              ],
            },
          ]}
          rows={Invoices}
          pageSize="25"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/invoice/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
