import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  FormattingNumber,
  HelperDateCalc,
  Month,
  getCall,
  postCall,
  profile,
  profileId,
  today,
} from "../../Helper/Helper";

import SubNavBar from "../../components/SubNavBar";
import DateRangeComponent from "../../components/DateRange";
import TotalComp from "../../components/TotalComp";
import { FormatDate } from "../../Helper/Helper";
import { TableRow } from "semantic-ui-react";
import TableComp from "../../components/TableComp";

export default function ItemsQuotedList({ navigate, props }) {
  const [open, setOpen] = useState(false);
  const [message, setmessage] = useState("");
  const [disablebtn, setDisablebtn] = useState(true);
  const [QList, setQList] = useState([{ id: 1 }, { id: 2 }]);
  const [rdata, setRdata] = useState({
    gstTotal: 0,
    subTotal: 0,
    GrandTotal: 0,
    CountTotal: 0,
    RecordTotal: 0,
    companies: "",
  });

  const [period, setPeriod] = useState({
    StartDate: today,
    EndDate: today,
    channel_id: profileId,
    channel: profile[0],
  });

  const clickHandler = (array, value, v2, v3) => {
    if (array.filter((i) => (i.company === value ? true : false))) {
      // console.log(value, 'Adding for first time');
      array.push({ company: value, stn: v2, ntn: v3 });
      // setter(newArray)
    } else {
      // console.log('Already in Array')
    }
    const mapFromColors = new Map(array.map((c) => [c.company, c]));

    const uniqueColors = [...mapFromColors.values()];
    console.log(uniqueColors);
    return setRdata({ ...rdata, companies: uniqueColors });
  };

  const CalcQListTotal = (data) => {
    let arr = [];
    if (data.length > 0) {
      // console.log(data.length);
      data.map((item) => {
        clickHandler(
          arr,
          item.bill_to_company,
          item.bill_to_stn,
          item.bill_to_ntn
        );
      });

      const st = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.subtotal),
        0
      );
      const gstt = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.gst_total),
        0
      );
      const gt = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.grand_total_gst),
        0
      );
      const ct = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.total_items_qty),
        0
      );
      setRdata({
        ...rdata,
        gstTotal: gstt,
        subTotal: st,
        GrandTotal: gt,
        CountTotal: ct,
        RecordTotal: data.length,
      });
    }
  };

  const QuotedItemsList = async (data) => {
    await postCall("/quotations/items/list", data)
      .then((response) => {
        setQList(response.data);
        CalcQListTotal(response.data);
        console.log(QList, "newer");
        // return QList;
      })
      .catch(function (err) {
        ValidationMessage(err.message, "error");
      });
  };

  var ValidationMessage = (msg, status) => {
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const DateCalc = (n, t) => {
    let d = HelperDateCalc(n, t);
    console.log(d);

    if (d.t === "to") {
      setPeriod({ ...period, EndDate: d.n });
    } else if (d.t === "from") {
      setPeriod({ ...period, StartDate: d.n });
    } else {
      setPeriod({ ...period, StartDate: d.stDate, EndDate: d.enDate });
    }
  };

  useEffect(() => {
    console.log(QList);
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              QList Invoices
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <DateRangeComponent
          data={period}
          update={(n, t) => DateCalc(n, t)}
          onBtnSubmit={(n) => QuotedItemsList(period)}
        />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            px: 2,
            mt: 10,
          }}
        >
          <Grid item md={12} textAlign="left">
            <Box sx={{ mt: 4 }}>
              <Paper className="p1">
                <Grid
                  sx={{
                    display: "flex",
                    alignContent: "end",
                    alignItems: "end",
                  }}
                  item
                  md={12}
                >
                  <Link
                    style={{ marginBottom: 2, alignContent: "end" }}
                    to={{ pathname: `/admin/reports/pdf/invoices/` }}
                    state={{ rdata, QList, period }}
                  >
                    Print
                  </Link>
                </Grid>
                <Grid item md={12}>
                  <h3>Quoted Items List</h3>
                  <TableComp
                    data={QList}
                    THead={
                      <TableRow>
                        <TableCell width={"3%"}>Quotation</TableCell>
                        <TableCell width={"3%"}>Ref.</TableCell>
                        <TableCell width={"25%"}>Company</TableCell>
                        <TableCell width={"4%"}>I. Code</TableCell>
                        <TableCell width={"30%"}>Item Decription</TableCell>
                        <TableCell width={"6%"}>Qty</TableCell>
                        <TableCell width={"5%"}>Price</TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    }
                    TRow={(i) => {
                      // let c = i.quote_to_company.toProperCase();
                      // let abbr = c.match(/\b([A-Z])/g).join("");
                      return (
                        <>
                          <TableRow sx={{ fontSize: "5pt" }} id={i.id}>
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/admin/quotation/view/${i.id}`,
                                }}
                              >
                                {" "}
                                {i.quote_number}
                              </Link>
                            </TableCell>
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              {" "}
                              {i.ref_number}
                            </TableCell>
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              {" "}
                              {i.quote_to_company}
                            </TableCell>
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              {" "}
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/admin/item/view/${i.quotation_items_id}`,
                                }}
                              >
                                {i.item_code}
                              </Link>
                            </TableCell>
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              {" "}
                              {i.item_title}
                            </TableCell>
                            {/* <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.status}
                        </TableCell> */}
                            <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                              {i.item_qty}/ {i.item_left}
                            </TableCell>
                            <TableCell align="right" sx={{ pr: 0, pl: 0 }}>
                              {i.item_price}/-
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/quotation/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
