import React, { useState } from "react";
import "./../create.css";

import { useLocation, useParams } from "react-router-dom";
import magento from "../../api/magento";
import {
  Paper,
  Tab,
  Tabs,
  Card,
  CardContent,
  CardActions,
  Table,
  TableRow,
  TableCell,
  TableHead,
  MenuItem,
} from "@mui/material";

import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import{FormattingNumber} from './../../Helper/Helper';

import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import DisplayLayout from "../../components/DisplayLayout";
import { Icon } from "semantic-ui-react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  ChartContainer,
  BarPlot,
  LinePlot,
  ChartsXAxis,
  ChartsYAxis,
  axisClasses,
} from "@mui/x-charts";
import SubNavBar from "../../components/SubNavBar";
import { DatePickerToolbar } from "@mui/x-date-pickers/DatePicker/DatePickerToolbar";
const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function ViewCompany({ navigate }) {
  const { companyId } = useParams();
  const today = moment(new Date()).format("YYYY-MM-DD");
  var sum = 0;
  const [months, setMonths] = useState([]);
  const [data, setData] = useState("");
  const [SelectValue, setSelectValue] = useState('7 Days');
  const [value, setValue] = useState(0);
  var monthsList = [
    {label: "Janurary", value: '01', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "February", value: '02', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "March", value: '03', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "April", value: '04', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "May", value: '05', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "June", value: '06', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "July", value: '07', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "August", value: '08', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "September", value: '09', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "October", value: '10', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "November", value: '11', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
    {label: "December", value: '12', dataset: [], amount:[], qty:[], invoices: 0, quotation: 0, dc:0, payment: 0},
]

const glist = ()=>{
    var list = [];
    monthsList.map(i =>{
      list.push(i.dc);
      console.log(list)
    })
    return list
  }
  const CallCompany = async () => {
    await magento
      .get("company/" + companyId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);

        let PayMap = response.data.cpPay.map(i => {
          var monthPay= moment(i.payment_date).format("MM");
          monthsList.find(m => m.value == monthPay);
          
        for (let j = 0; j < monthsList.length; j++) {
          if (monthsList[j].value == monthPay) {
              monthsList[j].payment =monthsList[j].payment + parseInt(i.receipt_amount);
          }
         
      }
      });
        let QuoteMap = response.data.cpQuote.map(i => {
          var monthQuote= moment(i.created_at).format("MM");
          monthsList.find(m => m.value == monthQuote);
          for (let j = 0; j < monthsList.length; j++) {
            if (monthsList[j].value == monthQuote) {
                monthsList[j].quotation =monthsList[j].quotation + parseInt(i.grand_total_gst);
            }
        }
        
        });

        let DcMap = response.data.cpDc.map(i => {
          var monthDc= moment(i.dc_date).format("MM");
          monthsList.find(m => m.value == monthDc);
        
          for (let j = 0; j < monthsList.length; j++) {
            if (monthsList[j].value === monthDc) {
                monthsList[j].dc = monthsList[j].dc + parseInt(i.total_items_count);
                
                
            }
              }
            });

        // Invoices Map
        let InvoiceMap = response.data.cpInv.map(i => {
          var monthInv= moment(i.invoice_create_at).format("MM");
          monthsList.find(m => m.value == monthInv);
           for (let j = 0; j < monthsList.length; j++) {
              if (monthsList[j].value == monthInv) {
                  monthsList[j].invoices = monthsList[j].invoices+  parseInt(i.grand_total_gst);
              }
              
          }
        });
        
        console.log(monthsList)
        setData(response.data);



      }
      
      
      )
      .catch(function (err) {
        console.log(err);
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const headdetails = () => {
    return (
      <>
        {console.log(data.Data[0].company)}
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Company - {data.Data[0].company}
        </Typography>

        <Link
          to={`/admin/company/edit/${companyId}`}
          className="ui secondary button tiny"
        >
          <Icon name="print" /> Edit
        </Link>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const countSummary = (arr, field) => {
    return;
  };

  React.useEffect(() => {
    CallCompany();
  }, []);

  return (
    <>
      {data.Data && (
        <DisplayLayout
          data={data}
          navigate={navigate}
          headdetails={headdetails}
        >
          <SubNavBar />
          <Grid container spacing={2}>
            
            <Grid item xs={5}>
              <Card sx={{ ml: 2, textAlign: "left" }}>
                <CardContent>
                  <Typography variant="h6">
                    {data.Data[0].company}{" "}
                    <small>
                      (<Link to={"/admin/company/edit/" + companyId}>Edit</Link>
                      )
                    </small>
                  </Typography>
                  <Typography variant="p">
                    Address: {data.Data[0].address}
                  </Typography>
                  <br />
                  <Typography variant="div" width={"300px"} sx={{ mr: 7 }}>
                    Phone: {data.Data[0].phone}{" "}
                  </Typography>
                  <br />
                  <Typography variant="p">
                    Email: {data.Data[0].email}
                  </Typography>
                  <br />
                  <Typography variant="div" width={"300px"} sx={{ mr: 7 }}>
                    NTN#: {data.Data[0].ntn}{" "}
                  </Typography>
                  <br />
                  <Typography variant="p">STN#: {data.Data[0].ntn}</Typography>
                  <br />
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ textAlign: "left",  height: "100%" }}>
                <CardContent>
                  <Typography variant="h6">Amount  Summary</Typography>
                  <Table size="small" aria-label="a dense table">
                  <TableRow>
                      <TableCell>
                        <strong>Unpaid</strong>
                      </TableCell>
                      <TableCell> 
                      {data.cpInv[0] &&
                          FormattingNumber(data.cpInv
                            .map((datum) => datum.status === 'Unpaid' ? datum.grand_total_gst : 0)
                            .reduce((a, b) => a + b))}.00
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"40%"}>
                        <strong>Paid</strong>
                      </TableCell>
                      <TableCell>{data.cpInv[0] &&
                          FormattingNumber(data.cpInv
                            .map((datum) => datum.status === 'Paid' ? datum.grand_total_gst : 0)
                            .reduce((a, b) => a + b))}.00</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"40%"}>
                        <strong>Balance</strong>
                      </TableCell>
                      <TableCell>
                        {FormattingNumber(data.Data[0].balance_amt)}.00
                      {/* {data.cpInv[0] &&
                          data.cpInv
                            .map((datum) => moment(datum.invoice_due_at).format("YYYY-MM-DD") > today || datum.status === "Paid" ? null : datum.grand_total_gst) 
                            .reduce((a, b) => a + b)} */}
                      </TableCell>
                    </TableRow>
                   
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                sx={{
                  mr: 2,
                  textAlign: "left",
                  minHeight: "100%",
                  verticalAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6">Summary</Typography>
                  <Table size="small" aria-label="a dense table">
                    <TableRow>
                      <TableCell width={"20%"}>
                        <strong>Quotations</strong>
                      </TableCell>
                      <TableCell>
                        {data.cpQuote[0] && data.cpQuote.length}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"20%"}>
                        <strong>DC</strong>
                      </TableCell>
                      <TableCell>{data.cpDc[0] && data.cpDc.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Invoices</strong>
                      </TableCell>
                      <TableCell>
                        {data.cpInv[0] && data.cpInv.length}{" "}
                        {/* {data.cpQuote[0] &&
                          data.cpQuote
                            .map((datum) => datum.total_items_count)
                            .reduce((a, b) => a + b)}
                        /
                        {data.cpQuote[0] &&
                          data.cpQuote
                            .map((datum) => datum.total_items_qty)
                            .reduce((a, b) => a + b)} */}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Card sx={{ maxWidth: "100%", mx: 2, textAlign: "left", my: 2 }}>
            <CardContent>
              {/* data: ['January', 'Feburary', 'March', "April","May", "June", 'July', 'August', 'September', "October","November", "December",], */}
              <ChartContainer
                xAxis={[
                  {
                    scaleType: "band",
                    data: [
                      "January",
                      "Feburary",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ],
                    id: "quarters",
                    label: "Quarters",
                  },
                ]}
                yAxis={[{ id: "money" }, { id: "quantities" }]}
                series={[
                  {
                    type: "bar",
                    id: "quotations",
                    yAxisKey: "money",
                    // data: monthsList.quotation,
                    data: [100,200],
                    label: "Quotations",
                  },
                  {
                    type: "bar",
                    id: "dc",
                    yAxisKey: "quantities",
                    // data: [10,25],
                    data: glist(),
                    label: "Delivery Challan",
                  },
                  {
                    type: "bar",
                    id: "invoice",
                    yAxisKey: "money",
                    // data:  monthsList.invoices, 
                    data: [100,200],
                    label: "Invoice",
                  },
                  {
                    type: "bar",
                    id: "payments",
                    yAxisKey: "money",
                    // data: monthsList.payment,
                    data: [100,200],
                    label: "Payments",
                  },
                ]}
                width={1000}
                height={400}
                margin={{ left: 70, right: 70 }}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "rotate(-90deg) translate(0px, -20px)",
                  },
                  [`.${axisClasses.right} .${axisClasses.label}`]: {
                    transform: "rotate(90deg) translate(0px, -25px)",
                  },
                }}
              >
                <BarPlot />
                
                <ChartsXAxis
                  axisId="quarters"
                  label="Year 2023"
                  labelFontSize={18}
                />
                <ChartsYAxis axisId="money" label="Amount" />
                <ChartsYAxis
                  axisId="quantities"
                  position="right"
                  label="Item Sold"
                />
              </ChartContainer>
              <Select value={SelectValue} onChange={(e)=>setSelectValue(e.target.value)}>
                <MenuItem selected="true" key="7 Days" value="7 Days">1 Week</MenuItem>
                <MenuItem key="15days" value="15 Days">2 Weeks</MenuItem>
                <MenuItem key="30days" value="1 Month">1 Month</MenuItem>
                <MenuItem key="90days" value="3 Month">3 Months</MenuItem>
                <MenuItem key="180days" value="6 Month">6 Months</MenuItem>
                <MenuItem key="365days" value="12 Month">12 Month</MenuItem>
                <MenuItem key="lastyear" value="Last Year">Last Year</MenuItem>
                <MenuItem key="All" value="All">All</MenuItem>
              </Select>
            </CardContent>
            <CardActions></CardActions>
          </Card>

            {data &&
              data.cpQuote.map((i) => {
                return console.log(moment(i.create_at).format("MM-YY"));
              })}

          <Paper sx={{ mx: 1, py: 2, my: 2 }}>
            <Box sx={{ bgcolor: "background.paper", width: "96%", mx: 2 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="#000000"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={"Quotation(" + data.cpQuote.length + ")"} />
                <Tab label={"Delivery Challan (" + data.cpDc.length + ")"} />
                <Tab label={"Invoices (" + data.cpInv.length + ")"} />
                <Tab label={"Payments (" + data.cpPay.length + ")"} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <DataGrid
                  sx={{ mx: 2, p: 1, background: "#ffffff" }}
                  density="standard"
                  autoHeight
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    {
                      field: "id",
                      hideable: true,
                      headerName: "ID#",
                      width: 70,
                    },
                    {
                      field: "created_at",
                      type: "date",
                      hideable: true,
                      headerName: "Created at",
                      valueGetter: (params) => {
                        return moment(params.row.created_at).format(
                          "YYYY-MM-DD"
                        );
                      },
                      width: 150,
                    },
                    {
                      field: "quote_number",
                      hideable: true,
                      headerName: "Quote#",
                      align: "left",
                    },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                      align: "left",
                    },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                      align: "left",
                      editable: true,
                    },

                    {
                      field: "grand_total_gst",
                      hideable: true,
                      headerName: "Grand Total",
                    },

                    // {
                    //   field: "items_calculate",
                    //   hideable: true,
                    //   headerName: "Items Count/Qty/Left",
                    //   valueGetter: (params) => {
                    //     return (
                    //       params.row.total_items_count +
                    //       "/" +
                    //       params.row.total_items_qty +
                    //       "/" +
                    //       params.row.items_left
                    //     );
                    //   },
                    //   width: 150,
                    // },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link target="_blank" to={`/admin/quotation/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  rows={data.cpQuote}
                  pageSize="25"
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  sx={{ mx: 2, p: 1, background: "#ffffff" }}
                  density="standard"
                  autoHeight
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    {
                      field: "id",
                      hideable: true,
                      headerName: "ID#",
                      width: 70,
                    },
                    {
                      field: "dc_date",
                      hideable: true,
                      headerName: "Created on",
                      valueGetter: (params) => {
                        return moment(params.row.created_at).format(
                          "YYYY-MM-DD"
                        );
                      },
                      width: 150,
                    },
                    {
                      field: "dc_number",
                      hideable: true,
                      headerName: "DC#",
                    },
                    {
                      field: "quote_number",
                      hideable: true,
                      headerName: "Quote#",
                    },
                    { field: "ref_number", hideable: true, headerName: "Ref#" },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                    },
                    
                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items Count/Rejected",
                      valueGetter: (params) => {
                        return (
                          params.row.total_items_count +
                          "/" +
                          params.row.total_rejected_qty
                        );
                      },
                      width: 150,
                    },
                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/dc/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  rows={data.cpDc}
                  pageSize="25"
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  sx={{ mx: 2, p: 1, background: "#ffffff" }}
                  density="standard"
                  autoHeight
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    {
                      field: "id",
                      hideable: true,
                      headerName: "ID#",
                      width: 70,
                    },
                   
                    {
                      field: "invoice_number",
                      hideable: true,
                      headerName: "Invoice#",
                    },
                    { field: "po_number", hideable: true, headerName: "PO#" },
                    { field: "ref_number", hideable: true, headerName: "Ref#" },
                    { field: "status", hideable: true, headerName: "Status" },
                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items",
                      width: 40,
                    },
                    { field: "grand_total_gst", hideable: true, headerName: "Amount" },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/invoice/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  rows={data.cpInv}
                  pageSize="25"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <DataGrid
                  sx={{ mx: 2, p: 1, background: "#ffffff" }}
                  density="standard"
                  autoHeight
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    {
                      field: "id",
                      hideable: true,
                      headerName: "ID#",
                      width: 70,
                    },
                    {
                      field: "created_at",
                      type: "date",
                      hideable: true,
                      headerName: "Created at",
                      valueGetter: (params) => {
                        return moment(params.row.created_at).format(
                          "YYYY-MM-DD"
                        );
                      },
                      width: 150,
                    },
                    {
                      field: "payment_number",
                      hideable: true,
                      headerName: "Payment#",
                      align: "left",
                    },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                      align: "left",
                    },
                    {
                      field: "payment_method",
                      hideable: true,
                      headerName: "Method",
                    },
                    {
                      field: "receipt_number",
                      hideable: true,
                      headerName: "Receipt #",
                      align: "left",
                      editable: true,
                    },
                   
                    {
                      field: "receipt_amount",
                      hideable: true,
                      headerName: "Total",
                    },

                    // {
                    //   field: "items_calculate",
                    //   hideable: true,
                    //   headerName: "Items Count/Qty/Left",
                    //   valueGetter: (params) => {
                    //     return (
                    //       params.row.total_items_count +
                    //       "/" +
                    //       params.row.total_items_qty +
                    //       "/" +
                    //       params.row.items_left
                    //     );
                    //   },
                    //   width: 150,
                    // },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link target="_blank" to={`/admin/payment/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  rows={data.cpPay}
                  pageSize="25"
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                />
              </TabPanel>
            </Box>
          </Paper>
        </DisplayLayout>
      )}
    </>
  );
}
