import axios from "axios";

export default axios.create({
  baseURL: "https://webapi.automarts.pk/",
  // baseURL: "http://localhost:3001/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// export default magento;
