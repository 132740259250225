import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation, useParams } from "react-router-dom";
import InquiryPDF from "../../components/pdf/Inquriy/InquiryPDF";
import { getCall } from "../../Helper/Helper";

export const InquriyPrint = ({}) => {
    
  const [formData, setformData] = useState("");
    const { id } = useParams();

    const apiCall = async (id) => {
      await getCall("rfq/" + id).then((response) => {
        
        setformData(response);
        // data.f = response;
        console.log('Call - ' ,response)
        return formData;
      });
      
    };
    console.log(formData);
  
  useEffect(() => {
      console.log(id)
       apiCall(id);
      
    }, []);

    

    return (
        <PDFViewer width="100%" height="1000px">
            <InquiryPDF data={formData} />
        </PDFViewer>
      );
}

