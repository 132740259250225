import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
} from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";
import NotificationMessage from "../../components/NotificationMessage";
import SubNavBar from "../../components/SubNavBar";

export default function Company({ navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [company, setCompany] = useState([]);
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);
  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = company.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = company.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };

  const CallCompany = async () => {
    await magento
      .get("company/channel/" + profile[0].id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const SearchCompany = async (companyname) => {
    await magento
      .get("search/company/" + companyname, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCompany(response.data);
        return company;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallCompany();
  }, []);

  console.log(company);
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Companies
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left">
            <DataGrid
              sx={{ mx: 8, mb: 2, p: 2, background: "#ffffff" }}
              density="comfortable"
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              components={{ Toolbar: GridToolbar }}
              autoHeight
              columns={[
                { field: "id", hideable: true, headerName: "S#", width: 50 },
                {
                  field: "company",
                  hideable: true,
                  headerName: "Company Name",
                  width: 250,
                },
                { field: "abbr", hideable: true, headerName: "Abbr" },
                {
                  field: "address",
                  hideable: true,
                  headerName: "Address",
                  width: 500,
                },
                {
                  field: "stn",
                  hideable: true,
                  headerName: "STN#",
                  width: 100,
                },
                {
                  field: "ntn",
                  hideable: true,
                  headerName: "NTN#",
                  width: 100,
                },
                {
                  field: "actions",
                  type: "actions",
                  headerName: "Action",
                  getActions: (GridRowParams) => [
                    <Link to={`/admin/company/view/${GridRowParams.id}`}>
                      View
                    </Link>,
                    <Link
                      to={`/admin/company/edit/${GridRowParams.id}`}
                      state={GridRowParams}
                    >
                      Edit
                    </Link>,
                  ],
                },
              ]}
              rows={company}
              pageSize="25"
            />
          </Grid>
        </Grid>

        <div style={{ position: "fixed", bottom: 20, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/company/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
