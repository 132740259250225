import React, { useState } from "react";
import "./../create.css";

import { useParams } from "react-router-dom";
  import {
  Paper,
  Tab,
  Tabs,
  Card,
  CardContent,
  CardActions,
  Table,
  TableRow,
  TableCell,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import DisplayLayout from "../../components/DisplayLayout";
import { Button, Divider, Icon } from "semantic-ui-react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";
import { getCall } from "../../Helper/Helper";
import { ArrowBack, Send } from "@mui/icons-material";
const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function ViewVendor({ navigate }) {
  const { VendorId } = useParams();
  const [formData, setFormData] = useState("");
  const [value, setValue] = useState(0);

    
  const DataCall = async () => {
    await getCall('vendor/' + VendorId).then((response) => {
      console.log('nEW', response);
      setFormData({ vendor: response.vendor[0], vendorItems:response.vendorItems});  
        return formData
    })
}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const headdetails = () => {
    return (
      <>
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Vendor - {formData.vendor.vendor_name}
        </Typography>

        <Link
          to={`/admin/vendor/edit/${VendorId}`}
          className="ui secondary button tiny"
        >
          <Icon name="print" /> Edit
        </Link>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  React.useEffect(() => {
    DataCall();
  }, []);

  return (
    <>
      {formData && (
        <DisplayLayout
          data={formData}
          navigate={navigate}
          headdetails={headdetails}
        >
          <SubNavBar />

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card
                sx={{
                  ml: 2,
                  textAlign: "ceter",
                  minHeight: "100%",
                  verticalAlign: "center",
                }}
              >
                <p>Logo</p>
              </Card>
            </Grid>
            <Grid item xs={5}>
              <Card sx={{ ml: 2, textAlign: "left" }}>
                <CardContent>
                  <Typography variant="h6">
                  {formData.vendor.vendor_name}
                    <small>
                      (<Link to={"/admin/vendor/edit/" + VendorId}>Edit</Link>)
                    </small>
                  </Typography>
                  <Typography variant="p">
                    Person: {formData.vendor.person_name} 
                  </Typography>
                  <br />
                  <Typography variant="p">
                    Address: {formData.vendor.address}
                  </Typography>
                  <br />
                  <Typography variant="div" width={"300px"} sx={{ mr: 7 }}>
                    Phone: {formData.vendor.phone}{" "}
                  </Typography>
                  <br />
                  <Typography variant="p">
                    Mobile: {formData.vendor.mobile}
                  </Typography>
                  <br />
                  <Typography variant="div" width={"300px"} sx={{ mr: 7 }}>
                    Email: {formData.vendor.email}{" "}
                  </Typography>
                  <br />
                  <Typography variant="p">Note: {formData.vendor.note}</Typography>
                  <br />
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ textAlign: "left", marginRight: 2, height: "100%" }}>
                <CardContent>
                  <Typography variant="h6">Summary</Typography>
                  <Table size="small" aria-label="a dense table">
                    <TableRow>
                      <TableCell width={"30%"}>
                        <strong>Balance:</strong>
                      </TableCell>
                      <TableCell>
                      {formData.vendor.balance_amt}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"30%"}>
                        <strong>Credit:</strong>
                      </TableCell>
                      <TableCell>
                      {formData.vendor.balance_amt}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Purchased:</strong>
                      </TableCell>
                      <TableCell>
                        {formData.vendor.balance_amt}
                        {/* {formData.cpQuote[0] &&
                          formData.cpQuote
                            .map((datum) => datum.total_items_count)
                            .reduce((a, b) => a + b)}
                        /
                        {formData.cpQuote[0] &&
                          formData.cpQuote
                            .map((datum) => datum.total_items_qty)
                            .reduce((a, b) => a + b)} */}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{
            display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', mt: 3,
          }}>
          <Paper sx={{ width: "25%", py: 2 }}>
            <Grid fluid sx={{ p: 2, pt: 0 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <InputLabel sx={{ mx: 0, px: 0, pb:6}}>PR Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={formData.prStatus}
                    onChange={(e) => {
                      setFormData({ ...formData, prStatus: e.target.value })
                      return formData;
                  }}
                    label="PR Status"
                    autoWidth
                    fullWidth
                    sx={{ textAlign: "left" }}
                    size="small"
                  >
                    <MenuItem sx={{ textAlign: "left" }} value="Active">
                      Active
                    </MenuItem>
                    <MenuItem sx={{ textAlign: "left" }} value="Expired">
                         Expired
                    </MenuItem>
                    <MenuItem sx={{ textAlign: "left" }} value="In Progress">
                      In Progress
                    </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Pending">
                      Pending
                    </MenuItem>
                    <MenuItem sx={{ textAlign: "left" }} value="Closed">
                      Closed
                   </MenuItem>
                   <MenuItem sx={{ textAlign: "left" }} value="Hold">
                      Hold
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Dropped">
                      Dropped
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "1%", width: "98%" }}
            >
               <TextField
                id="pr_number"
                name="pr_number"
                label="PR/RFQ Number"
                
                value={formData.pr}
                onChange={(e) =>
                  setFormData({ ...formData, pr: e.target.value })
                }
                type="text"
              />
              </FormControl>
              <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
             <TextField
                id="company"
                name="company"
                label="Company"
                value={formData.companyName}
                    disabled
              />
                
              </FormControl>
              <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
           <TextField
                id="department"
                name="depart"
                label="Department"
                value={formData.depart}
                onChange={(e) =>
                  setFormData({ ...formData, depart: e.target.value })
                }
                                  type="text"
                                  disabled
              />
                
              </FormControl>
              <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              
              <TextField
                id="department"
                name="depart"
                label="Department"
                value={formData.depart}
                onChange={(e) =>
                  setFormData({ ...formData, depart: e.target.value })
                }
                                  type="text"
                                  disabled
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
              <TextField
                  disabled
                  fullWidth
                  id="submit_date"
                  label="Submit Date"
                  type="date"
                  defaultValue={formData.submitDate}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, submitDate: e.target.value });
                    return formData;
                  }}
                />{" "}
              </FormControl>
              <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "96%" }}
            >
                              <TextField
                                  disabled
                  fullWidth
                  id="receive_date"
                  label="PR Received on"
                  type="date"
                  defaultValue={formData.recevieDate}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, recevieDate: e.target.value });
                    return formData;
                  }}
                />{" "}
              </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            
          >
            Update RFQ
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
        <Paper sx={{ width: '70%', py: 2, fontSize:"12px" }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              Vendor Items
            </Typography>
          </Grid>
          <Grid fluid sx={{ px: 0 }}>
          <DataGrid 
          sx={{minHeight:'700px',  fontSize:"10px"}}
          density="compact"
          // checkboxSelection
          // onSelectionModelChange={(newSelectionModel) =>  {
          //   setSelectedInvoice(newSelectionModel);
          //   return CalculateTotal(newSelectionModel);
          // }
          // }
          // selectionModel={selectedInvoice}
          // rowSelectionModel={rowSelectionModel} 
          
          columns={[
            {
              field: "item_code", headerName: "Code",
              renderCell: (params) => <Link target="_blank" to={`/admin/item/view/${params.row.item_id}`}>{params.row.item_code}</Link>,
            },
            {
              field: "item_title", headerName: "Title",
              width: 250,
              renderCell: (params) => <Link target="_blank" to={`/admin/item/view/${params.row.item_id}`}>{params.row.item_title}</Link>,  },
            { field: "price", hideable: true, headerName: "Price/MOU", renderCell: (params) => <>{params.row.price}/{params.row.unit_of_measure}</> , },
            { field: "notes", hideable: true, headerName: "Notes", },
          ]}
          rows={formData.vendorItems}
          pageSize="50"
        />
          </Grid>
          
        </Paper>
        </Box>
        </DisplayLayout>
      )}
    </>
  );
}
