import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./dashboard.css";
import { AuthContext } from "../Context/AuthContext";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountBalanceWallet,
  AttachMoney,
  DescriptionOutlined,
  DescriptionSharp,
  DoNotDisturb,
  MenuOutlined,
  Person,
  Receipt,
  Settings,
} from "@mui/icons-material";
import { TableBody } from "semantic-ui-react";
import magento from "../api/magento";
import moment from "moment";

const Banking = ({ navigate }) => {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [data, setData] = React.useState([]);
  const { useAuth } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);

  const handleClick = (event, menu = event.currentTarget.id) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setMenu([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const DataCall = async () => {
    await magento
      .get("data/channel/" + profile[0].id, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        setData(response.data);
        return data;
      })
      .catch((error) => console.log("Error: ", error.message));
  };

  React.useEffect(() => {
    return DataCall();
  }, []);
  return (
    <div>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="primary"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Banking
            </Typography>
          </Toolbar>
        </AppBar>
        <Popover
          id={id}
          open={open}
          sx={{ p: 1 }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menu.map((item) => (
            <Box width={"200px"} sx={{ px: 1, pt: 1 }}>
              <Link to={item.url}>
                <Typography width>{item.title}</Typography>
              </Link>
            </Box>
          ))}
        </Popover>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Paper sx={{ p: 2, px: "20%" }}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Banking Tasks
              </Typography>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="item"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "New Item", url: "/admin/item/create" },
                      { title: "Edit & View Items", url: "/admin/item" },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Person fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Inventory Items
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="writechecks"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Settings fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Write Checks
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={() => navigate("/admin/settings")}
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Settings fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Settings
                  </Typography>
                </Paper>
              </Box>
              <Divider />
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="po"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "New Purchase Order", url: "/admin/po/create" },
                      { title: "View & Edit Purhcase Order", url: "/admin/po" },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionSharp fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    PO
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejections"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionSharp fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Items Rejections
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="enterbills"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Receive Inventory
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="paybills"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Ship Package
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="paybills"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Track Package
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="voidchecks"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Receipt fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Inventory Count
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="creditreturn"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AccountBalanceWallet fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Iventory Adjs.
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="recive_money"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AttachMoney fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Reports
                  </Typography>
                </Paper>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TableContainer component={Paper} fluid>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ p: 1 }}
                textAlign="left"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  <DescriptionSharp fontSize="12" sx={{ mt: 1 }} /> Recent
                  Quotation
                </span>
                <Link to={"/admin/quotation"}>
                  <Typography fontSize="12" textAlign={"right"}>
                    View All
                  </Typography>
                </Link>
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Quote#</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Ref#</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Items Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.Quote &&
                    data.Quote.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Link to={`/admin/quotation/view/${row.id}`}>
                            {row.quote_number}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {moment(row.create_at).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.ref_number}</TableCell>
                        <TableCell>
                          <Link to={"/admin/company/view/" + row.company_id}>
                            {row.quote_to_company}
                          </Link>
                        </TableCell>
                        <TableCell>{row.total_items_count}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <br />
            </TableContainer>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TableContainer component={Paper} fluid>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ p: 1 }}
                textAlign="left"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  <DescriptionOutlined fontSize="12" sx={{ mt: 1 }} /> Recent
                  Delivery Challan
                </span>
                <Link to={"/admin/delivery-challans"}>
                  <Typography fontSize="12" textAlign={"right"}>
                    View All
                  </Typography>
                </Link>
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>DC#</TableCell>
                    <TableCell>Quote#</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Item Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.Quote &&
                    data.DC.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          <Link to={`/admin/dc/view/${row.id}`}>
                            {row.dc_number}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Link to={`/admin/dc/view/${row.id}`}>
                            {row.quote_number}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {moment(row.create_at).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          <Link to={"/admin/company/view/" + row.company_id}>
                            {row.dc_to_company}
                          </Link>
                        </TableCell>
                        <TableCell>{row.total_items_count}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <br />
            </TableContainer>
          </Grid>
          <Grid item xs={2} sm={4} md={12}>
            <TableContainer component={Paper} fluid>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ p: 1 }}
                textAlign="left"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  <DescriptionOutlined fontSize="12" sx={{ mt: 1 }} /> Recent
                  Recent Invoices
                </span>
                <Link to={"/admin/invoices"}>
                  <Typography fontSize="12" textAlign={"right"}>
                    View All
                  </Typography>
                </Link>
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Invoice#</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>PO At</TableCell>
                    <TableCell>Quote#</TableCell>
                    <TableCell>DC#</TableCell>
                    <TableCell>Ref#</TableCell>
                    <TableCell>Invoiced Items</TableCell>
                    <TableCell>Grand Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.Quote &&
                    data.Invoices.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Link to={"/admin/company/view/" + row.company_id}>
                            {row.bill_to_company}
                          </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Link to={`/admin/invoice/view/${row.id}`}>
                            {row.invoice_number}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {moment(row.invoice_create_at).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.po_number}</TableCell>
                        <TableCell>
                          <Link to={`/admin/quotation/view/${row.id}`}>
                            {row.quotation_number}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/admin/dc/view/${row.id}`}>
                            {row.dc_number}
                          </Link>
                        </TableCell>
                        <TableCell>{row.ref_number}</TableCell>
                        <TableCell>{row.total_items_count}</TableCell>
                        <TableCell>{row.grand_total_gst.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <br />
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      {useAuth()}
    </div>
  );
};

export default Banking;
