import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
} from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import NotificationMessage from "../../components/NotificationMessage";

export default function Channel({ navigate }) {
  const [Channel, setChannel] = useState([]);

  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);
  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Channel.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Channel.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };

  const CallChannel = async () => {
    await magento
      .get("https://webapi.automarts.pk/channels", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setChannel(response.data);
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallChannel();
  }, []);

  console.log(Channel);
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Channel
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left">
            <TableContainer component={Paper} fluid>
              <br />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Logo</TableCell>
                    <TableCell>TItle</TableCell>
                    <TableCell>DC Series</TableCell>
                    <TableCell>Quote Series</TableCell>
                    <TableCell>SO Series</TableCell>
                    <TableCell>ST Series</TableCell>
                    <TableCell>SRCT Series</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Channel.map((item) => {
                    console.log(item);
                    const pimage =
                      item.logo === ""
                        ? "https://react.semantic-ui.com/images/wireframe/image.png"
                        : "http://accounting.automarts.pk/assets/products/" +
                          item.logo;
                    return (
                      <TableRow>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                          <Avatar src={pimage} />
                        </TableCell>
                        <TableCell>
                          <strong>{item.channel}</strong>
                        </TableCell>
                        <TableCell>{item.dc_series}</TableCell>
                        <TableCell>{item.qoute_series}</TableCell>
                        <TableCell>{item.so_series}</TableCell>
                        <TableCell>{item.salestax_series}</TableCell>
                        <TableCell>{item.servicetax_series}</TableCell>
                        <TableCell>{item.phone}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>
                          <Link
                            to={`/admin/channel/view/${item.id}`}
                            state={item}
                          >
                            View
                          </Link>{" "}
                          |{" "}
                          <Link
                            to={`/admin/channel/edit/${item.id}`}
                            state={item}
                          >
                            Edit
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <br />
            </TableContainer>
          </Grid>
        </Grid>
        <div style={{ position: "fixed", bottom: 20, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/channel/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
