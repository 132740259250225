import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Avatar,
  Box,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  GridRowParams,
} from "@mui/x-data-grid";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";
import NotificationMessage from "../../components/NotificationMessage";

export default function Item({ navigate }) {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [Item, setItem] = useState([]);
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [ItemPerPage, setItemPerPage] = useState(15);
  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Item.slice(IndexOfFirstItem, IndexOfLastItem);

  const CallItem = async () => {
    await magento
      .get("https://webapi.automarts.pk/item/channel/" + profile[0].id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setItem(response.data);
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const SearchItem = async (quoteNo) => {
    await magento
      .get(
        "https://webapi.automarts.pk/search/quote/channel/" +
          localStorage.getItem("channel") +
          "/" +
          quoteNo,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setItem(response.data);
        return Item;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Item.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  useEffect(() => {
    CallItem();
  }, []);

  console.log(Item);
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Items{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 8, mb: 2, p: 2, background: "#ffffff" }}
          density="compact"
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columns={[
            { field: "id", hideable: false, headerName: "S#", width: 50 },

            { field: "item_code", hideable: false, headerName: "Item Code" },
            { field: "sku", hideable: true, headerName: "SKU" },
            { field: "part_no", hideable: true, headerName: "Part#" },
            {
              field: "item_title",
              hideable: true,
              headerName: "Title",
              width: 220,
            },
            {
              field: "currency",
              hideable: true,
              headerName: "Currency",
            },
            {
              field: "price",
              hideable: true,
              headerName: "Price",
            },
            {
              field: "cost",
              hideable: true,
              headerName: "Cost",
            },

            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link
                  to={`/admin/item/view/${GridRowParams.id}`}
                  state={GridRowParams.row}
                >
                  View
                </Link>,
                <Link
                  to={`/admin/item/edit/${GridRowParams.id}`}
                  state={GridRowParams.row}
                >
                  Edit
                </Link>,
              ],
            },
          ]}
          rows={Item}
          pageSize="25"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/item/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
