import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";

import {FormattingNumber} from '../../Helper/Helper';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select  from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import magento from "../../api/magento";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import Payments from "./Payments";
import SubNavBar from "../../components/SubNavBar";

export default function ViewPayment({ navigate }) {
  const { PayId } = useParams();
  const [unPaid, setUnPaid] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [formData, setFormData] = useState({
    payments:[{
      payment_number: '',
      payment_method: "",
      ref_number:'',
      receipt_amount:0,
      income_tax_amt:0,
      total_savings: 0,
    }],
    paidInvoices:[]
  }); 

 
  const handleSelectChange = (e) => {
    // alert(e.target.value);
    setFormData({ ...formData, taxtype: `${e.target.value}` });
    return formData;
  };

  const PaymentDataCall = async () => {
    await magento.get("payment/"+ PayId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log('loaded', response.data);
        setFormData(response.data)
        return formData;
      })
      .catch(function (err) {
        console.log(err);
      });
  };


  React.useEffect(() => {
    PaymentDataCall(); 
  },[]);
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Payment #{formData.payments && formData.payments[0].payment_number}
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-around' }}>
        <Paper sx={{ width: "30%", py: 2 }}>
        <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
        <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
            Company:
              </Typography>
              <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
           {formData.payments && formData.payments[0].company} 
              </Typography>
          </Grid>       


        <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
        
            <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
            Payment Method:
              </Typography>
              <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
           {formData.payments && formData.payments[0].payment_method} 
              </Typography>
          </Grid>
         <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
            <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
            Reciept Number:
              </Typography>
              <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
           {formData.payments && formData.payments[0].receipt_number} 
              </Typography>
          </Grid>
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
            <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
            Ref Number:
              </Typography>
              <Typography variant="h3"  fontSize={14} sx={{display:'flex'}}>
           {formData.payments && formData.payments[0].ref_number} 
              </Typography>
          </Grid>
          <Divider />
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
            <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
            Receipt Amount:
              </Typography>
              <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
           {FormattingNumber(formData.payments && formData.payments[0].receipt_amount)} 
              </Typography>
          </Grid>
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
          <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
          Income Taxes: 
              </Typography>
           <Typography variant="h5"  fontSize={16} sx={{display:'flex'}}>
            {FormattingNumber(formData.payments && formData.payments[0].income_tax_amt)} 
              </Typography>
          </Grid>
          <Divider />
          <Divider />
          <Grid fluid sx={{py:0.4, px: 2, textAlign:'left', display: "flex", justifyContent:"space-between" }}>
          <Typography variant="h5"  fontSize={22} sx={{display:'flex'}}>
          Amount:
              </Typography>
              <Typography variant="h5"  fontSize={22} sx={{display:'flex'}}>
             {FormattingNumber(formData.payments && formData.payments[0].total_savings)} 
              </Typography>
          </Grid> 

          <Divider />
          
        </Paper>
        <Paper sx={{ width: '65%', py: 2 }}>
          <Grid fluid sx={{ p: 2 }}>
            <Typography variant="h5" textAlign="left">
              Paid Invoices against #{formData.payments &&  formData.payments[0].payment_number}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
          <DataGrid 
          sx={{minHeight:'400px'}}
          density="standard"
          
          selectionModel={selectedInvoice}
          disableSelectionOnClick
          
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 50 },
            { field: "status", hideable: true, headerName: "Status", width: 100 },     
            { field: "invoice_number", hideable: true, headerName: "Invoice#" },
            { field: "invoice_number_type", hideable: true, headerName: "ST/SST Invoice" },
            { field: "grand_total_gst", hideable: true, headerName: "Amount", },
            // {
            //   field: "payment_method",
            //   hideable: true,
            //   headerName: "Payment Method",
            // },
            // {
            //   field: "receipt_amount",
            //   hideable: true,
            //   headerName: "Payment Ammount",
            // },
            {
              field: "actions",
              type: "actions",
              width: 200,
              getActions: (GridRowParams) => [
                <Link to={`/admin/invoice/view/${GridRowParams.row.id}`} target="_blank">
                  View
                </Link>,
               
              ],
            },
          ]}
          rows={formData.payments && formData.paidInvoices }
          pageSize="50"
        />
          </Grid>
          
        </Paper>
        </Box>
      </Box>
    </>
  );
}
