import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";

// Layouts
import Layout from "./components/Layout";

// General
import SignIn from "./Login/SignIn";
import SignOut from "./Login/SignOut";

//Admin Pages
import Dashboard from "./admin/Dashboard";
import Vendor from "./admin/vendor/Vendor";

import Quotations from "./admin/Quote/Quotations";
import ViewQuotation from "./admin/Quote/ViewQuotation";
import AddQuotation from "./admin/Quote/AddQuotation";
import ViewPdf from "./admin/Pdf/ViewPDF";

import Company from "./admin/company/Company";
import AddCompany from "./admin/company/AddCompany";
import ViewCompany from "./admin/company/ViewCompany";
import EditCompany from "./admin/company/EditCompany";

import Item from "./admin/items/Item";
import EditItem from "./admin/items/EditItem";
import AddItem from "./admin/items/AddItem";
import ViewItem from "./admin/items/ViewItem";

import DeliveryChallan from "./admin/DeliveryChallan/DeliveryChallan";
import ViewDc from "./admin/DeliveryChallan/ViewDc";
// import ViewDCPDF from "./admin/backups/ViewDCPDF";
import AddDc from "./admin/DeliveryChallan/AddDc";

import Channel from "./admin/Channel/Channel";
import AddChannel from "./admin/Channel/AddChannel";
import EditChannel from "./admin/Channel/EditChannel";
import ViewChannel from "./admin/Channel/ViewChannel";
import Invoices from "./admin/Invoice/Invoices";
import EditInvoice from "./admin/Invoice/EditInvoice";
import AddNewInvoice from "./admin/Invoice/AddNewInvoice";
// import ViewInvoice from "./admin/Invoice/ViewInvoice";
// import ViewInvPDF from "./admin/backups/ViewInvPDF";
import Users from "./admin/Users/Users";
import Rejections from "./admin/Rejections/Rejections";
import AddUser from "./admin/Users/AddUser";
import AddVendor from "./admin/vendor/AddVendor";
import Settings from "./admin/Settings/Settings";
import Logs from "./admin/Settings/Logs";
import MediaUpload from "./admin/Media/MediaUpload";
import VendorPurchase from "./admin/VendorPurchase";
import InventoryServices from "./admin/InventoryServices";
import EmployeesPayroll from "./admin/EmployeesPayroll";
import Banking from "./admin/Banking";
import ProfileCompany from "./admin/ProfileCompany";
import PreviewPdf from "./admin/Pdf/PreviewPDF";
import EditQuotation from "./admin/Quote/EditQuotation";
import EditUser from "./admin/Users/EditUser";
import Taxes from "./admin/Taxes/Taxes";
import AddTax from "./admin/Taxes/AddTax";
import EditTax from "./admin/Taxes/EditTax";
import EditDc from "./admin/DeliveryChallan/EditDc";
import EditVendor from "./admin/vendor/EditVendor";
import ViewVendor from "./admin/vendor/ViewVendor";
import ViewNewInvoice from "./admin/Invoice/ViewNewInvoice";
import Magento from "./admin/Magento";
import MageProducts from "./admin/Magento/MageProducts";
import MageAddProducts from "./admin/Magento/MageAddProducts";
import MageEditProducts from "./admin/Magento/MageEditProducts";
import YelpSearch from "./admin/Yelp/YelpSearch";
// import ViewQuoteWSTPDF from "./admin/backups/ViewQuoteWSTPDF";
import Payments from "./admin/Payments/Payments";
import AddPayment from "./admin/Payments/AddPayment";
import ViewPayment from "./admin/Payments/ViewPayment";
import Prints from "./admin/Prints";
import { EnvelopePDF } from "./admin/Prints/EnvelopePDF";
import Persons from "./admin/Person/Persons";
import AddPerson from "./admin/Person/AddPerson";
import EditPerson from "./admin/Person/EditPerson";
import ViewPerson from "./admin/Person/ViewPerson";
import Brands from "./admin/Brands/Brands";
import AddBrand from "./admin/Brands/AddBrand";
import EditBrand from "./admin/Brands/EditBrand";
import AddRFQ from "./admin/RFQs/AddRFQ";
import Rfqs from "./admin/RFQs/rfqs";
import ViewRFQ from "./admin/RFQs/ViewRFQ";
import ReportsInvoices from "./admin/Reports/invoices/ReportsInvoices";
import { ReportsPrint } from "./admin/Prints/ReportsPrint";
import Tasks from "./admin/Tasks/Tasks";
import ItemsQuotedList from "./admin/Quote/ItemsQuotedList";
import Po from "./admin/PO/Po";
import AddPo from "./admin/PO/AddPo";
import ViewPo from "./admin/PO/ViewPo";
import EditPo from "./admin/PO/EditPo";
import InquiryPDF from "./components/pdf/Inquriy/InquiryPDF";
import { InquriyPrint } from "./admin/Prints/InquriyPrint";
import { InquriyPrintBulk } from "./admin/Prints/InquriyPrintBulk";
import EditRFQ from "./admin/RFQs/EditRFQ";
import PT from "./admin/PettyCash/PT";
import So from "./admin/SO/So";
import AddSo from "./admin/SO/AddSO";
import EditSo from "./admin/SO/EditSo";
import ViewSo from "./admin/SO/ViewSo";

function App() {
  const navigate = useNavigate();
  return (
    <div>
      <AuthProvider>
        <div className="App">
          <div className="">
            <Routes>
              <Route path="*" element={<SignIn navigate={navigate} />} />
              <Route path="/admin" element={<Layout navigate={navigate} />}>
                <Route
                  path="magento"
                  element={<Magento navigate={navigate} />}
                />
                <Route
                  path="dashboard"
                  element={<Dashboard navigate={navigate} />}
                />
                <Route
                  path="vendorpurchase"
                  element={<VendorPurchase navigate={navigate} />}
                />
                <Route
                  path="inventoryservice"
                  element={<InventoryServices navigate={navigate} />}
                />
                <Route
                  path="employeespayroll"
                  element={<EmployeesPayroll navigate={navigate} />}
                />
                <Route
                  path="banking"
                  element={<Banking navigate={navigate} />}
                />
                <Route
                  path="profilecompany"
                  element={<ProfileCompany navigate={navigate} />}
                />
                <Route path="vendor" element={<Vendor />} />
                {/* Quotation */}
                <Route
                  path="quotation"
                  element={<Quotations navigate={navigate} />}
                ></Route>
                <Route
                  path="quotation/create"
                  element={<AddQuotation navigate={navigate} />}
                  loader={true}
                />
                <Route
                  path="quotation/edit/:quoteId"
                  element={<EditQuotation navigate={navigate} />}
                />
                <Route
                  path="quotation/view/:quoteId"
                  element={<ViewQuotation navigate={navigate} />}
                />
                <Route
                  path="quotation/items/list"
                  element={<ItemsQuotedList navigate={navigate} />}
                />

                {/* Sales Order */}
                <Route
                  path="so"
                  element={<So navigate={navigate} />}
                ></Route>
                <Route
                  path="so/create"
                  element={<AddSo navigate={navigate} />}
                  loader={true}
                />
                <Route
                  path="so/edit/:soId" 
                  element={<EditSo navigate={navigate} />}
                />
                <Route 
                  path="so/view/:soId"
                  element={<ViewSo navigate={navigate} />}
                />
                {/* <Route
                  path="quotation/items/list"
                  element={<ItemsQuotedList navigate={navigate} />}
                /> */}

                {/* Invoice */}
                <Route
                  path="invoices"
                  element={<Invoices navigate={navigate} />}
                ></Route>
                <Route
                  path="invoices/:type"
                  element={<Invoices navigate={navigate} />}
                ></Route>
                <Route
                  path="invoice/create"
                  element={<AddNewInvoice navigate={navigate} />}
                />
                <Route
                  path="invoice/view/:InvId"
                  element={<ViewNewInvoice navigate={navigate} />}
                />
                <Route
                  path="invoice/view/:tType/:InvId"
                  element={<ViewNewInvoice navigate={navigate} />}
                />
                <Route
                  path="invoice/edit/:InvId"
                  element={<EditInvoice navigate={navigate} />}
                />

                 {/* Vendor PO */}
                 <Route
                  path="po"
                  element={<Po navigate={navigate} />}
                ></Route>
                <Route
                  path="po/create"
                  element={<AddPo navigate={navigate} />}
                />
                <Route
                  path="po/view/:InvId"
                  element={<ViewPo navigate={navigate} />}
                />
                <Route
                  path="po/edit/:InvId"
                  element={<EditPo navigate={navigate} />}
                />


                {/* Company */}
                <Route
                  path="company"
                  element={<Company navigate={navigate} />}
                ></Route>
                <Route
                  path="company/create"
                  element={<AddCompany navigate={navigate} />}
                />
                <Route
                  path="company/view/:companyId"
                  element={<ViewCompany navigate={navigate} />}
                />
                <Route
                  path="company/edit/:companyId"
                  element={<EditCompany navigate={navigate} />}
                />
                {/* Magento Product */}
                <Route
                  path="mproducts"
                  element={<MageProducts navigate={navigate} />}
                ></Route>
                <Route
                  path="mproducts/create"
                  element={<MageAddProducts navigate={navigate} />}
                />
                <Route
                  path="mproducts/edit/:pId"
                  element={<MageEditProducts navigate={navigate} />}
                />
                <Route
                  path="mproducts/view/:itemId"
                  element={<ViewItem navigate={navigate} />}
                />
                {/* Items */}
                <Route
                  path="item"
                  element={<Item navigate={navigate} />}
                ></Route>
                <Route
                  path="item/create"
                  element={<AddItem navigate={navigate} />}
                />
                <Route
                  path="item/edit/:itemId"
                  element={<EditItem navigate={navigate} />}
                />
                <Route
                  path="item/view/:itemId"
                  element={<ViewItem navigate={navigate} />}
                />
                {/* Delivery Challan */}
                <Route
                  path="delivery-challans"
                  element={<DeliveryChallan navigate={navigate} />}
                />
                <Route
                  path="dc/create"
                  element={<AddDc navigate={navigate} />}
                />
                <Route
                  path="dc/view/:DcId"
                  element={<ViewDc navigate={navigate} />}
                />
                <Route
                  path="dc/edit/:DcId"
                  element={<EditDc navigate={navigate} />}
                />
                {/* Channel */}
                <Route
                  path="channels"
                  element={<Channel navigate={navigate} />}
                ></Route>
                <Route
                  path="channel/create"
                  element={<AddChannel navigate={navigate} />}
                />
                <Route
                  path="channel/edit/:channelId"
                  element={<EditChannel navigate={navigate} />}
                />
                <Route
                  path="channel/view/:channelId"
                  element={<ViewChannel navigate={navigate} />}
                />
                {/* Petty Cash */}
                <Route
                  path="pettycash"
                  element={<PT navigate={navigate} />}
                ></Route>
                {/* PR / RFQ */}
                <Route
                  path="rfqs"
                  element={<Rfqs navigate={navigate} />}
                ></Route>
                <Route
                  path="rfq/view/:rId"
                  element={<ViewRFQ navigate={navigate} />}
                ></Route>
                <Route
                  path="rfq/create"
                  element={<AddRFQ navigate={navigate} />}
                ></Route>
                <Route
                  path="rfq/edit/:id"
                  element={<EditRFQ navigate={navigate} />}
                ></Route>

                {/* Reports */}
                <Route
                  path="reports/invoices/"
                  element={<ReportsInvoices navigate={navigate} />}
                ></Route>
                <Route
                  path="reports/pdf/invoices/"
                  element={<ReportsPrint navigate={navigate} />}
                ></Route>



                {/* Users */}
                <Route
                  path="users"
                  element={<Users navigate={navigate} />}
                ></Route>
                <Route
                  path="user/create"
                  element={<AddUser navigate={navigate} />}
                />
                <Route
                  path="user/edit/:UserId"
                  element={<EditUser navigate={navigate} />}
                />
                {/* <Route
                  path="user/view/:UserId"
                  element={<ViewInvoice navigate={navigate} />}
                /> */}
                {/* Vendors */}
                <Route
                  path="vendors"
                  element={<Vendor navigate={navigate} />}
                ></Route>
                <Route
                  path="vendor/create"
                  element={<AddVendor navigate={navigate} />}
                />
                <Route
                  path="vendor/edit/:VendorId"
                  element={<EditVendor navigate={navigate} />}
                />
                <Route
                  path="vendor/view/:VendorId"
                  element={<ViewVendor navigate={navigate} />}
                />
                {/* Taxes GST */}
                <Route
                  path="taxes"
                  element={<Taxes navigate={navigate} />}
                ></Route>
                <Route
                  path="tax/create"
                  element={<AddTax navigate={navigate} />}
                ></Route>
                <Route
                  path="tax/edit/:TaxId"
                  element={<EditTax navigate={navigate} />}
                ></Route>
                {/* Rejections */}
                <Route
                  path="rejections"
                  element={<Rejections navigate={navigate} />}
                ></Route>
                {/* Settings */}
                <Route
                  path="settings"
                  element={<Settings navigate={navigate} />}
                ></Route>
                {/* Persons */}
                <Route
                  path="persons"
                  element={<Persons navigate={navigate} />}
                ></Route>
                <Route
                  path="person/create"
                  element={<AddPerson navigate={navigate} />}
                ></Route>
                <Route
                  path="person/edit/:pId"
                  element={<EditPerson navigate={navigate} />}
                ></Route>
                <Route
                  path="person/view/:pId"
                  element={<ViewPerson navigate={navigate} />}
                ></Route>
                {/* Brands */}
                <Route
                  path="brands"
                  element={<Brands navigate={navigate} />}
                ></Route>
                <Route
                  path="brand/create"
                  element={<AddBrand navigate={navigate} />}
                ></Route>
                <Route
                  path="brand/edit/:pId"
                  element={<EditBrand navigate={navigate} />}
                ></Route>
                {/* 
                
                <Route    
                  path="person/view/:pId"
                  element={<ViewPerson navigate={navigate} />}
                ></Route> */}
                {/* Payments */}
                <Route
                  path="payments"
                  element={<Payments navigate={navigate} />}
                ></Route>
                <Route
                  path="payment/create"
                  element={<AddPayment navigate={navigate} />}
                ></Route>
                <Route
                  path="payment/view/:PayId"
                  element={<ViewPayment navigate={navigate} />}
                ></Route>
                {/* Tasks */}
                <Route
                  path="tasks"
                  element={<Tasks navigate={navigate} />}
                ></Route>
                {/* Prints */}
                <Route
                  path="prints"
                  element={<Prints navigate={navigate} />}
                ></Route>
                {/* Logs */}
                <Route
                  path="logs"
                  element={<Logs navigate={navigate} />}
                ></Route>
                {/* Media */}
                <Route
                  path="media"
                  element={<MediaUpload navigate={navigate} />}
                ></Route>
                {/* Yelp */}
                <Route
                  path="yelp"
                  element={<YelpSearch navigate={navigate} />}
                ></Route>
              </Route>
              <Route path="/pdf/preview/" element={<PreviewPdf />} />
              <Route
                path="/view/pdf/:Id"
                element={<ViewPdf type="quotation" />}
              />
              <Route path="/view/pdf/dc/:Id" element={<ViewPdf type="dc" />} />
              <Route
                path="/view/pdf/inv/:Id"
                element={<ViewPdf type="invoice" />}
              />
              <Route
                path="/view/pdf/inv/:taxtype/:Id"
                element={<ViewPdf type="invoice" />}
              />
              <Route
                path="/view/wst/pdf/:Id"
                element={<ViewPdf type="quotation" notax="true" />}
              />
              
              <Route path="/rfq/inquriy/pdf/:id"
                  element={<InquriyPrint navigate={navigate} />}
              ></Route>
              <Route path="/rfq/inquriy/pdf/bulk"
                  element={<InquriyPrintBulk navigate={navigate} />}
                ></Route>

              <Route
                path="print/envelope/:Color"
                element={<EnvelopePDF navigate={navigate} />}
              ></Route>
              <Route
                path="print/envelope/:Color/:type/:Id"
                element={<EnvelopePDF navigate={navigate} />}
              ></Route>
              <Route
                path="/signout"
                element={<SignOut navigate={navigate} />}
              />
              <Route path="/login" element={<SignIn navigate={navigate} />} />
            </Routes>
          </div>

          {/* <Login /> */}
        </div>
      </AuthProvider>
    </div>
  );
}

export default App;
