import React, { useState } from "react";
import "./../admin/create.css";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };
const NotificationMessage = ({ message="null", open, handleClose }) => {
  
  return(<>
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
  </>)
};

export default NotificationMessage;
