import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";

import {
  ArrowBack,
  Send,
} from "@mui/icons-material";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel} from "@mui/material";
import magento from "../../api/magento";
import {profile} from '../../Helper/Helper';
import SelectDataComp from "../../components/Company/SelectDataComp";
import { useParams } from "react-router-dom";

export default function EditPerson({ navigate }) {

  const {pId} = useParams();

  const [DataList, setDataList] = useState({
    CompanyList:[],
    VendorList:[]
  });

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    department: "",
    contact_type: "Company",
    channel_id: profile[0].id,
    conn_id: 0,
    phone:'',
    mobile:'',
    email:'',
    remarks:'',

  });

  const DataCall = async () =>{
    await magento.get('person/'+ pId).then((response)=>{
      let d = response.data[0];
      console.log('let ',d);
      setFormData({...formData, name: d.name,
        role: d.role,
        department: d.department,
        contact_type: d.contact_type,
        channel_id: d.channel_id,
        conn_id: d.conn_id,
        phone:d.phone,
        mobile:d.mobile,
        email:d.email,
        remarks:d.remarks,
      })
      
      return formData;
    })
  }
  
  const CompanyCall = async () =>{
    await magento.get('channel/call/'+ profile[0].id).then((response)=>{
      console.log(response.data);
      setDataList(response.data)
      return DataList;
    })
  }

  const handleSelectChange = (e) => {
    setFormData({ ...formData, contact_type: `${e.target.value}`, conn_id: 0 });
    return formData;
  };
  const OnSubmit = async (data) => {
    console.log(data);
    await magento
      .post("person/edit/"+pId, data)
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/persons/view/${response.data.insertId}`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const CheckType = (data,dList)=>{
        if(data.contact_type === "Company" || data.contact_type === 'Vendor') {
          return <SelectDataComp  data={data} dataList={data.contact_type === 'Company'? dList.CompanyList : dList.VendorList} type={data.contact_type}  />
        } else{
         return null
        }
  }
  React.useEffect(()=>{
    DataCall();
    CompanyCall();
  },[])

  return (
    
    <>
    {console.log(formData)}
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Edit Contact Person
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
          <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "35%" }}
            >
            <InputLabel id="taxtype">Contact Type</InputLabel>
           <Select
            labelId="cnttye"
            id="cnttye"
            onChange={handleSelectChange}
            placeholder="Contact Type"
            label="Contact Type"
            native
            variant="standard"
          >
            <option key="Company" value="Company" selected>Company</option>
            <option key="Vendor" value="Vendor">Vendor</option>
            <option key="Professional" value="Professional">Professional</option>
            <option key="Personal" value="Personal">Personal</option>
            <option key="Other" value="Other">Other</option>
          </Select>
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "61%" }}
            >
              {CheckType(formData,DataList)}
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "0", width: "38%" }}
            >
              <TextField
                label="Contact Name"
                variant="standard"
                value={formData.name}
                helperText="Contact Person Name"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                label="Role"
                helperText="Enter Role or Designation"
                variant="standard"
                value={formData.role}
                onChange={(e) =>
                  setFormData({ ...formData, role: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "28%" }}
            >
              <TextField
                label="Department"
                variant="standard"
                value={formData.department}
                helperText="Enter Department Name"
                onChange={(e) =>
                  setFormData({ ...formData, department: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                label="Email"
                variant="standard"
                value={formData.email}
                helperText="Enter Email Address"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                label="Phone"
                variant="standard"
                value={formData.phone}
                helperText="Enter Phone"
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "31%" }}
            >
              <TextField
                label="Mobile"
                variant="standard"
                value={formData.mobile}
                helperText="Enter Mobile Name"
                onChange={(e) =>
                  setFormData({ ...formData, mobile: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mx: "2%", width: "98%" }}
            >
              <TextField
                label="Remarks"
                variant="standard"
                value={formData.remarks}
                helperText="Enter Remarks"
                multiline
                rows={2}
                onChange={(e) =>
                  setFormData({ ...formData, remarks: e.target.value })
                }
                type="text"
              />
            </FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save Contact Person
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
