import React from "react";
import { Paper } from "@mui/material";
import { Button } from "semantic-ui-react";

import { postCall } from "../Helper/Helper";

export default function PDFUpload({pdfData}) {
  const [file, setFile] = React.useState("");
  const handleSelectChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);

    return file;
  };

  const UploadImage = async (data) => {
    const UploadFileData = new FormData();
    UploadFileData.append("pdfFile", data, data.name);
    console.log(UploadFileData);
    await postCall("/pdf/upload", UploadFileData, {
      headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    }} ).then((response)=>{
      // console.log("sub", response.data.text);
       pdfData(response.data.text)
    }).catch(function(err){
      console.log(err);
    })
  };

  return (
    <>
      <Paper sx={{ mx: "20%", py: 2, my: 5 }}>
        <input
          name="pdf"
          type="file"
          accept="application/pdf,.csv"
          onChange={(e) => handleSelectChange(e)}
        />
        <br />
        <br />
        <Button fluid secondary onClick={() => UploadImage(file)}>
          Upload PDF
        </Button>
      </Paper>
    </>
  );
}
