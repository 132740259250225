import React, { useState, useEffect } from "react";
import "./../create.css";
import {
  Divider,
  Snackbar,
  InputAdornment,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Input,
  FormControl,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import DataLayout from "../../components/DataLayout";
import PreviewPopUp from "../../components/PreviewPopUp";
import { Delete, Edit } from "@mui/icons-material";
import ModelPopup from "../../components/ModelPopup";
import ItemAddComponenet from "../../components/ItemAddComponenet";
import SearchResultPopup from "../../components/SearchResultPopup";
import { LocalUpdate, postCall, getCall, profile, today, ActiveUser } from "../../Helper/Helper";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function AddDc({ navigate }) {
  const [disablebtn, setDisablebtn] = useState(true);
  const [message, setmessage] = useState("");
  const EmptyItem = {
    item_title: null,
    item_code: "",
    status: 1,
    item_qty: 1,
    qty: 1,
    currency: "Rs.",
    unit_of_measure: "kg",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    measure_of_unit: "kg",
    location: "",
    deliveryStatus: "1 Week",
    channel_id: profile[0].id,
    weight: 0,
    id: 0,
  };
  const [formData, setFormData] = useState({
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    Channel: {},
    SearchResult: ["No Item Found"],
    SelectedItems: [EmptyItem],
    create_at: today,
    item_count_total: 0,
    username: ActiveUser[0].username,
    user_id: ActiveUser[0].id,
    items_left: 0,
    ref_number: "",
    quote_id: 0,
    quote_number: "",
    po_number: "",
    terms: "- Prices in this quotation are valid for 7 days.",
    remarks: "",
    DcDate: today,
    dc_date: today,
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  var ValidationMessage = (msg, status)=>{
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  }

  // ** Calculate Item Row Total */
  const PriceCalculate = (qty = 1, item, type = null, val = null) => {
    item.item_cost = item.cost;
    var saleTaxTotal = parseInt((17 / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((17 / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;
    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }
    if (type === "measure") {
      item.measure_of_unit = val;
    }

    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    item.CostTotal = CostTotal;
    item.rowTotal = rowTotal;
    item.saleTaxTotal = saleTaxTotal;
    item.subTotalWithoutTax = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  // ** Calculate Total */

  const CalulateTotal = (
    data = formData.SelectedItems,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;

    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;
    console.log(Additionalcharges, "Checking addtional charges");
    if (data.length > 0) {
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );
      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );
      total_items_qty = parseInt(
        data.reduce(
          (prevValue, currentValue) => prevValue + currentValue.item_qty,
          0
        )
      );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
      });
    } else {
      let gdtotal =
        parseInt(formData.SubTotal) +
        parseInt(Additionalcharges) -
        parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(formData.GSTTotal) +
          parseInt(formData.SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
      });
    }
    return formData;
  };

  const DeleteItem = (data) => {
    setFormData({
      ...formData,
      SelectedItems: formData.SelectedItems.filter((item) => {
        return item.item_code !== data.item_code;
      }),
    });

    return formData;
  };

  const AddSelected = (data = EmptyItem) => {
    PriceCalculate(1, data);
    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    console.log(formData);

    return formData;
  };

  const UpdateItem = (Uitem, extItem) => {
    extItem.measure_of_unit = Uitem.unit_of_measure;
    extItem.id = Uitem.id;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.price = Uitem.price;
    extItem.item_price = Uitem.price;
    extItem.price = Uitem.price;
    extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    PriceCalculate(extItem.item_qty, extItem);

    return extItem;
  };

  const TableRowNew = (item, index) => {
    console.log(index, item, "listing");
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {/* <ItemSearchComponent addItems={(d) => {AddSelected(d); console.log(d)} } /> */}
            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              fullwidth
              required
              size="small"
              width="100%"
              onChange={(e) => {
                return PriceCalculate(
                  item.item_qty,
                  item,
                  "code",
                  e.target.value
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      console.log(newItem);
                      UpdateItem(newItem, item);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              value={item.item_title}
              fullWidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(item.item_qty, item, "title", e.target.value);
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              p: "0px",
              borderRight: "1px solid #d5d5d5",
              textAlign: "center",
            }}
          >
            <TextField
              id={"item-measure-" + index}
              sx={{
                p: "0",
                textAlign: "center",
                border: "none",
                "& input": {
                  px: "4px",
                  fontSize: "0.875rem",
                  textAlign: "center",
                },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="text"
              size="small"
              value={item.measure_of_unit}
              onChange={(e) => {
                PriceCalculate(item.item_qty, item, "measure", e.target.value);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            {item.id > 0 ? (
              <ModelPopup
                mopen={true}
                title="Edit Item"
                content={<Edit fontSize="small" />}
                childComp={
                  <ItemAddComponenet
                    type="edit"
                    edata={item}
                    iid={item.id}
                    addItems={(newItem) => {
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message:
                            "Item information has been updated and row has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                }
              />
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  const InsertDc = async (data) => {
    await postCall("dc/create", data).then((response)=>{
      navigate("/admin/dc/view/" + response.data.insertId, {
        state: {
            message: "Dc has been sucessfully created",
          },
      })
    }).catch(function(err){
      ValidationMessage(err.message,"error")
    }); 
  };

  const OnSubmitData = (data, profile) => {
    setDisablebtn(true);
    CalulateTotal();
    if (data.billTo.length === 0) {
      return ValidationMessage("Please select company","error" );
    } else if (data.SelectedItems.length === 0) {
      return ValidationMessage("Please add items to proceed further","error" );
    } else {
      CalulateTotal(data);
      InsertDc({ data, profile });
      return true;
    }
  };
  const headdetails = () => {
    return (
      <>
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Delivery Challan #{profile[0].dc_series}
        </Typography>
      </>
    );
  };

  const infoCall = async () =>{
    Promise.all([
      setFormData({ ...formData, CompanyList: await getCall("company/channel/" + profile[0].id)})
    ])

  }

  useEffect(() => {
    LocalUpdate();
    infoCall();
  }, []);
  return (
    <>
      <DataLayout data={formData} navigate={navigate} headdetails={headdetails}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <Grid sx={{ px: 2 }} container spacing={2}>
          <Grid textAlign={"left"} md={10} sx={{ p: 2 }}>
            <CompanySelection data={formData} />
          </Grid>
          <Grid textAlign={"left"} md={2} sx={{ p: 2 }}></Grid>
        </Grid>
        <Divider />
        <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                value={formData.po_number}
                id="po_number"
                label="PO #"
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                marginBottom="0"
                onChange={(e) => {
                  setFormData({ ...formData, po_number: e.target.value });
                  return formData;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="ref_number"
                label="Reference #"
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                marginBottom="0"
                onChange={(e) => {
                  setFormData({ ...formData, ref_number: e.target.value });
                  return formData;
                }}
              />
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                id="quote_number"
                label="Quotation #"
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setFormData({ ...formData, quote_number: e.target.value });
                  return formData;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="create_at"
                label="DC Date"
                type="date"
                defaultValue={formData.DcDate}
                sx={{ background: "#fff" }}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setFormData({ ...formData, create_at: e.target.value });
                  return formData;
                }}
              />
            </Grid>
            <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                rows={5}
                sx={{ background: "#fff" }}
                multiline
                placeholder="Please enter remarks"
                variant="outlined"
                value={formData.remarks}
                onChange={(e) => {
                  setFormData({ ...formData, remarks: e.target.value });
                  return formData;
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Grid container spacing={1}>
          <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
            <Typography variant="h6" color={"#777"} fontSize={20}>
              Details
            </Typography>
            {/* <SearchBar/> */}
          </Grid>
          <Grid textAlign={"right"} md={12} sx={{ px: 4 }}>
            {formData.SelectedItems && (
              <>
                {/* <FormControl fullWidth="true" style={{fontSize:"8px"}} >               */}
                <Table
                  sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                  size="small"
                  aria-label="a dense table"
                  padding="0"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"50px"}
                      >
                        Sno.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"200px"}
                        align="left"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"500px"}
                      >
                        Product Description
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Qty.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Measure of Unit
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px" }}
                        width={50}
                        colSpan={2}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.SelectedItems.map((item, index) => {
                      console.log(item, index, "consle");
                      return TableRowNew(item, index);
                    })}
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Button onClick={() => AddSelected()}>
                          Add New Row
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* </FormControl> */}
              </>
            )}
          </Grid>
          <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}></Grid>
          <Grid textAlign={"left"} md={5} sx={{ pl: 0, pr: 4, py: 2 }}></Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
            <PreviewPopUp data={[formData, profile[0]]} type="dc" />
          </Grid>
          <Grid textAlign={"right"} md={5} sx={{ px: 4, py: 2 }}>
            <Button
              variant="contained"
              color="inherit"
              type="submit"
              onClick={() => {
                setDisablebtn(false);
                console.log(formData);
                //  localStorage.setItem(
                //   "previewdata",
                //   JSON.stringify(formData, profile)
                // );
              }}
              sx={{ mx: 3 }}
            >
              Calculate
            </Button>
            <Button
              onClick={() => OnSubmitData(formData, profile[0])}
              type="submit"
              variant="contained"
              color="success"
              size="large"
              disabled={disablebtn}
            >
              Save DC
            </Button>
          </Grid>
        </Grid>
      </DataLayout>
    </>
  );
}
