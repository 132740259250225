import React, { useEffect, useState } from "react";
import "./persons.css";
import { Link } from "react-router-dom";
import { Add, MenuOutlined } from "@mui/icons-material";
import magento from "../../api/magento";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";
import { profile } from './../../Helper/Helper';
import {
  AppBar,
  Box,
  Fab,
  IconButton, 
  Toolbar,
  Typography,
} from "@mui/material";
export default function Persons({ navigate }) {
  const [Data, setData] = useState([]);
  
  const CallData = async () => {
    await magento
      .get("persons/channel/" + profile[0].id)
      .then(function (response) {
        setData(response.data);
        return Data;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallData();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          className="mb15"
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Contact Person List
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <DataGrid
          className="m20 mt0 p20 basecolor"
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 15 },
            { field: "name", hideable: true, headerName: "Contact", width: 150 },
            { field: "contact_type", hideable: true, headerName: "Type" },
            { field: "conn_id", hideable: true, headerName: "CompanyName", width: 150, renderCell:(params)=>{return <Link target="_blank" to={`/admin/${params.row.contact_type.toLowerCase()}/view/${params.row.conn_id}`}>
            {(params.row.contact_type === 'Company')? params.row.cname : ''}
            {(params.row.contact_type === 'Vendor')? params.row.vname : ''}
          </Link>}},
            { field: "role", hideable: true, headerName: "Role", width: 100 },
            { field: "department", hideable: true, headerName: "Dep." },
            { field: "email", hideable: true, headerName: "Email", width: 200 },
            { field: "phone", hideable: true, headerName: "Phone" },
            // { field: "mobile", hideable: true, headerName: "Mobile" },
              { 
                field: "actions",
                type: "actions",
                width: 200,
                getActions: (GridRowParams) => [
                  <Link target="_blank" to={`/admin/person/view/${GridRowParams.row.id}`}>
                    View
                  </Link>,
                  <Link target="_blank" to={`/admin/person/edit/${GridRowParams.row.id}`}>
                  Edit
                </Link>,
                
                ],
              },
          ]}
          rows={Data}
          pageSize="50"
        />
        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            
            onClick={() => navigate("admin/person/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
