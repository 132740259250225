import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./Context/AuthContext";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  DashboardOutlined,
  Groups,
  Inventory,
  LoginOutlined,
  Man,
  PeopleAlt,
  Person,
  TaskAlt,
} from "@mui/icons-material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

const SideNav = ({ navigate }) => {
  const ActiveChannel = JSON.parse(localStorage.getItem("channelDetail"));
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  console.log(ActiveChannel);
  const { useAuth, IsActive, IsLoggedIn } = useContext(AuthContext);
  const [active, setActive] = useState({ activeItem: true });

  const handleItemClick = (e, { name }) => setActive({ activeItem: name });
  return (
    <>
      <Box>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 10,
            px: [1],
          }}
        >
          <Avatar
            src={`http://accounting.automarts.pk/assets/products/${ActiveChannel[0].logo}`}
          />

          <Typography fontSize={18}>{ActiveChannel[0].channel}</Typography>
          <IconButton>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Typography
          fontSize={12}
          textAlign="left"
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            <Person fontSize="14" /> Welcome, {ActiveUser[0].username}{" "}
          </span>
          <Link to="/signout" size="tiny">
            <LoginOutlined fontSize="12" /> Signout
          </Link>
        </Typography>
        <Divider />
        <List component="nav">
          <Link to="/admin/dashboard" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <DashboardOutlined />
              </ListItemIcon>
              Customer & Sales
            </ListItemButton>
          </Link>
          <Link to="/admin/magento" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <DashboardOutlined />
              </ListItemIcon>
              Magento
            </ListItemButton>
          </Link>
          <Link to={"/admin/vendorpurchase"} color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              Vendor & Purchase
            </ListItemButton>
          </Link>
          <Link to="/admin/inventoryservice" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <Inventory />
              </ListItemIcon>
              Inventory & Ser.
            </ListItemButton>
          </Link>
          <Link to="/admin/employeespayroll" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <Man />
              </ListItemIcon>
              Employees & Payroll
            </ListItemButton>
          </Link>
          <Link to="/admin/banking" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <Groups />
              </ListItemIcon>
              Banking
            </ListItemButton>
          </Link>
          <Link to="/admin/profilecompany" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <Man />
              </ListItemIcon>
              Company
            </ListItemButton>
          </Link>
          <Divider sx={{ my: 0 }} />

          <Link to="/admin/tasks" color="#000000">
            <ListItemButton sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <TaskAlt />
              </ListItemIcon>
              Task Manager
            </ListItemButton>
          </Link>
        </List>
        <Divider sx={{ my: 0 }} />
      </Box>
    </>
  );
};

export default SideNav;
