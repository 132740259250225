import React, { useState, useEffect } from "react";
import "./../create.css";
import { Icon, Segment } from "semantic-ui-react";

import { AppBar, Divider, Paper, Toolbar, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import magento from "../../api/magento";
import CreatePDF from "../backups/CreateDcPDF";
import QuoteIntoDc from "../../components/QuoteIntoDc";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NumberIntoWord from "../../components/NumberIntoWord";

import MuiAlert from "@mui/material/Alert";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";

import ItemListing from "../../components/ItemListing";
import DataLayout from "../../components/DataLayout";
import CreateDcPDF from "../backups/CreateDcPDF";
import RejectionInDc from "../../components/RejectionInDc";
import CreateDDcPDF from "../../components/pdf/Default/DcPDF";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function ViewDc({ navigate }) {
  const location = useLocation();
  const { DcId } = useParams();
  const [data, setData] = useState("");
  const [open, setOpen] = React.useState(false);
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const [pdfTemplate, setPdfTemplate] = useState(1);
  const [message, setmessage] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const DcData = async () => {
    await magento
      .get(`https://webapi.automarts.pk/dc/${DcId}`, {
        // .get(`http://localhost:3001/dc/${DcId}`, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const PDFCheck  = (data)=>{
    switch (data.Data[0].channel_id) {
      case 2:
        console.log(pdfTemplate,'true');
        return <CreateDcPDF data={data} /> 
      
      default:
        console.log(pdfTemplate, 'default ');
        return <CreateDDcPDF data={data} />     
    }
  
   }
  

  const CreateSelectedItemListing = () => {
    return <ItemListing data={data} layout="dc" />;
  };
  const headdetails = () => {
    return (
      <>
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Delivery Challan #{data.Data[0].dc_number}
        </Typography>
        <RejectionInDc data={data} navigate={navigate} />

        <Link
          target="_blank"
          to={`/view/pdf/dc/${DcId}`}
          className="ui secondary button tiny"
        >
          <Icon name="print" /> Print
        </Link>
        <PDFDownloadLink
          className="ui secondary button tiny"
          fileName={
            data.channel &&
            data.channel[0].channel +
              "-DC#" +
              data.Data[0].dc_number +
              (data.Data[0].po_number !== null
                ? "-PO#" + data.Data[0].po_number
                : data.Data[0].ref_number !== null
                ? "-PO#" + data.Data[0].ref_number
                : "") +
              ".pdf"
          }
          document={PDFCheck(data)}
        >
          <Icon name="file pdf" /> Download
        </PDFDownloadLink>
      </>
    );
  };

  useEffect(() => {
    DcData();
    if (location.state) {
      handleClickOpen();
      setmessage({ message: location.state.message, status: "success" });
    } else {
      console.log("No Message");
    }
  }, []);
  return (
    <>
      {data.Data && (
        <>
          <DataLayout data={data} navigate={navigate} headdetails={headdetails}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <MuiAlert
                elevation={6}
                variant="filled"
                sx={{ width: "100%" }}
                onClose={handleClose}
                severity={message.status}
              >
                {message.message}
              </MuiAlert>
            </Snackbar>
            <Grid sx={{ px: 2 }} container spacing={2}>
              <Grid textAlign={"left"} md={5} sx={{ p: 2 }}>
                <Typography
                  variant="h6"
                  textAlign="left"
                  color={"#777"}
                  fontSize={20}
                >
                  To: {data.Data[0].dc_to_company}{" "}<Link
                    to={{
                      pathname: `/admin/dc/edit/${data.Data[0].id}`,
                    }}
                    state={{ data }}
                  >
                    <small>- Edit</small>
                  </Link>
                </Typography>
                <Typography variant="p" marginBottom={0}>
                  {data.Data[0].dc_to}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  STN#: {data.Data[0].dc_to_stn}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  NTN#: {data.Data[0].dc_to_ntn}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  Phone: {data.Data[0].dc_to_phone}
                </Typography>
              </Grid>
              <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
                <Typography
                  color={"#777"}
                  fontSize={20}
                  bottom
                  variant="h6"
                  textAlign="left"
                >
                  By: {data.channel[0].channel}
                </Typography>
                <Typography variant="p" marginBottom={0}>
                  {data.channel[0].address}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  STN#: {data.channel[0].stn}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  NTN#: {data.channel[0].ntn}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  Phone: {data.channel[0].phone}
                </Typography>
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
                <Typography
                  color={"#777"}
                  fontSize={20}
                  bottom
                  variant="h6"
                  textAlign="left"
                >
                  Generated By:
                </Typography>
                <Typography variant="p" marginBottom={0}>
                  Name: {data.Data[0].name}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  Role: {data.Data[0].role}
                </Typography>
                <br />
                <Typography variant="p" marginBottom={0}>
                  Phone: {data.Data[0].phone}
                </Typography>
                <br />
              </Grid>
            </Grid>
            <Divider />
            <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100" width={"100%"}>
              <Grid container spacing={1}>
                <Grid textAlign={"left"} md={3} sx={{ px: 2, pt: 4 }}>
                <TextField
                    fullWidth
                    id="po_number"
                    label="PO #"
                    type="text"
                    sx={{ background: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    marginBottom="0"
                    value={data.Data[0].po_number}
                  />
                  <br />
                  <br />
                  <TextField
                    fullWidth
                    id="ref_number"
                    label="Reference #"
                    type="text"
                    sx={{ background: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    marginBottom="0"
                    value={data.Data[0].ref_number}
                  />
                </Grid>
                <Grid textAlign={"left"} md={3} sx={{ px: 2, pt: 4 }}>
                  
                  <TextField
                    disabled
                    fullWidth
                    id="quote_number"
                    label="Quotation#"
                    type="text"
                    sx={{ background: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={data.Data[0].quote_number}
                    helperText={
                      data.Data[0].quote_id > 0 ? (
                        <Link
                          to={"/admin/quotation/view/" + data.Data[0].quote_id}
                        >
                          View Quotation
                        </Link>
                      ) : (
                        ""
                      )
                    }
                  />
                  <br />
                  <br />
                  <TextField
                    fullWidth
                    id="create_at"
                    label="DC Issue Date"
                    sx={{ background: "#fff" }}
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(data.Data[0].dc_date).format("DD/MM/YYYY")}
                    disabled
                  />

                </Grid>

                <Grid textAlign={"left"} md={6} sx={{ px: 2, pt: 4 }}>
                <TextField
                  fullWidth
                  id="remarks"
                  multiline
                  sx={{ background: "#fff" }}

                  label="Remarks"
                  placeholder="Please enter remarks"
                  multilineInputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  rows={4}
                  value={data.Data[0].remarks}
                  disabled
                />
                  
                </Grid>
                
              </Grid>
            </Box>
            <Divider />
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
                <Typography variant="h6" color={"#555"} fontSize={20}>
                  Details{" "}
                  <Link
                    to={{
                      pathname: `/admin/dc/edit/${data.Data[0].id}`,
                    }}
                    state={{ data }}
                  >
                    <small>- Edit</small>
                  </Link>
                </Typography>
              </Grid>

              <Grid textAlign={"right"} md={12} sx={{ px: 4 }}>
                {<>{data.Items && CreateSelectedItemListing()}</>}
              </Grid>
              <Grid md={12} sx={{ px: 4, my: 1 }}>
                {" "}
              </Grid>
              <Grid textAlign={"left"} md={7} sx={{ px: 2, py: 2, pl: 4 }}>
                
              </Grid>
              <Grid
                textAlign={"left"}
                md={5}
                sx={{ px: 2, py: 2, pr: 4 }}
              ></Grid>
            </Grid>
            <Divider />
          </DataLayout>
        </>
      )}
    </>
  );
}
