import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./dashboard.css";
import { AuthContext } from "../Context/AuthContext";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TableBody,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  Article,
  AttachMoney,
  BusinessOutlined,
  DescriptionOutlined,
  DescriptionSharp,
  DoNotDisturb,
  MenuOutlined,
  MultipleStop,
  Person,
  Print,
  Receipt,
  Settings,
} from "@mui/icons-material";
import magento from "../api/magento";
import moment from "moment";
import CurrencyConvertor from "../components/CurrencyConvertor";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Prints = ({ navigate }) => {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const taxes = JSON.parse(localStorage.getItem("taxes"));
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(
    JSON.parse(sessionStorage.getItem("channelDetail")),
    "dashboard call"
  );
  const { useAuth } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);

  const handleClick = (event, menu = event.currentTarget.id) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setMenu([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const DataCall = async () => {
    await magento
      .get("https://webapi.automarts.pk/data/channel/" + profile[0].id, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        return data;
      })
      .catch((error) => console.log("Error: ", error.message));
  };

  const newMenu = (men) => {
    setMenu(men);
    return menu;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  React.useEffect(() => {
    return DataCall();
  }, []);
  return (
    <div>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="primary"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Popover
          id={id}
          open={open}
          sx={{ p: 1 }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menu.map((item) => (
            <Box width={"200px"} sx={{ px: 1, py: 1 }}>
              <Link to={item.url}>
                <Typography width>{item.title}</Typography>
              </Link>
            </Box>
          ))}
        </Popover>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Paper sx={{ p: 2, px: "7%" }}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Prints 
              </Typography>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "Brown Envelope", url: "/admin/print/envelope/brown/" },
                      { title: "White Envelope", url: "/admin/print/envelope/white/" },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  
                  <Typography variant="small" fontSize={12}>
                    Envelope 
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={() => navigate("/admin/settings")}
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Typography variant="small" fontSize={12}>
                    Brown Envelope
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="taxes"
                  onClick={() => navigate("/admin/taxes")}
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AccountBalanceWalletOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Letter Head
                  </Typography>
                </Paper>
              </Box>
              <Divider />
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="quotations"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "New Quotation",
                        url: "/admin/quotation/create",
                      },
                      {
                        title: "View & Edit Quotation",
                        url: "/admin/quotation",
                      },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionSharp fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Quotations
                  </Typography>
                </Paper>
              </Box>
           
             
              
            </Paper>
          </Grid>
          <Grid
            xs={2}
            sm={4}
            md={8}
            sx={{ p: 3, my: 0, py: 3, display: "flex", flexWrap: "wrap" }}
            spacing={{ xs: 1, md: 3 }}
          >
          
          </Grid>
          <Grid item xs={2} sm={4} md={6}></Grid>
          <Grid item xs={2} sm={4} md={12}></Grid>
        </Grid>
      </Box>
      {useAuth()}
    </div>
  );
};

export default Prints;
