import { Dialog, Button, DialogContent } from "@mui/material";
import _ from "lodash";
import React from "react";

import PreviewPdf from "../admin/Pdf/PreviewPDF";
function PreviewPopUp({ data, type = "quote" }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    return () => {
      console.log(data);
    };
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          handleClickOpen();
        }}
        variant="contained"
        color="secondary"
      >
        Preview PDF
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth sx={{ padding: 0 }}>
        <DialogContent sx={{ padding: 0 }}>
          <PreviewPdf sdata={data} type={type} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PreviewPopUp;
