import React, { useEffect, useState } from "react";
import '../admin/create.css'
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { FormattingNumber } from "../Helper/Helper";

export default function TotalComp({ data, records }) {

  useEffect(() => {
    
  }, []);

  return (
    <>
        <Grid
        container
         className="reportsTotal"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2,}}
      >
        <Grid item md={2} textAlign="left" sx={{mt:2}}>
            <Box>
              <Paper className="p1">
              <h5>Total Record: {FormattingNumber(data.RecordTotal)}</h5>
              </Paper>
              </Box>
        </Grid>
        <Grid item md={2} textAlign="left" sx={{mt:2}}>
            <Box>
              <Paper className="p1">
              <h5>Items Count: {FormattingNumber(data.CountTotal)}</h5>
              </Paper>
              </Box>
          </Grid>
          <Grid item md={2} textAlign="left" sx={{mt:2}}>
            <Box>
              <Paper className="p1">
              <h5>SubTotal:  {FormattingNumber(data.subTotal)}</h5>
              </Paper>
              </Box>
          </Grid>
          <Grid item md={2} textAlign="left" sx={{mt:2}}>
          <Box>
              <Paper className="p1">
              <h5>GST Total:  {FormattingNumber(data.gstTotal) }</h5>
              </Paper>
              </Box>
          </Grid>
          <Grid item md={2} textAlign="left" sx={{mt:2}}>
          <Box>
              <Paper className="p1">
                <h5>Total:  {FormattingNumber(data.GrandTotal)}</h5>
              </Paper>
              </Box>
              </Grid>
              
        </Grid>

    </>
  );
}
