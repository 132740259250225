import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  FormHelperText,
  IconButton,
  Paper,
  Toolbar,
  Input,
} from "@mui/material";

import {
  Add,
  ArrowBack,
  DescriptionOutlined,
  DescriptionSharp,
  MenuOutlined,
  Send,
} from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import magento from "../../api/magento";
import { useParams } from "react-router-dom";

export default function EditUser({ navigate }) {
  const { UserId } = useParams();
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    active: 1,
    name: "",
    role: "Manager",
    email: "",
    phone: "",
    channel_id: profile[0].id,
  });

  const handleSelectChange = (e, data, update = "city") => {
    switch (update) {
      case "business":
        setFormData({ ...formData, business: `${data.value}` });
        break;
      default:
        setFormData({ ...formData, city: `${data.value}` });
        break;
    }
  };
  const DataCall = async () => {
    await magento
      .get("https://webapi.automarts.pk/users/" + UserId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setFormData(response.data[0]);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const OnSubmit = async (data) => {
    await magento
      .post("https://webapi.automarts.pk/user/edit/" + UserId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/user/` + UserId);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    DataCall();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {profile && profile[0].channel} - Edit User : {}
            </Typography>
          </Toolbar>
        </AppBar>

        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.name}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "4%", width: "42%" }}
            >
              <TextField
                label="Username"
                helperText="Please enter username "
                fullWidth
                variant="standard"
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "42%" }}>
              <TextField
                label="Password"
                helperText="Please enter passwrod "
                fullWidth
                variant="standard"
                onChange={(e) =>
                  setFormData({ ...formData, newpassword: e.target.value })
                }
                type="password"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                id="name"
                name="name"
                label="Name"
                variant="standard"
                value={formData.name}
                helperText="Enter complete name"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "47%" }}
            >
              <TextField
                label="Role"
                helperText="Enter Role"
                variant="standard"
                defaultValue={formData.role}
                onChange={(e) =>
                  setFormData({ ...formData, role: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                label="Email Address"
                helperText="Enter Email address"
                variant="standard"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "47%" }}
            >
              <TextField
                label="Phone"
                helperText="Enter Phone"
                variant="standard"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "47%" }}
            ></FormControl>
          </Grid>
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
