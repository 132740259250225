import { Search } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  TextField,
  Button,
  FormControl,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import magento from "../api/magento";
import "./styles.css";
import moment from "moment";
import { Grid } from "semantic-ui-react";

function SearchItemVendorPopup({ iid, cid, idata }) {
  const [open, setOpen] = React.useState(false);
  const [Results, setResults] = React.useState([]);
  const [ItemVendor, setItemVendor] = React.useState({
    channel_id: cid,
    item_id: iid,
    price: null,
    notes: "",
    vendor_id: null,
  });

  const handleClickOpen = () => {
    SearchVendor(iid);
  };

  const options = () => {
    return Results[1].map((i) => {
      return { key: i.id, label: `${i.vendor_name}`, value: i.id };
    });
  };

  const SearchVendor = async (id, index) => {
    await magento
      .get("https://webapi.automarts.pk/item/search/vendor/" + id + "/" + cid, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setResults(response.data);
        console.log(Results);
        return Results;
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const RowCall = (item, i) => {
    // console.log(item, i);
    return (
      <>
        <TableRow
          onClick={() => {
            idata.cost = item.price;
            // idata.vendor_id = item.vendor_id;
            // idata.vendor_items_id = item.id;
            // i.data.vender_name = item.
            console.log(item);
            return idata;
          }}
          hover="true"
          selected={idata.vendor_items_id === item.id ? true : false}
        >
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {i + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {item.vendor_name}
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.price}
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {moment(item.price_update).format("YYYY-MM-DD")}
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.notes}
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.price}
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.price}
          </TableCell>
        </TableRow>
      </>
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectChange = (e, sdata) => {
    console.log(e)
    if (sdata !== null) {
      Results[1].map((ie) => {
        return ie.id === sdata.value
          ? setItemVendor({ ...ItemVendor, vendor_id: ie.id })
          : "";
      });
      // console.log(sdata, data.company_id, data);
    } else {
    }

    // return data;
  };

  const OnSubmitData = async (data) => {
    await magento
      .post("https://webapi.automarts.pk/item/vendor/create", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        return SearchVendor(iid);
      });
  };

  React.useEffect(() => {
    SearchVendor(iid);
  }, []);

  return (
    <>
      {Results[0] && Results[0] !== "vvvNo Item Found" ? (
        <>
          <Grid
            textAlign={"left"}
            md={16}
            sm={16}
            lg={16}
            sx={{ px: 2, py: 0, mt: 3 }}
          >
            <FormControl>
              {" "}
              <Autocomplete
                id="select-vendor"
                options={options(Results[1])}
                sx={{ width: 200, marginBottom: 2 }}
                size="small"
                getOptionSelected={(option, value) => option === value}
                onChange={(e, data) => handleSelectChange(e, data)}
                renderInput={(params) => (
                  <TextField {...params} label="Select Vendor" />
                )}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                id="price"
                size="small"
                label="Vendor Price"
                type="number"
                defaultValue={ItemVendor.price}
                sx={{ background: "#fff", marginBottom: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setItemVendor({ ...ItemVendor, price: e.target.value });
                  return ItemVendor;
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                id="notes"
                label="Notes"
                placeholder="Please enter Product Vendor Notes"
                variant="outlined"
                sx={{ background: "#fff" }}
                value={ItemVendor.notes}
                size="small"
                onChange={(e) => {
                  setItemVendor({ ...ItemVendor, notes: e.target.value });
                  return ItemVendor;
                }}
              />
            </FormControl>
            <FormControl>
              <Button
                onClick={() => OnSubmitData(ItemVendor)}
                type="submit"
                sx={{ marginBottom: 2 }}
                variant="contained"
                color="success"
                size="small"
                disabled={
                  ItemVendor.price !== null || ItemVendor.vendor_id !== null
                    ? false
                    : true
                }
              >
                Add Item Vendor
              </Button>
            </FormControl>
          </Grid>
        </>
      ) : (
        ""
      )}
      <h4>Item Vendor Search Result</h4>
      <Table
        sx={{ minWidth: "100%", border: "1px solid #d5d5d5", mt: "10px" }}
        size="small"
        aria-label="a dense table"
        padding="0"
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              align="center"
              width={"50px"}
            >
              Sno.
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              align="center"
              width={"200px"}
            >
              Vendor Name
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              width={"200px"}
              align="left"
            >
              Vendor Cost
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              width={"100px"}
            >
              Updated at
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              align="center"
              width={"300px"}
            >
              Notes
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Results[0] && Results[0] !== "No Item Found" ? (
            Results[0].map((item, index) => {
              return RowCall(item, index);
            })
          ) : (
            <TableCell
              colSpan={9}
              sx={{
                p: "2px",
                mt: "10px",
                borderRight: "1px solid #d5d5d5",
              }}
              align="center"
              width={"150px"}
            >
              <h5>No Vendor Found for this Item</h5>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default SearchItemVendorPopup;
