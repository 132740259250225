import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import moment from "moment/moment";
import NumberIntoWord from "./../../NumberIntoWord";
import { FormattingNumber } from './../../../Helper/Helper';
import signature from "./za-signature-stamp.png";
// Font.register({
//   family: "RobotoBold",
//   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900",
// });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9Regular' });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap', { fontFamily: 'Roboto-Bold' });


const CheckTaxType = (t) => {
  if (t === 'SST') {
    return 'SST'
  } else {
    return 'GST'
  }
}
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 180,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#777",
    paddingVertical: 10,
    width: "100%",
    height: "1.5in",
  },
  topSection: {
    padding: 15,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },
  MainHeading: {
    fontSize: 28,
    fontWeight: "700",
    textAlign: "left",
    marginLeft: 170,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    right: "3%",
    top: "12pt",
  },
  MainText: {
    fontSize: "9pt",
    marginVertical: 2,
  },
  SubHeading: {
    fontSize: "11pt",
    marginTop: 5,
    textAlign: "left",
    marginLeft: 170,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 2,
  },
  ShippingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
    marginHorizontal: 10,
  },
  BillingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  logo: {
    width: "40%",
    height: 150,
    minHeight: 100,
    marginLeft: "0",
    marginRight: "auto",
    marginTop: -20,
  },
  grid: {
    borderWidth: 1,
    borderColor: "#777",
    borderStyle: "solid",
    width: "100%",
    marginTop: 10,
    marginHorizontal: 15,
  },
  WordInText: {
    textAlign: "left",
    fontSize: 9,
    color: "#000000",
  },
  GridHeader: {
    backgroundColor: "#eee",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#777",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  GridProductsItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },
  productPart2: {
    flex: 4,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  InvoiceNumber: {
    fontSize: 12,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 9,
    textAlign: "left",
    alignSelf: "left",
  },
  gridTextItems: {
    fontSize: 7.5,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  gridText: {
    fontSize: 7.5,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  productSno: {
    flex: 0.6,
    padding: 3,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
  },
  productTitle: {
    flex: 10,
    padding: 3,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
  },
  productPart: {
    flex: 3.5,
    padding: 3,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    display:'flex',
    flexWrap: 'wrap',
    alignContent: "flex-start",
    wordWrap: 'break-word',
    flexDirection: 'row',
    
    
  },
  priceTotalRight: {
    fontSize: 11,
    textAlign: "right",
  },
  productQty: {
    flex: 1.4,
    fontSize:7.5,
    padding: 3,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
  },
  productPrice: {
    flex: 3,
    padding: 3,
    fontSize:7.5,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
  },
  producctTotal: {
    flex: 3,
    padding: 3,
    fontSize:7.5,
    height: "100%",
  },
  productGrid: {
    padding: 3,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#ccc",
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  GridFooter2Red: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    color: '#da1f2d',
  },
  productNote: {
    padding: 5,
    flex: 9,
  },

  stamp: {
    position:'absolute',
    width: "55%",
    right:'-20px',
    top:"-25px",
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  signature: {
    position:'absolute',
    width: "55%",
    right:'80px',
    bottom:"70px",    
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 9,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 9,
    textAlign: "center",
  },
  priceRightItems: {
    fontSize: 7.5,
    textAlign: "right",
  },
  priceCenterItems: {
    fontSize: 7.5,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 9,
    textAlign: "left",
  },
  grandTotalHeadingRed: {
    fontSize: 9,
    textAlign: "left",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
    textAlign: "center",
  },
});

// Create Document Component
const QuotationPDF = ({ data, type, notax }) => {
  data.Data && console.log(data.channel[0].logo);
  return (
    <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="ZA Autos"
      title={type}
      keywords="ZA Autos"
      subject={type}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pagewrapper} fixed>
          <View style={styles.topSection}>
            <View style={styles.HeadingWrapper}>
              {data.channel && (
                <Image
                  style={styles.logo}
                  source={{
                    uri:
                      "https://accounting.automarts.pk/assets/products/" +
                      data.channel[0].logo,
                    method: "GET",
                    headers: {},
                    body: "",
                  }}
                />
              )}
              {/* <Image style={styles.logo} src={logo} /> */}
            </View>
            <View style={styles.InfoWrapper}>
              <Text style={styles.MainHeading}>Quotation</Text>
              <Text style={styles.SubHeading}>
                Date: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {data.Data &&
                  moment(data.Data[0].create_at).format("DD/MM/YYYY")}
              </Text>
              <Text style={styles.SubHeading}>
                Quotation: &nbsp;
                {data.Data && data.Data[0].quote_number}
              </Text>
              <Text style={styles.SubHeading}>
                {data.Data && data.Data[0].ref_number !== ""
                  ? "Ref. No.: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data.Data[0].ref_number +
                    "  "
                  : " "}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.CustomerInfo} fixed>
          <View style={styles.InfWrapper}>
            <Text style={styles.MainText}>
              {data.Data && data.Data[0].quote_to_company}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && data.Data[0].quote_to}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "Phone: " + data.Data[0].quote_to_phone}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "STN: " + data.Data[0].quote_to_stn}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "NTN: " + data.Data[0].quote_to_ntn}
            </Text>
          </View>
          <View style={styles.BillingAddress}>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>
              Terms & Condition
            </Text>
            <Text style={{ fontSize: 9, marginBottom: 5 }}>
              {data.Data && data.Data[0].terms}
            </Text>
          </View>
        </View>
        <View style={styles.grid}>
          <View style={styles.GridHeader} fixed>
          <View style={styles.productSno}>
                <Text style={styles.gridHeading}>S#</Text>
              </View>
            <View style={styles.productPart}>
              <Text style={styles.gridHeading}>Item Code</Text>
            </View>

            <View style={styles.productTitle}>
              <Text style={styles.gridHeading}>Description</Text>
            </View>
            <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>Status</Text>
            </View>
            <View style={styles.productQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>Rate</Text>
            </View>
            {!notax && <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>{CheckTaxType(data.Data[0].tax_type)}</Text>
            </View> }
            

            <View style={styles.producctTotal}>
              <Text style={styles.priceCenter}>Total</Text>
            </View>
          </View>

          {data.Items &&
            data.Items.map((item, index) => {
              //   console.log(data.Data[0].id);
              return (
                
                <View style={styles.GridProductsItems}>
                  <View style={styles.productSno}>
                    <Text style={styles.gridText}>{index + 1}</Text>
                  </View>
                  <View wrap style={styles.productPart}>
                    <Text style={styles.gridTextItems}>{item.item_code.split('',60)} </Text>
                  </View>
                  <View style={styles.productTitle}>
                    <Text style={styles.gridText}>{item.item_title}</Text>
                  </View>
                  <View style={styles.productPrice}>
                    <Text style={styles.priceCenterItems} >
                      {item.delivery_status}
                    </Text>
                  </View>
                  <View style={styles.productQty}>
                    <Text style={styles.priceCenterItems}>{item.item_qty} {item.unit_of_measure}</Text>
                  </View>
                  <View style={styles.productPrice}>
                    <Text style={styles.priceRightItems}>{FormattingNumber(item.item_price)}.00</Text>
                  </View>
                  {!notax && <View style={styles.productPrice}>
                    <Text style={styles.priceRightItems}>{FormattingNumber(item.item_gst_total)}.00</Text>
                  </View>
                  }
                  
                  <View style={styles.producctTotal}>
                    <Text style={styles.priceRightItems}>
                      {FormattingNumber(item.item_row_total_gst)}.00
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingHorizontal: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: 10,
            bottom: 50,
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 15,
            }}
          >
            <View
              style={{
                borderColor: "#777",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 10,
                width: "55%",
                height: 100,
                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75 }}>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  Created By:
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 5 }}>
                  {data.Data && data.Data[0].name}
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  {data.Data && data.Data[0].role}
                </Text>
              </View>
              <Text style={styles.footerMainTextTop}>
                This is computer generated document, no signature and stamp
                required.
              </Text>
            </View>
            <View style={{ width: "45%", paddingHorizontal: '10px', textAlign:'right', display:'flex' }}>
            <View style={styles.GridFooter}>
            <View style={styles.productPart2}>
              <Text style={styles.grandTotalHeading}>Sub Total</Text>
            </View>
            <View style={styles.OrderGrandTotal}>
              <Text style={styles.priceTotalRight}>
                {data.Data && FormattingNumber(data.Data[0].subtotal.toFixed(0))}.00
              </Text>
            </View>
              </View>
              
              <View style={styles.GridFooter2Red}>
              <View style={styles.productPart2}>
              <Text style={styles.grandTotalHeading}>
                Discount</Text>
            </View>
            <View style={styles.OrderGrandTotal}>
              <Text style={styles.priceTotalRight}>
                -{data.Data && FormattingNumber(data.Data[0].discount)}.00
              </Text>
                </View>
              </View>
          {!notax && <View style={styles.GridFooter2}>
            <View style={styles.productPart2}>
              <Text style={styles.grandTotalHeading}>
                {CheckTaxType(data.Data[0].tax_type)}
                ({data.Data && data.Data[0].gstinpercentage}%)
              </Text>
            </View>
            <View style={styles.OrderGrandTotal}>
              <Text style={styles.priceTotalRight}>
                {data.Data && FormattingNumber(data.Data[0].gst_total.toFixed(2))}.00
              </Text>
            </View>
              </View>} 
          <View style={styles.GridFooter}>
            <View style={styles.productPart2}>
              <Text style={styles.grandTotalHeading}>{}Grand Total</Text>
            </View>
            <View style={styles.OrderGrandTotal}>
              <Text style={styles.priceTotalRight}>
                {data.Data && FormattingNumber(data.Data[0].grand_total_gst)}.00
              </Text>
            </View>
          </View>
          <View style={styles.GridFooter}>
            <View style={styles.productNote}>
              {data.Data && (
                <Text style={styles.WordInText}>
                  <NumberIntoWord
                    number={parseInt(data.Data[0].grand_total_gst)}
                  />
                </Text>
              )}
            </View>
          </View>
            </View>
          </View>
          <View
            style={{
              paddingTop: 5,
              paddingBottom: 15,
              paddingHorizontal: 10,
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginTop: 0,
              bottom: -50,
              borderTopColor: "#777",
              borderTopStyle: "solid",
              borderTopWidth: 1,
            }}
          >
            <View style={styles.footer}>
              <Text style={styles.footerMainText}>
                {data.channel && data.channel[0].channel}
                {", "}
                {data.channel && data.channel[0].address}
              </Text>
              <Text style={styles.footerMainText}>
                STN: {data.channel && data.channel[0].stn}, NTN:{" "}
                {data.channel && data.channel[0].ntn}, Phone:
                {data.channel && data.channel[0].phone}
              </Text>
              <Image style={styles.signature} src={signature} />
              
            </View>
            <Text
              style={styles.footerMainText}
              render={({ pageNumber, totalPages }) => {
                return `Page No. ${pageNumber} / ${totalPages}`;
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );  
};

export default QuotationPDF;
