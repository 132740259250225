import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  // Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { FormattingNumber, today } from "../../../Helper/Helper";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 100,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#777",
    paddingVertical: 10,
    width: "100%",
    height: "1in",
  },
  topSection: {
    paddingHorizontal: 15,
    paddingTop:10,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },
  MainHeading: {
    fontSize: 14,
    fontWeight: "700",
    textAlign: "left",
    marginLeft: 0,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    left: "3%",
    top: "12pt",
  },
  MainText: {
    fontSize: "9pt",
    marginVertical: 2,
  },
  SubHeading: {
    fontSize: "10pt",
    marginTop: "9px",
    textAlign: "left",
    marginLeft: 0,
  },
  SubHeading2: {
    fontSize: "8pt",
    marginTop: "9px",
    textAlign: "left",
    marginLeft: 0,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 2,
  },
  ShippingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
    marginHorizontal: 10,
  },
  BillingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  logo: {
    width: "40%",
    height: 150,
    minHeight: 100,
    marginLeft: "0",
    marginRight: "auto",
    marginTop: -20,
  },
  grid: {
    borderWidth: 1,
    borderColor: "#777",
    borderStyle: "solid",
    width: "92%",
    marginTop: 10,
    marginHorizontal: "4%",
  },
  WordInText: {
    textAlign: "left",
    fontSize: 9,
    color: "#000000",
  },
  GridHeader: {
    backgroundColor: "#ccc",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    paddingVertical:1,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#777",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    height:"13.41px",
    borderBottom: '1 solid #999',
  },
  GridProductsItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",

  },
  productSno: {
    flex: 0.5,
    paddingHorizontal: 5,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  productQty: {
    flex: 2.5,
    paddingHorizontal: 5,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  productRates: {
    flex: 2,
    paddingHorizontal: 5,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  productTitle :{
    flex: 9,
    paddingHorizontal: 5,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  productPart: {
    flex: 4,
    paddingHorizontal: 5,
    paddingTop: 2,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  InvoiceNumber: {
    fontSize: 10,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 8,
    textAlign: "left",
    alignSelf: "left",
  },
  gridTextItems: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  gridText: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  gridText2: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    
    alignContent: "space-between",
    
  },
  gridText3: {
    fontSize: 7,
    textAlign: "center",
    fontWeight: "Normal",
    marginLeft: '1%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    
    
    
  },
  GDate: {
    flex: 4,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GCompany: {
    flex: 8,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
    paddingBottom: 0,
  },
  GNumber: {
    flex: 8,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    display:'flex',
    // flexWrap: 'wrap',
    alignContent: "stretch",
    flexDirection: 'row',
    
    
  },
  priceTotalRight: {
    fontSize: 11,
    textAlign: "right",
  },
  GQty: {
    flex: 3,
    fontSize:7,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GAmt: {
    flex: 3,
    padding: 2,
    fontSize:7,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GAmtL: {
    flex: 3,
    padding: 2,
    fontSize:7,
    height: "100%",
    paddingBottom: 0,
  },
  productGrid: {
    padding: 3,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#ccc",
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  productNote: {
    padding: 5,
    flex: 9,
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 7,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 8,
    textAlign: "center",
  },
  priceRightItems: {
    fontSize: 8,
    textAlign: "right",
  },
  priceCenterItems: {
    fontSize: 8,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 9,
    textAlign: "left",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
    textAlign: "center",
  },
});

const InquiryPDF = (data) => {
  const d = data.data;
  console.log('New--',d.Data);
    return (<>
   <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="ZA Autos"
      
      keywords="ZA Autos"
      
      >
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.pagewrapper} fixed>
            <View style={styles.topSection}>
              <View style={styles.HeadingWrapper}>
                <Text style={styles.MainHeading}>Inquriy</Text>
                <Text style={styles.SubHeading2}>
                  Date: {today}
                </Text>
                <Text style={styles.SubHeading2}>
                  PR: {d.Data && d.Data.pr}
                </Text>
              </View>
              
              </View>

          </View>
          <View style={styles.grid}>
          <View style={styles.GridHeader} fixed>
            <View style={styles.productSno}>
              <Text style={styles.gridHeading}>Sno.</Text>
            </View>
            <View style={styles.productPart}>
              <Text style={styles.gridHeading}>Item Code</Text>
            </View>
            <View style={styles.productTitle}>
              <Text style={styles.gridHeading}>Description</Text>
            </View>

            <View style={styles.productQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.productRates}>
              <Text style={styles.priceCenter}>Rates</Text>
            </View>
            </View>
            {d.Items &&
            d.Items.map((item, index) => {
                console.log(item.id);
              return (
                <View style={styles.GridProductsItems}>
                  <View style={styles.productSno}>
                    <Text style={styles.priceCenter}>{index + 1}</Text>
                  </View>
                  <View style={styles.productPart}>
                    <Text style={styles.gridText}>{item.item_code}</Text>
                  </View>

                  <View style={styles.productTitle}>
                    <Text style={styles.gridText}>{item.item_title}</Text>
                    <Text style={styles.smallText}></Text>
                  </View>

                  <View style={styles.productQty}>
                    <Text style={styles.priceCenter}>{item.item_qty} / {item.unit_of_measure}</Text>
                    
                  </View>
                  <View style={styles.productRates}>
                    <Text style={styles.priceCenter}>
                      {item.measure_of_unit}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </Page>
    </Document>
    </>)
}

export default InquiryPDF;