import React, { useEffect } from "react";
import { AppBar, Paper, Toolbar } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ArrowBack } from "@mui/icons-material";
import SubNavBar from "./SubNavBar";

const DataLayout = ({
  children,
  data,
  navigate,
  headdetails,
  infoBar = null,
  StatusBar = null,
}) => {
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            {headdetails()}
            <Button
              variant="outlined"
              endIcon={<ArrowBack />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Toolbar>
        </AppBar>
        {infoBar !== null ? (
          <>
            <Box
              style={{
                display: "flex",
                width: "76%",
                justifyContent: "space-between",
              }}
              sx={{ mx: "6%" }}
            >
              <Paper sx={{ width: "45%", py: 2, my: 3, borderRadius: 3 }}>
                {infoBar()}
              </Paper>

              <Paper sx={{ width: "45%", py: 2, my: 3, borderRadius: 3 }}>
                {StatusBar()}
              </Paper>
            </Box>
          </>
        ) : (
          ""
        )}
        <SubNavBar />
        <Paper sx={{ mx: "2%", py: 2, borderRadius: 5 }}>{children}</Paper>
      </Box>
    </>
  );
};
export default DataLayout;
