import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation, useParams } from "react-router-dom";
import InquiryPDFBulk from "../../components/pdf/Inquriy/InquiryPDFBulk";
import { getCall, postCall } from "../../Helper/Helper";

export const InquriyPrintBulk = ({data}) => {
  const location = useLocation();
  const [formData, setformData] = useState("");
    const { id } = useParams();

    const apiCall = async (id) => {
      await postCall("rfq/bulk", location.state).then((response) => {
        setformData(response);
        // data.f = response;
        console.log('Call - ' ,response)
        return formData;
      });
      
    };
    console.log(location.state, "FirstCall");
  
  useEffect(() => {
      console.log(id)
       apiCall(id);
      
    }, []);

    

    return (
        <PDFViewer width="100%" height="1000px">
            <InquiryPDFBulk data={formData} />
        </PDFViewer>
      );
}

