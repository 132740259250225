import React from "react";
// import "./../create.css";
import { Header, Segment } from "semantic-ui-react";

import { Alert, Table, TableCell, TableHead, TableRow } from "@mui/material";
import moment from "moment";

import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { FormattingNumber } from "../Helper/Helper";

const ItemListing = ({ data, view = "view", layout = "quotation" }) => {
  if (view !== "view") {
    data.Items = data.SelectedItems;
  }

  return data.Items.length > 0 ? (
    <>
      <Table
        sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
        size="small"
        aria-label="a dense table"
        padding="0"
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              align="center"
              width={"50px"}
            >
              Sno.
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              width={"200px"}
              align="left"
            >
              Item Code
            </TableCell>
            <TableCell
              sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
              width={"500px"}
            >
              Product Description
            </TableCell>
            {layout === "dc" ? (
              <>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"100px"}
                >
                  Qty.
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"100px"}
                >
                  Measure of Unit
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"150px"}
                >
                  Rejected
                </TableCell>
              </>
            ) : (
              <>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"100px"}
                >
                  {layout === "invoice" ? "Qty" : "Qty/Left"}
                </TableCell>
                {layout === "quotation" ? (
                  <TableCell
                    sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                    align="center"
                    width={"150px"}
                  >
                    Status
                  </TableCell>
                ) : (
                  ""
                )}
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"150px"}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"150px"}
                >
                  GST
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"200px"}
                >
                  Row Total{" "}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        {data.Items.map((item, index) => {
          if (view !== "view") {
            item.item_id = item.id;
            item.item_price = item.price;
          }
          return (
            <>
              {console.log(item)}

              <TableRow>
                <TableCell
                  sx={{
                    padding: "0 2px 0 0",
                    borderRight: "1px solid #d5d5d5",
                  }}
                  align="center"
                >
                  {index + 1}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  style={{ position: "relative" }}
                >
                  {item.item_id !== 0 ?<Link to={"/admin/item/view/" + item.item_id}>
                    {item.item_code}
                  </Link> : item.item_code }
                  
                </TableCell>

                <TableCell
                  sx={{ p: "1px 5px", borderRight: "1px solid #d5d5d5" }}
                >
                  {item.item_title}
                </TableCell>

                {layout === "dc" ? (
                  <>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.item_qty}
                    </TableCell>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.measure_of_unit}
                    </TableCell>
                    <TableCell
                      sx={{ p: "2px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.item_rejected}
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}
                {layout === "invoice" ? (
                  <>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.item_qty} {item.unit_of_measure}
                    </TableCell>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {FormattingNumber(item.item_price)}.00
                    </TableCell>
                    <TableCell
                      sx={{ p: "2px 5px", borderRight: "1px solid #d5d5d5" }}
                      align="right"
                    >
                      {FormattingNumber(item.item_gst_total)}.00
                    </TableCell>
                    <TableCell
                      sx={{ p: "2px 5px", borderRight: "1px solid #d5d5d5" }}
                      align="right"
                    >
                      {FormattingNumber(item.item_row_total_gst)}
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}

                {layout === "quotation" ? (
                  <>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.item_qty}/{item.item_left} {item.unit_of_measure}
                    </TableCell>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {item.delivery_status}
                    </TableCell>
                    <TableCell
                      sx={{ p: "0px 5px", borderRight: "1px solid #d5d5d5" }}
                    >
                      {FormattingNumber(item.item_price)}.00
                    </TableCell>
                    <TableCell
                      sx={{ p: "2px 5px", borderRight: "1px solid #d5d5d5" }}
                      align="right"
                    >
                      {FormattingNumber(item.item_gst_total)}.00
                    </TableCell>
                    <TableCell
                      sx={{ p: "2px 5px", borderRight: "1px solid #d5d5d5" }}
                      align="right"
                    >
                      {FormattingNumber(item.item_row_total_gst)}.00
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}
              </TableRow>

              <TableRow sx={{ p: 0 }}>
                {data.rejections.map((rejectItems) => {
                  console.log(rejectItems.dc_item_id, item.id);
                  return rejectItems.dc_item_id === item.id ? (
                    <>
                      <TableCell
                        colSpan={8}
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="right"
                      >
                        <Alert severity="error">
                          <Typography variant="b">
                            {rejectItems.rejected_qty} Items Rejected on{" "}
                            {moment(rejectItems.create_at).format("DD/MM/YYYY")}{" "}
                            - Reason: {rejectItems.reason}
                          </Typography>
                        </Alert>
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  );
                })}
              </TableRow>
            </>
          );
        })}
      </Table>
    </>
  ) : (
    <>
      <Segment textAlign="center">
        <Header icon>There is no Item added in Quote</Header>
      </Segment>
    </>
  );
};

export default ItemListing;
