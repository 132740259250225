import React, { useState, useEffect } from "react";
import "./../create.css";
import { Icon } from "semantic-ui-react";

import {
  Divider,
  Snackbar,
  Tab,
  Tabs,
  OutlinedInput,
  FormControl,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import magento from "../../api/magento";
import CreatePDF from "../../components/pdf/Default/InvoicePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NumberIntoWord from "../../components/NumberIntoWord";
import CreateTEInvPDF from "../../components/pdf/Red/InvoicePDF";

import MuiAlert from "@mui/material/Alert";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  DataGrid, GridToolbar,
} from "@mui/x-data-grid";
import { ArrowBack } from "@mui/icons-material";
import ItemListing from "../../components/ItemListing";
import DataLayout from "../../components/DataLayout";
import QuoteIntoInv from "../../components/QuoteIntoInv";
import PopOverComponent from "../../components/popover/PopOverComponent";
const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

const options = ["Expired", "Processed", "Partial Processed", "Generated"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewPo({ navigate }) {
  const location = useLocation();
  const { InvId, tType } = useParams();
  const [pdfTemplate, setPdfTemplate] = useState(1);
  const [message, setmessage] = useState("");
  const [data, setData] = useState("");
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };


  
 const PDFCheck  = (data, ttype)=>{
  switch (data.Data[0].channel_id) {
    case 2:
      console.log(pdfTemplate,'true');
      return <CreateTEInvPDF data={data} ttype={ttype} /> 
      
    default:
      console.log(pdfTemplate, 'default ');
      return <CreatePDF data={data} ttype={ttype} />    
  }

 }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const ChangeHandle = (event: SelectChangeEvent) =>
    setStatus(event.target.value);

  const InvoiceData = async () => {
    await magento
      // .get(`http://localhost:3001/invoice/${InvId}`, {
      .get(`invoice/${InvId}`, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log('test',response.data);
        setData(response.data);
        setStatus(response.data.Data[0].status);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [state, setState] = useState({ activeIndex: 1 });
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const handleSelectChange = () => {
    console.log("heelo");
  };

  const CreateSelectedItemListing = () => {
    return <ItemListing data={data} layout="invoice" />;
  };
  const headdetails = () => {
    return (
      <>
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          {(!tType) ? '' :  data.Data[0].tax_type} Invoice # {(!tType) ? data.Data[0].invoice_number : data.Data[0].invoice_number_type}{" "}
          <small
            style={{ verticalAlign: "center", fontSize: "14px", color: "#777" }}
          >
            ({status})
          </small>
        </Typography>
        <Button  variant="contained" align="right" className="ui primary button tiny">
            Receive Payment
          </Button>
        <PopOverComponent
          ButtonIcon="print"
          buttonTitle="Print"
          menu={[
            <Link
              to={`/view/pdf/inv/${data.Data[0].id}`}
              target="_blank"
              style={{ color: "#000" }}
            >
              <Icon name="print" /> Print Invoice
            </Link>,

            <Link
            to={`/view/pdf/inv/${data.Data[0].tax_type}/${data.Data[0].id}`}
            target="_blank"
            style={{ color: "#000" }}
            >
            <Icon name="print" /> Print {data.Data[0].tax_type} Tax Invoice
            </Link>,
          ]}
        />
        <PopOverComponent
          ButtonIcon="file pdf"
          buttonTitle="Download"
          menu={[
            <PDFDownloadLink
              style={{ color: "#000" }}
              fileName={
                data.channel &&
                data.channel[0].channel +
                  "-po#" +
                  data.Data[0].po_number +
                  "-invoice#" +
                  data.Data[0].invoice_number +
                  ".pdf"
              }
              document={PDFCheck(data, "Standard")}
            >
              <Icon name="file pdf" /> Download Invoice
            </PDFDownloadLink>,
             <PDFDownloadLink
             style={{ color: "#000" }}
             fileName={
               data.channel &&
               data.channel[0].channel +
                 "-po#" +
                 data.Data[0].po_number +
                 "-"+ data.Data[0].tax_type + " invoice#" +
                 data.Data[0].invoice_number_type +
                 ".pdf" 
             }
             document={PDFCheck(data, data.Data[0].tax_type)}
           >
             <Icon name="file pdf" /> Download {data.Data[0].tax_type} Tax Invoice
           </PDFDownloadLink>,
          ]}
        />
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue.target.value);
    console.log(newValue.target.value);
    return status;
  };

  const infoBar = () => {
    return (
      <>
        <Typography variant="h6">Status: {data.Data[0].status}</Typography>
      </>
    );
  };

  const UpdateStatus = async () => {
    await magento
      .post(
        "quotation/update/status/" + InvId,
        { status: status },
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        }
      )
      .then(function (response) {
        console.log("Status has been Updated");
        handleClickOpen();

        setmessage({
          message: "Status has been sccuessfully updated",
          status: "success",
        });
        return message;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const StatusBar = (sdata) => {
    return (
      <>
        <FormControl
          style={{ display: "flex", flexDirection: "row" }}
          sx={{ m: 0, width: 220 }}
        >
          <Select
            labelId="statusLabel"
            id="newstatus"
            onChange={handleStatusChange}
            placeholder="Update Status"
            input={
              <OutlinedInput
                sx={{
                  flex: 1,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                label="Status"
              />
            }
            MenuProps={MenuProps}
            native
          >
            {options.map((i, index) =>
              sdata === i ? (
                <option key={index} value={i} selected>
                  {i}
                </option>
              ) : (
                <option key={index} value={i}>
                  {i}
                </option>
              )
            )}
          </Select>
          <Button
            color="success"
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            size="small"
            variant="contained"
            onClick={() => UpdateStatus()}
          >
            Change
          </Button>
        </FormControl>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    InvoiceData();
    if (location.state) {
      handleClickOpen();
      setmessage({ message: location.state.message, status: "success" });
    } else {
      console.log("No Message");
    }
    // console.log(data.Data, "new");
  }, []);

  return (
    <>
      {data.Data && (
        <DataLayout data={data} navigate={navigate} headdetails={headdetails}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert
              elevation={6}
              variant="filled"
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity={message.status}
            >
              {message.message}
            </MuiAlert>
          </Snackbar>
          <Grid sx={{ px: 2 }} container spacing={2}>
            <Grid textAlign={"left"} md={5} sx={{ p: 2 }}>
              <Typography
                variant="h6"
                textAlign="left"
                color={"#777"}
                fontSize={20}
              >
                To: {data.Data[0].bill_to_company}{" "}
                <Link
                  to={{ pathname: `/admin/invoice/edit/${data.Data[0].id}` }}
                  state={{ data }}
                >
                  <small>- Edit</small>
                </Link>
              </Typography>
              <Typography variant="p" marginBottom={0}>
                {data.Data[0].bill_to}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                STN#: {data.Data[0].bill_to_stn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                NTN#: {data.Data[0].bill_to_ntn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {data.Data[0].bill_to_phone}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                By: {data.channel[0].channel}{" "}
              </Typography>

              <Typography variant="p" marginBottom={0}>
                {data.channel[0].address}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                STN#: {data.channel[0].stn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                NTN#: {data.channel[0].ntn}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {data.channel[0].phone}
              </Typography>
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
              <Typography
                color={"#777"}
                fontSize={20}
                bottom
                variant="h6"
                textAlign="left"
              >
                Generated By:
              </Typography>
              <Typography variant="p" marginBottom={0}>
                Name: {data.Data[0].name}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Role: {data.Data[0].role}
              </Typography>
              <br />
              <Typography variant="p" marginBottom={0}>
                Phone: {data.Data[0].phone}
              </Typography>
              <br />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  disabled
                  fullWidth
                  id="invoice_number"
                  label="Invoice #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.Data[0].invoice_number}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="create_at"
                  label="Invoice Date"
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(data.Data[0].invoice_create_at).format(
                    "YYYY/MM/DD"
                  )}
                  disabled
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="po_number"
                  label="PO #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  marginBottom="0"
                  value={data.Data[0].po_number}
                />
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                disabled
                fullWidth
                id="invoice_number_type"
                label={data.Data[0].tax_type === "None" ? "No Tax" : data.Data[0].tax_type + " Invoice#"}
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={data.Data[0].tax_type === "None" ? "None GST or SST" : data.Data[0].invoice_number_type }
              />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="ref_number"
                  label="Reference #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  marginBottom="0"
                  value={data.Data[0].ref_number}
                />
              </Grid>
              <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="terms"
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions"
                  multiline
                  sx={{ background: "#fff" }}
                  variant="outlined"
                  disabled
                  rows={5}
                  value={data.Data[0].terms}
                />
                <br/>
                <br/>
                <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                placeholder="Please enter remarks"
                sx={{ background: "#fff" }}
                multilineInputLabelProps={{
                  shrink: true, 
                }}
                variant="outlined"
                rows={2}
                value={data.Data[0].remarks}
                disabled
              />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
              <Typography variant="h6" color={"#555"} fontSize={20}>
                Details{" "}
                <Link
                  to={{ pathname: `/admin/invoice/edit/${data.Data[0].id}` }}
                  state={{ data }}
                >
                  <small>- Edit</small>
                </Link>
              </Typography>
            </Grid>

            <Grid textAlign={"right"} md={12} sx={{ px: 4 }}>
              {<>{data.Items && CreateSelectedItemListing()}</>}
            </Grid>
            <Grid md={12} sx={{ px: 4, my: 2 }}>
              {" "}
            </Grid>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography
                  textAlign={"left"}
                  variant="h6"
                  borderBottom={1}
                  borderColor="#ccc"
                >
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography
                  textAlign={"right"}
                  variant="h6"
                  borderBottom={1}
                  borderColor="#ccc"
                >
                  {data.Data[0].subtotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Additional
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {data.Data[0].additional_charges}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                {data.Data[0].gstinpercentage ? (
                  <Typography textAlign={"left"} variant="h6">
                    GST({data.Data[0].gstinpercentage}%)
                  </Typography>
                ) : (
                  <Typography textAlign={"left"} variant="h6">
                    Sales Tax (GST)
                  </Typography>
                )}
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {data.Data[0].gst_total}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6" color="error">
                  Discount
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6" color="error">
                  - {data.Data[0].discount}
                </Typography>
              </Grid>
              <Grid
                md={6}
                sx={{ py: 1 }}
                borderTop={1}
                borderBottom={1}
                borderColor="#ccc"
              >
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid
                md={6}
                sx={{ py: 1 }}
                borderTop={1}
                borderBottom={1}
                borderColor="#ccc"
              >
                <Typography textAlign={"right"} variant="h5">
                  {data.Data[0].grand_total_gst}
                </Typography>
              </Grid>
              <Grid textAlign={"left"} fontSize="12" md={12} sx={{ py: 1 }}>
                <strong>
                  <NumberIntoWord
                    number={parseInt(data.Data[0].grand_total_gst)}
                  />
                </strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid textAlign={"left"} md={12} sx={{ px: 0, py: 2 }}>
              <Divider />
              <Box sx={{ p: 1, py: 0 }} bgcolor={"#f8f8f8"} height="100">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="#000000"
                  variant="standard"
                  aria-label="full width tabs example"
                >
                  <Tab label={"Payments"} />
                  <Tab label={"DC"} />
                  <Tab label={"Quotation"} />
                  <Tab label={"Logs"} />
                </Tabs>
              </Box>
              <Divider />
              <TabPanel value={value} index={0}>
                <Typography variant="h6">
                 Payments againist on Inovice #{data.Data[0].invoice_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    { field: "payment_number", hideable: true, headerName: "Payment ID" },
                    {
                      field: "payment_date",
                      hideable: true,
                      headerName: "Date",
                    },
                    {
                      field: "receipt_number",
                      hideable: true,
                      headerName: "Receipt Number",
                    },

                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref #",
                    },
                    {
                      field: "payment_method",
                      hideable: true,
                      headerName: "Method",
                    },
                    {
                      field: "receipt_amount",
                      hideable: true,
                      headerName: "Amount",
                    },
                    {
                      field: "income_tax_amt",
                      hideable: true,
                      headerName: "Tax",
                    },
                    {
                      field: "total_savings",
                      hideable: true,
                      headerName: "Savings",
                    },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/payment/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.payments}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography variant="h6">
                  DC againist on Inovice #{data.Data[0].invoice_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    { field: "dc_number", hideable: true, headerName: "DC#" },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                    },
                    {
                      field: "create_at",
                      hideable: true,
                      headerName: "Create at",
                    },

                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items",
                    },
                    {
                      field: "username",
                      hideable: true,
                      headerName: "Created By",
                    },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                    },

                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/dc/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.dcOnQuote}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography variant="h6">
                  Quotation againist on Invoice #{data.Data[0].invoice_number}
                </Typography>
                <DataGrid
                  sx={{ my: 2, p: 1, background: "#ffffff" }}
                  density="compact"
                  components={{ Toolbar: GridToolbar }}
                  columns={[
                    {
                      field: "quote_number",
                      hideable: true,
                      headerName: "Quote#",
                    },
                    {
                      field: "po_number",
                      hideable: true,
                      headerName: "PO#",
                    },
                    {
                      field: "ref_number",
                      hideable: true,
                      headerName: "Ref#",
                    },
                    {
                      field: "create_at",
                      hideable: true,
                      headerName: "Create at",
                    },

                    {
                      field: "total_items_count",
                      hideable: true,
                      headerName: "Items",
                    },
                    {
                      field: "status",
                      hideable: true,
                      headerName: "Status",
                    },
                    {
                      field: "actions",
                      type: "actions",
                      getActions: (GridRowParams) => [
                        <Link to={`/admin/quotation/view/${GridRowParams.id}`}>
                          View
                        </Link>,
                      ],
                    },
                  ]}
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
                  autoHeight
                  rows={data.invOnQuote}
                  pageSize="50"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                {data.logs.map((item, index) => {
                  return (
                    <>
                      <Typography variant="p" sx={{ pb: 1 }}>
                        {item.description}
                      </Typography>
                      <Divider />
                    </>
                  );
                })}
              </TabPanel>
            </Grid>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 2 }}></Grid>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 2 }}></Grid>
          </Grid>
          <Divider />
          <Grid container></Grid>
        </DataLayout>
      )}
    </>
  );
}
