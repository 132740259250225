import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  Svg,
  Rect,
  // Font,
} from "@react-pdf/renderer";

import moment from "moment/moment";
import signature from "./../../../admin/order/mo-signature.png";
import stamp from "./../../../admin/order/te-stamp.png";
import NumberIntoWord from "./../../NumberIntoWord";
import {FormattingNumber}from './../../../Helper/Helper';
// Font.register({
//   family: "RobotoBold",
//   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900",
// });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9Regular' });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap', { fontFamily: 'Roboto-Bold' });

const TaxCheck = (data) =>{
  if(data.Data[0].gstinpercentage !== 0){

  return <>
      <Text style={styles.grandTotalHeading}>
      {data.Data[0].tax_type == "SST" 
                  ? "SST"
                  : "GST"}
      ({data.Data && data.Data[0].gstinpercentage}%):
    </Text>
    <Text style={styles.grandTotalHeading}>
      {data.Data && FormattingNumber(data.Data[0].gst_total)}.00
    </Text>
  
  </>
  }
}
const tbDivider = (offset, offsetTop = 0 ) => {
  return (
    <Svg
      height={ offsetTop > 0 ? "419px" : "425px"}
      width="2"
      style={{ position: "absolute", left: offset, top: offsetTop }}
      fixed
    >
      <Line
        style={{}}
        x1="0px"
        y1="0px"
        x2="2px"
        y2="520px"
        // style={{ position: "absolute" }}
        strokeWidth={1}
        stroke="#ccc"
      />
    </Svg>
  );
}; 

const tbDividerHor = (offset, offsetTop = 0 ) => {
  return (
    <Svg
      height="3"
      width="100%"
      style={{ position: "absolute", left: offset, top: offsetTop }}
      fixed
    >
      <Line
        style={{}}
        x1="0px"
        y1="0px"
        x2="564px"
        y2="0px"
        // style={{ position: "absolute" }}
        strokeWidth={1}
        stroke="#ccc"
      />
    </Svg>
  );
}; 
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 180,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {

    paddingVertical: 10,
    width: "100%",
    height: "1.5in",
  },
  topSection: {
    padding: 15,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },MainHeading: {
    fontSize: 23,
    fontWeight: "700",
    textAlign: "right",
    marginLeft: 170,
    lineHeight:1.2,
    textTransform:'uppercase'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    right: "3%",
    top: "12pt",
  },
  MainText: {
    fontSize: 9,
    marginVertical: 2,
    lineHeight:1.2
  },
  SubHeading: {
    fontSize: "9pt",
    marginTop: 5,
    textAlign: "left",
    marginLeft: 170,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 1.3,
    fontSize:8,
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 8,
                marginRight:5,
                width: "61%",
                height: 80,
                minHeight: 60,
                backgroundColor:'#fff',
  },
  ShippingAddress: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    backgroundColor:'#fff',
    
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                paddingHorizontal:8,
                paddingVertical:5,
                marginLeft:10,
                width: "61%",
                height: 80,
                minHeight: 60,
  },    
  BillingAddress: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 8,
                marginRight:10,
                width: "61%",
                height: 80,
                minHeight: 60,
  },
  logo: {
    position:'absolute',
    width: "100%",
    left:'-30px',
    top:"-20px",
    minHeight: 90,
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  stamp: {
    position:'absolute',
    width: "55%",
    right:'-20px',
    top:"-25px",
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  signature: {
    position:'absolute',
    width: "55%",
    right:'30px',
    bottom:"0px",
    
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  grid: {
    width: "100%",
    marginTop: 5,
    marginHorizontal: 15,
    
  },
  WordInText: {
    textAlign: "left",
    fontSize: 8,
    textTransform: "capitalize",
    color: "#000000",
  },
  productPart: {
    flex: 3.5,
    paddingHorizontal: 5,
    paddingVertical: 2.5,
    // borderLeft: "1px solid #ccc",
    height: "100%",
  },
  GridHeader: {
    // overflow: "hidden",
    width: "100%",
    display: "flex",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    border: 1,
    borderStyle: "solid",
    borderColor: "#ccc",
    minHeight: "25px",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    paddingVertical: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#ccc",
    // borderBottomLeftRadius: 5,
    // borderBottomRightRadius: 5,
    height: 10,
  },
  GridProductsItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor:'#ccc',
    textAlign: "center",
    // borderBottomWidth: 1,
    // borderBottomStyle: "solid",
    // borderBottomColor: "#ccc",
    paddingTop:2,
  },
  InvoiceNumber: {
    fontSize: 12,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 9,
    textAlign: "left",
    alignSelf: "left",
  },
  gridText: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
  },
  productSno: {
    flex: 0.6,
    padding: 2.5,
    // borderRightWidth: 1,
    // borderRightColor: "#777",
    // borderRightStyle: "dotted",
    height: "100%",
  },
  productTitle: {
    flex: 9.5,
    padding: 2.5,
    // borderRightWidth: 1,
    // borderRightColor: "#777",
    // borderRightStyle: "dotted",
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
  },

  productQty: {
    flex: 2.2,
    padding: 2.5,
    // borderRightWidth: 1,
    // borderRightColor: "#777",
    // borderRightStyle: "dotted",
    height: "100%",
  },
  productPrice: {
    flex: 3,
    padding: 2.5,
    // borderRightWidth: 1,
    // borderRightColor: "#ccc",
    // borderRightStyle: "solid",
    height: "100%",
  },
  producctTotal: {
    flex: 3,
    padding: 2.5,
    height: "100%",
    // borderRight: "1px solid #ccc",
  },
  productGrid: {
    padding: 3,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#ccc",
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  productNote: {
    padding: 5,
    flex: 9,
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 9,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 9,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 10,
    textAlign: "right",
    textTransform: "uppercase",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
    textAlign: "center",
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
    textAlign: "center",
  },
});

// Create Document Component
const QuotationPDF = ({ data, type }) => {
  data.Data && console.log(data.channel[0].logo);
  return (
    <Document
      fileName="test.pdf"
      creator="Techno Era"
      author="Techno Era"
      title={type}
      keywords="Techno Era"
      subject={type}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pagewrapper} fixed>
          <View style={styles.topSection}>
            <View style={styles.HeadingWrapper}>
              {data.channel && (
                <Image
                  style={styles.logo}
                  source={{
                    uri:
                      "https://accounting.automarts.pk/assets/products/" +
                      data.channel[0].logo,
                    method: "GET",
                    headers: {},
                    body: "",
                  }}
                />
              )}
              {/* <Image style={styles.logo} src={logo} /> */}
            </View>
            <Svg viewBox="0 0 720 300" style={{transform:'rotate(-25deg)', position:'absolute',top:-140, left:-90, zIndex:-1}}>

                <Rect
                  width="900"
                  height="600"
                  fill="#DA1F2D"
                />
                </Svg>
            <View style={styles.InfoWrapper}>
              <Text style={styles.MainHeading}>Quotation</Text>
              
              <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  {data.channel && data.channel[0].address}{", "} 
                </Text>
                <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  STN: {data.channel && data.channel[0].stn}{", "}NTN: {data.channel && data.channel[0].ntn}
                </Text>
                <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  Email: {data.channel && data.channel[0].email}
                </Text>
                { <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  Phone: {data.channel && data.channel[0].phone}
                  
                </Text>
            }

            </View>
          </View>
        </View>
        <View style={styles.CustomerInfo} fixed>
        
          <View style={[styles.InfWrapper, {borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                paddingVertical:5,
                paddingHorizontal: 8,
                marginRight:10,
                width: "61%",
                height: 80,
                minHeight: 70,}]}>
            <Text style={styles.MainText}>
              {data.Data && "To:" +  data.Data[0].quote_to_company}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "Address: " +  data.Data[0].quote_to}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "STN: " + data.Data[0].quote_to_stn}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && "NTN: " + data.Data[0].quote_to_ntn}
            </Text>
            {/* <Text style={styles.MainText}>
              {data.Data && "Phone: " + data.Data[0].quote_to_phone}
            </Text> */}
          </View>
          
          <View style={styles.ShippingAddress}>
          <Text style={styles.MainText}>
                Date: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {data.Data &&
                  moment(data.Data[0].create_at).format("DD/MM/YYYY")}
              </Text>
              <Text style={styles.MainText}>
                Quotation: &nbsp;
                {data.Data && data.Data[0].quote_number}
              </Text>
              <Text style={styles.MainText}>
                {data.Data && data.Data[0].ref_number !== ""
                  ? "Ref. No.: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data.Data[0].ref_number +
                    "  "
                  : " "}
              </Text>
          </View>
          {/* <View style={styles.BillingAddress}>
            <Text style={{ fontSize: 9, marginBottom: 10 }}>
              Terms & Condition
            </Text>
            <Text style={{ fontSize: 9, marginBottom: 5 }}>
              
            </Text>
          </View> */}
        </View>
        <View style={[styles.grid]} >
          {tbDividerHor(2,'425pt')}
          {tbDivider(0, 5)}
          {tbDivider(75)}
          {tbDivider(270)}
          {tbDivider(325)}
          {tbDivider(380)}
          {tbDivider(442)}
          {tbDivider(502)}
          {tbDivider(564, 5)}
        <View style={styles.GridHeader} fixed>
            {/* <View style={styles.productSno}>
              <Text style={styles.gridHeading}>S#</Text>
            </View> */}
            <View style={[styles.productPart, { borderLeft: "none" }]}>
              <Text style={styles.gridHeading}>Item Code</Text>
            </View>

            <View style={styles.productTitle}>
              <Text style={styles.gridHeading}>Description</Text>
            </View>
            <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>Availability</Text>
            </View>
            <View style={styles.productQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>Rate</Text>
            </View>
            <View style={styles.productPrice}>
              <Text style={styles.priceCenter}>{data.Data && data.Data[0].tax_type == "SST" 
                  ? "SST"
                  : "GST"}{" "}</Text>
            </View>

            <View style={[styles.producctTotal, { borderRight: "none" }]}>
              <Text style={styles.priceCenter}>Total</Text>
            </View>
          </View>
          {data.Items &&
            data.Items.map((item, index) => {
              //   console.log(data.Data[0].id);
              return (
                <View style={styles.GridProductsItems} break={index === 18 ? true : false}>
                  {/* <View style={styles.productSno}>
                    <Text style={styles.gridText}>{index + 1}</Text>
                  </View> */}
                  <View style={styles.productPart}>
                    <Text style={styles.gridText}>{item.item_code}</Text>
                  </View>
                  <View style={styles.productTitle}>
                    <Text style={styles.gridText}>{item.item_title}</Text>
                  </View>
                  <View style={styles.productPrice}>
                    <Text style={styles.priceCenter}>
                      {item.delivery_status}
                    </Text>
                  </View>
                  <View style={styles.productQty}>
                    <Text style={styles.priceCenter}>{item.item_qty} {item.unit_of_measure}</Text>
                  </View>
                  <View style={styles.productPrice}>
                    <Text style={styles.priceRight}>{FormattingNumber(item.item_price)}.00</Text>
                  </View>
                  <View style={styles.productPrice}>
                    <Text style={styles.priceRight}>{FormattingNumber(item.item_gst_total)}.00</Text>
                  </View>
                  <View style={styles.producctTotal}>
                    <Text style={styles.priceRight}>
                      {FormattingNumber(item.item_row_total_gst)}.00
                    </Text>
                  </View>
                </View>
              );
            })}

        </View>
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingHorizontal: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: 50,
            bottom: 7,
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 15,
            }}
          >
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "60%",
                height: 70,
                marginBottom: 2,
                minHeight: 51,
              }}
            >
              <View style={{ height: 20, minHeight: 30 }}>
                {data.Data && (<>
                  <Text style={{ fontSize: 7.5, color: "#333" }}>Remarks:</Text>
                  <Text style={{ fontSize: 7.5, color: "#333" }}> {data.Data && data.Data[0].remarks}</Text>
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                width: "39%",
                marginLeft: "1%",
              }}
            >
              <View
                style={{
                  height: 23,
                  minHeight: 23,
                  borderColor: "#ccc",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Text style={styles.grandTotalHeading}>Subtotal:</Text>
                <Text style={styles.grandTotalHeading}>
                  {data.Data && FormattingNumber(data.Data[0].subtotal)}.00
                </Text>
              </View>
              <View
                style={{
                  height: 23,
                  minHeight: 23,
                  borderColor: "#ccc",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                  color:'#da1f2d',
                }}
              >
                <Text style={styles.grandTotalHeading}>Discount:</Text>
                <Text style={styles.grandTotalHeading}>
                  - {data.Data && FormattingNumber(data.Data[0].discount)}.00
                </Text>
              </View>
              <View
                style={{
                  height: 23,
                  minHeight: 23,
                  borderColor: "#ccc",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
               {TaxCheck(data)}
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 15,
            }}
          >
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "60%",
                height: 22,
                marginBottom: 2,
                minHeight: 22,
              }}
            >
              <View style={{ height: 20, minHeight: 20 }}>
                {data.Data && (
                  <Text style={styles.WordInText}>
                    <NumberIntoWord
                      number={parseInt(data.Data[0].grand_total_gst)}
                    />
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "39%",
                height: 22,
                marginBottom: 3,
                minHeight: 22,
                marginLeft: "1%",
              }}
            >
              <View
                style={{
                  height: 22,
                  minHeight: 22,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.grandTotalHeading}>Grand Total:</Text>
                <Text style={styles.grandTotalHeading}>
                  {data.Data && FormattingNumber(data.Data[0].grand_total_gst)}.00
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 20,
            }}
          >
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "61%",
                height: 80,

                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75 }}>
                {data.Data && (<>
                  <Text style={{ fontSize: 8.5, color: "#333" }}>Terms & Conditions:</Text>
                  <Text style={{ fontSize: 8.5, color: "#333" }}> {data.Data && data.Data[0].terms}</Text>
                  </>
                )}
              </View>
            </View>

            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 10,
                width: "40%",
                height: 80,
                marginLeft: "1%",
                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75 }}>
                <Text style={{ fontSize: 10, marginBottom: 5 }}>
                  For {data.channel && data.channel[0].channel}
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  Authorized Signatory:
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  {data.Data && data.Data[0].name}
                </Text>
                <Text style={{ fontSize: 6, marginBottom: 5 }}>
                  {data.Data && data.Data[0].role}
                </Text>
                <Image style={styles.stamp} src={stamp} />
                <Image style={styles.signature} src={signature} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default QuotationPDF; 
