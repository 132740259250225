import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";

import { useParams } from "react-router-dom";
import { PDFCheck, getCall } from "../../Helper/Helper";
// Font.register({
//   family: "RobotoBold",
//   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900",
// });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9Regular' });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap', { fontFamily: 'Roboto-Bold' });

// Create Document Component
const ViewQuotePDF = ({type, notax}) => {
  const [data, setData] = useState("");
  const { Id, taxtype } = useParams();
  const callData = (t) => {
    if (t === 'quotation') {
      return `/quotation/${Id}`
    }
    if (t === 'invoice') {
      return `/invoice/${Id}`
    }
    if (t === 'dc') {
      return `/dc/${Id}`
    }
  }

  const apiCall = async () => {

    await getCall(callData(type)).then((response) => {
      setData(response);
        return data;
    })
  };
  console.log(data);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <PDFViewer width="100%" height="1000px">
      {data.Data && PDFCheck(data, type, taxtype, notax)}       
    </PDFViewer>
  );
};

export default ViewQuotePDF;
