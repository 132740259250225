import { createContext, useState } from "react";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [IsActive, setIsActive] = useState(true);
  const [mainData, setMainData] = useState([]);

  const useAuth = () => {
    return token;
  };

  const MainDataSet = (data) => {
    setMainData({
      ...mainData,
      data,
    });
  };

  const setAuth = (status = true) => {
    setIsActive(status);
    return IsActive;
  };

  const IsLoggedIn = () => {
    return localStorage.getItem("channel");
  };

  const IsLoggedOut = () => {
    return localStorage.removeItem("channel");
  };

  return (
    <AuthContext.Provider
      value={{
        useAuth,
        setAuth,
        IsActive,
        IsLoggedIn,
        IsLoggedOut,
        MainDataSet,
        mainData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
