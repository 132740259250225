import React, { useState } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
} from "@mui/material";

import {
  ArrowBack,
  Send,
} from "@mui/icons-material";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControl} from "@mui/material";
import magento from "../../api/magento";
import FileUpload from "../../components/FileUpload";
import SubNavBar from "../../components/SubNavBar";

export default function AddChannel({ navigate }) {
  const [formData, setFormData] = useState({
    channel: "",
    logo: "",
    address: "",
    ntn: "",
    stn: "",
    invoice_series: "",
    quote_series: "",
    so_series: "",
    dc_series: "",
    salestax_series: "",
    servicetax_series: "",
    payment_series: "",
    phone: "",
    email: "",
  });

  const handleSelectChange = (e, data, update = "city") => {
    switch (update) {
      case "business":
        setFormData({ ...formData, business: `${data.value}` });
        break;
      default:
        setFormData({ ...formData, city: `${data.value}` });
        break;
    }
  };
  const OnSubmit = async (data) => {
    await magento
      .post("https://webapi.automarts.pk/channel/create", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json", 
        },
      })
      .then(function (response) {
        console.log(response.data);
        navigate(`/admin/channel`);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <CssBaseline />

        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Add New Channel
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Paper sx={{ mx: "20%", py: 2 }}>
          <Grid fluid sx={{ px: 2 }}>
            <Typography variant="h5" textAlign="left">
              {formData.channel}
            </Typography>
          </Grid>
          <Divider />

          <Grid fluid sx={{ p: 2 }}>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "35%" }}
            >
              <TextField
                label="Channel Name"
                helperText="Enter Channel Name"
                fullWidth
                variant="standard"
                value={formData.channel}
                onChange={(e) =>
                  setFormData({ ...formData, channel: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>

            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "26%" }}
            >
              <TextField
                id="ntn"
                name="ntn"
                label="NTN#"
                variant="standard"
                value={formData.ntn}
                helperText="Enter NTN Number"
                onChange={(e) =>
                  setFormData({ ...formData, ntn: e.target.value })
                }
                type="text"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "26%" }}
            >
              <TextField
                label="STN#"
                helperText="Enter STN Number"
                variant="standard"
                defaultValue={formData.stn}
                onChange={(e) =>
                  setFormData({ ...formData, stn: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl fullWidth textAlign="left" sx={{ mb: 2 }}>
              <TextField
                label="Office Address"
                helperText="Enter complete office address"
                fullWidth
                variant="standard"
                defaultValue={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                label="Office Phone Number"
                helperText="Enter office phone"
                variant="standard"
                defaultValue={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, mr: "2%", width: "48%" }}
            >
              <TextField
                label="Email Address"
                helperText="Enter Email address"
                variant="standard"
                defaultValue={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="mail"
                margin="10"
              />
            </FormControl>
            <FormControl textAlign="left" sx={{ mb: 2, width: "20%" }}>
              <TextField
                label="Invoice Series"
                helperText="Invoice series"
                variant="standard"
                defaultValue={formData.invoice_series}
                onChange={(e) =>
                  setFormData({ ...formData, invoice_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="Qoutation Series"
                helperText="Quotation series"
                variant="standard"
                defaultValue={formData.quote_series}
                onChange={(e) =>
                  setFormData({ ...formData, quote_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="DC Series"
                helperText="DC Series"
                variant="standard"
                defaultValue={formData.dc_series}
                onChange={(e) =>
                  setFormData({ ...formData, dc_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="SO Series"
                helperText="SO series"
                variant="standard"
                defaultValue={formData.so_series}
                onChange={(e) =>
                  setFormData({ ...formData, so_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="ST Series"
                helperText="SalesTax series"
                variant="standard"
                defaultValue={formData.salestax_series}
                onChange={(e) =>
                  setFormData({ ...formData, salestax_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="SST Series"
                helperText="ServiceTax series"
                variant="standard"
                defaultValue={formData.servicetax_series}
                onChange={(e) =>
                  setFormData({ ...formData, servicetax_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
            <FormControl
              textAlign="left"
              sx={{ mb: 2, ml: "2%", width: "20%" }}
            >
              <TextField
                label="Payment Series"
                helperText="Payment series"
                variant="standard"
                defaultValue={formData.payment_series}
                onChange={(e) =>
                  setFormData({ ...formData, payment_series: e.target.value })
                }
                type="text"
                margin="10"
              />
            </FormControl>
          </Grid>
          <Divider />
          <FileUpload />
          <Divider />
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save
          </Button>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textAlign: "right", mt: 1 }}
            variant="outlined"
            endIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Paper>
      </Box>
    </>
  );
}
