import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CheckCircle,
  DeleteForever,
  MenuOutlined,
  RemoveCircleOutline,
} from "@mui/icons-material";

import MuiAlert from "@mui/material/Alert";

import CompanySelection from "../../components/CompanySelection";
import {
  ActiveUser,
  FormatDate,
  getCall,
  postCall,
  profile,
  profileId,
  today,
} from "../../Helper/Helper";
import TableComp from "../../components/TableComp";
import { green, grey, red } from "@mui/material/colors";
import ModelPopup from "../../components/ModelPopup";
import OnClickModelPopup from "../../components/OnClickModelPopup ";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function Tasks({ navigate }) {
  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);

  const [Data, setData] = useState({
    CompanyList: [],
    channel_id: profileId,
    user_id: ActiveUser[0].id,
    deadline: today,
    status: "Pending",
    ref_person: "",
    query_ref: "",
    task_details: "",
  });
  const [Tasks, setTasks] = useState([]);
  const [disablebtn, setDisableBtn] = useState(true);

  const CallTasks = async () => {
    await getCall("/tasks/" + Data.user_id)
      .then((response) => {
        setTasks(response);
      })
      .catch((e) =>
        ValidationMessage("There is some problem with Internet", "error")
      );
  };
  const DeleteTask = async (TId) => {};

  const infoCall = async () => {
    Promise.all([
      setData({
        ...Data,
        CompanyList: await getCall("company/channel/" + profile[0].id),
      }),
    ]);
  };

  var ValidationMessage = (msg, status) => {
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const InsertTask = async (data) => {
    await postCall("task/create", data)
      .then((response) => {
        ValidationMessage("Task Successfully Added", "success");
        CallTasks();
        setData({
          ...Data,
          ref_person: "",
          query_ref: "",
          deadline: today,
          task_details: "",
        });
        setDisableBtn(true);
      })
      .catch(function (err) {
        ValidationMessage(err.message, "error");
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onRowClick = async (i, status) => {
    await postCall("tasks/status", { id: i.task_id, status })
      .then((response) => {
        ValidationMessage("Task has been sucessfully Updated", "success");
        CallTasks();
        return Tasks;
      })
      .catch((e) => ValidationMessage(e.message, "erro"));
  };

  const ValidateFields = (d) => {
    if (
      d.billTo.length == 0 ||
      d.ref_person == "" ||
      d.query_ref == "" ||
      d.task_details == ""
    ) {
      return setDisableBtn(true);
    } else {
      return setDisableBtn(false);
    }
  };

  const OnSubmitData = (data) => {
    console.log(data);
    setDisableBtn(true);
    if (data.billTo.length === 0) {
      setDisableBtn(false);
      return ValidationMessage("Please select company", "error");
    } else if (
      data.ref_person == "" ||
      data.query_ref == "" ||
      data.task_details == ""
    ) {
      setDisableBtn(false);
      return ValidationMessage(
        "Please fill all the feilds to add new task",
        "error"
      );
    } else {
      InsertTask(data);
      return true;
    }
  };
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    CallTasks();
    infoCall();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Daily Routine Tasks
            </Typography>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={4} textAlign="left">
            <CompanySelection data={Data} selectionOnly="true" />
          </Grid>
          <Grid item xs={2} sm={4} md={2} textAlign="left">
            <TextField
              fullWidth
              id="create_at"
              label="Task Deadline"
              type="date"
              defaultValue={Data.deadline}
              sx={{ background: "#fff" }}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setData({ ...Data, deadline: e.target.value });
                ValidateFields(Data);
                return Data;
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={2} textAlign="left">
            <TextField
              fullWidth
              value={Data.query_ref}
              id="query_reference"
              label="For:"
              type="text"
              sx={{ background: "#fff" }}
              InputLabelProps={{
                shrink: true,
              }}
              marginBottom="0"
              onChange={(e) => {
                setData({ ...Data, query_ref: e.target.value });
                ValidateFields(Data);
                return Data;
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={2} textAlign="left">
            <TextField
              fullWidth
              value={Data.ref_person}
              id="ref_person"
              label="Reference Person"
              type="text"
              sx={{ background: "#fff" }}
              InputLabelProps={{
                shrink: true,
              }}
              marginBottom="0"
              onChange={(e) => {
                setData({ ...Data, ref_person: e.target.value });
                ValidateFields(Data);
                return Data;
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={2} textAlign="left">
            <FormControl sx={{ minWidth: "100%" }}>
              <InputLabel
                id="demo-simple-select-autowidth-label"
                size="small"
                sx={{ m: 0, p: 0 }}
              >
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={Data.status}
                onChange={(e) => {
                  setData({ ...Data, status: e.target.value });
                  ValidateFields(Data);
                  return Data;
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", background: "#fff" }}
                size="medium"
                label="status"
              >
                <MenuItem sx={{ textAlign: "left" }} value="Pending">
                  Pending
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="Complete">
                  Complete
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={4} md={10} textAlign="left">
            <TextField
              fullWidth
              value={Data.task_details}
              id="task_description"
              label="Task Details"
              type="text"
              sx={{ background: "#fff" }}
              InputLabelProps={{
                shrink: true,
              }}
              marginBottom="0"
              onChange={(e) => {
                setData({ ...Data, task_details: e.target.value });
                ValidateFields(Data);

                return Data;
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={2} textAlign="left">
            <Button
              onClick={() => OnSubmitData(Data)}
              type="submit"
              variant="contained"
              color="success"
              size="large"
              sx={{ minWidth: "100%", minHeight: "100%" }}
              disabled={disablebtn}
            >
              Add Task
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid sx={{ mt: 2 }} item xs={2} sm={4} md={12} textAlign="left">
            <Paper sx={{ p: 1 }} bgcolor={"#fff"} height="100">
              <h1>Task Sheet</h1>
              <Divider sx={{ mb: 2 }} />
              <TableComp
                data={Tasks}
                THead={
                  <TableRow>
                    <TableCell width={"5%"}>Channel</TableCell>
                    <TableCell width={"5%"}>Company</TableCell>
                    <TableCell width={"5%"}>Ref.</TableCell>
                    <TableCell width={"8%"}>For</TableCell>
                    <TableCell width={"8%"}>Deadline</TableCell>
                    <TableCell width={"65%"}>Task Description</TableCell>
                    <TableCell width={"3%"}></TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                }
                TRow={(i) => {
                  let c = i.company.toProperCase();
                  let abbr = c.match(/\b([A-Z])/g).join("");
                  return (
                    <>
                      <TableRow
                        sx={{ fontSize: "5pt" }}
                        id={i.id}
                        onClick={() =>
                          onRowClick(
                            i,
                            i.status === "Pending" ? "Complete" : "Pending"
                          )
                        }
                        className={i.status}
                      >
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.channel}
                        </TableCell>
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          <span title={i.company}>{abbr}</span>
                        </TableCell>
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.ref_person}
                        </TableCell>
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.query_ref}
                        </TableCell>
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {FormatDate(i.deadline)}
                        </TableCell>
                        {/* <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.status}
                        </TableCell> */}
                        <TableCell sx={{ pr: 1, pl: 1, fontSize: "12px" }}>
                          {i.task_details}
                        </TableCell>
                        <TableCell sx={{ pr: 0, pl: 0, fontSize: "12px" }}>
                          {i.status === "Pending" ? (
                            <CheckCircle sx={{ color: green[700] }} />
                          ) : (
                            <RemoveCircleOutline sx={{ color: red[900] }} />
                          )}
                        </TableCell>
                        {/* <TableCell sx={{ pr: 0, pl: 0 }}>
                          <DeleteForever />
                        </TableCell> */}
                      </TableRow>
                    </>
                  );
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
