import React, { useState, useEffect } from "react";
import "./../create.css";
import { Header, Icon, Segment } from "semantic-ui-react";

import {
  Divider,
  Snackbar,
  InputAdornment,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Input,
  FormControl,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import magento from "../../api/magento";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import DataLayout from "../../components/DataLayout";
import moment from "moment";
import PreviewPopUp from "../../components/PreviewPopUp";
import { Delete, Edit } from "@mui/icons-material";
import ModelPopup from "../../components/ModelPopup";
import ItemAddComponenet from "../../components/ItemAddComponenet";
import SearchResultPopup from "../../components/SearchResultPopup";
import { Link, useLocation, useParams } from "react-router-dom";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function EditDc({ navigate }) {
  const location = useLocation();
  const today = moment(new Date()).format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const [disablebtn, setDisablebtn] = useState(true);
  const { DcId } = useParams();
  const [Profile, setProfile] = useState([]);
  const [message, setmessage] = useState("");
  const EmptyItem = {
    item_title: null,
    item_code: "",
    status: 1,
    item_qty: 1,
    qty: 1,
    currency: "Rs.",
    unit_of_measure: "kg",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    deliveryStatus: "1 Week",
    channel_id: profile[0].id,
    weight: 0,
    dc_item_id: 0,
    id: 0,
  };
  const [formData, setFormData] = useState({
    Channel: {},
    CompanyList: [],
    SearchResult: ["No Item Found"],
    billTo:[],
    measure_of_unit: location.state.data.Data[0].measure_of_unit,
    channel_id: location.state.data.Data[0].channel_id,
    SelectedItems: location.state.data.Items,
    create_at: location.state.data.Data[0].create_at,
    dc_date: location.state.data.Data[0].dc_date,
    item_count_total: location.state.data.Data[0].total_items_qty,
    po_number: location.state.data.Data[0].po_number,
    dc_number: location.state.data.Data[0].dc_number,
    quote_id: location.state.data.Data[0].quote_id,
    quote_number: location.state.data.Data[0].quote_number,
    ref_number: location.state.data.Data[0].ref_number,
    terms: location.state.data.Data[0].terms,
    remarks: location.state.data.Data[0].remarks,
    company_id: location.state.data.Data[0].company_id,
    created_at: location.state.data.Data[0].created_at,
    dc_to: location.state.data.Data[0].dc_to,
    dc_to_company: location.state.data.Data[0].dc_to_company,
    dc_to_email: location.state.data.Data[0].dc_to_email,
    dc_to_ntn: location.state.data.Data[0].dc_to_ntn,
    dc_to_phone: location.state.data.Data[0].dc_to_phone,
    dc_to_stn: location.state.data.Data[0].dc_to_stn,
    user_id: location.state.data.Data[0].uid,
    username: location.state.data.Data[0].username,
    name: location.state.data.Data[0].name,
    role: location.state.data.Data[0].role,
    phone: location.state.data.Data[0].phone,
    modify_at: today,
    modify_by: ActiveUser[0].username,
    modify_uid: ActiveUser[0].id,
    DeletedItems: [],
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //** Profile Data Call */
  const ProfileCall = async () => {
    // console.log("Channel Call");
    await magento
      .get(
        "channel/" +
          localStorage.getItem("channel"),
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        }
      )
      .then(function (response) {
        // console.log(response.data[0]);
        setProfile(response.data[0]);
        CompanyCall();
        return formData;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const [state, setState] = useState({ activeIndex: 1 });
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  // ** Calculate Item Row Total */
  const PriceCalculate = (
    qty = 1,
    item,

    type = null,
    val = null
  ) => {
    item.item_cost = item.cost;
    var saleTaxTotal = parseInt((17 / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((17 / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;
    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }
    if (type === "measure") {
      item.measure_of_unit = val;
    }

    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    item.CostTotal = CostTotal;
    item.rowTotal = rowTotal;
    item.saleTaxTotal = saleTaxTotal;
    item.subTotalWithoutTax = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  // ** Calculate Total */

  const CalulateTotal = (
    data = formData.SelectedItems,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;

    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;
    // console.log(Additionalcharges, "Checking addtional charges");
    if (data.length > 0) {
      // console.log(vdata.length);
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );
      total_items_qty = parseInt(
        data.reduce(
          (prevValue, currentValue) => prevValue + currentValue.item_qty,
          0
        )
      );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
      });
    } else {
      let gdtotal =
        parseInt(formData.SubTotal) +
        parseInt(Additionalcharges) -
        parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(formData.GSTTotal) +
          parseInt(formData.SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
      });
    }
    return formData;
  };
  // const PriceCalculate = (qty = 1, item, type = null, val = null) => {
  //   item.qty = qty;
  //   item.item_qty = qty;
  //   if (type === "code") {
  //     item.item_code = val;
  //   }
  //   if (type === "title") {
  //     item.item_title = val;
  //   }
  //   return item;
  // };

  //** Company Data Call */
  const CompanyCall = async () => {
    await magento
      .get(
        "company/channel/" +
          localStorage.getItem("channel"),
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        }
      )
      .then(function (response) {
        setFormData({ ...formData, CompanyList: response.data });
        // ProfileCall();
        return formData;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const CreateSelectedItemListing = () => {
    return formData.SelectedItems.length > 0 ? (
      <>
        {formData.SelectedItems.map((item, index) => {
          return (
            <>
              <Grid
                textAlign={"left"}
                container
                fullWidth
                sx={{
                  mb: 2,
                  pb: 2,
                  borderBottomWidth: 1,
                  borderBlockColor: "#ccc",
                  borderBottomStyle: "dotted",
                }}
              >
                <Grid md={11} sx={{ px: 1, mb: 2 }}>
                  <Typography variant="h5">
                    {index + 1}. {item.item_name && item.item_name}{" "}
                    {item.item_title && item.item_title}{" "}
                    <Typography variant="small">({item.item_code})</Typography>
                  </Typography>
                </Grid>
                <Grid md={1} sx={{ px: 1, mb: 2 }}>
                  <Button
                    compact
                    onClick={(e) => {
                      return DeleteItem(item, index);
                    }}
                  >
                    <Icon size="large" name="trash alternate outline" />
                  </Button>
                </Grid>
                <Grid md={2} sx={{ px: 1 }}>
                  <TextField
                    fullWidth
                    id="qty"
                    defaultValue={1}
                    label="Quantity"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      PriceCalculate(e.target.value, item);
                      return formData;
                    }}
                    size="small"
                  />
                </Grid>
                <Grid md={5} sx={{ px: 1 }}>
                  <TextField
                    disabled
                    fullWidth
                    id="sales_description"
                    label="Sales Description"
                    value={item.sales_description}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid md={5} sx={{ px: 1 }}>
                  <TextField
                    disabled
                    fullWidth
                    id="purchase_description"
                    label="Purchase Description"
                    value={item.purchase_description}
                    size="small"
                    helperText={""}
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </>
    ) : (
      <>
        <Segment textAlign="center">
          <Header icon>There is no Item added in Invoice</Header>
        </Segment>
      </>
    );
  };

  const DeleteItem = (data, dindex) => {
    formData.DeletedItems.push(data);    
    setFormData({
      ...formData,
      
      SelectedItems: formData.SelectedItems.filter((item, index) => {
        return index !== dindex;
      }),
    });
    handleClickOpen();
    setmessage({
      message: "Item row deleted",
      status: "error",
    });

    return formData;
  };

  const AddSelected = (data = EmptyItem) => {
    PriceCalculate(1, data);

    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    
    return formData;
  };

  const UpdateItem = (Uitem, extItem) => {
    // console.log(Uitem.item_title);
    extItem.measure_of_unit = Uitem.unit_of_measure;
    extItem.id = Uitem.id;
    extItem.item_id = Uitem.id;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.price = Uitem.price;
    extItem.item_price = Uitem.price;
    extItem.price = Uitem.price;
    extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    console.log(extItem);
    PriceCalculate(extItem.item_qty, extItem);

    return extItem;
  };

  const TableRowNew = (item, index) => {
    // console.log(index, item, "listing");
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {/* <ItemSearchComponent addItems={(d) => {AddSelected(d); console.log(d)} } /> */}
            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              fullwidth
              size="small"
              width="100%"
              onChange={(e) => {
                return PriceCalculate(
                  item.item_qty,
                  item,
                  "code",
                  e.target.value
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      // console.log(newItem);
                      UpdateItem(newItem, item);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              value={item.item_title}
              fullWidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(item.item_qty, item, "title", e.target.value);
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                textAlign: "center",
                border: "none",
                "& input": {
                  px: "4px",
                  fontSize: "0.875rem",
                  textAlign: "center",
                },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              p: "0px",
              borderRight: "1px solid #d5d5d5",
              textAlign: "center",
            }}
          >
            <TextField
              id={"item-measure-" + index}
              sx={{
                p: "0",
                textAlign: "center",
                border: "none",
                "& input": {
                  px: "4px",
                  fontSize: "0.875rem",
                  textAlign: "center",
                },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="text"
              size="small"
              value={item.measure_of_unit}
              onChange={(e) => {
                PriceCalculate(item.item_qty, item, "measure", e.target.value);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            {item.item_id !== 0 ? (
              <ModelPopup
                mopen={true}
                title="Edit Item"
                content={<Edit fontSize="small" />}
                childComp={
                  <ItemAddComponenet
                    type="edit"
                    edata={item}
                    iid={item.item_id}
                    addItems={(newItem) => {
                      if (newItem) {
                        handleClickOpen();
                        setmessage({
                          message:
                            "Item information has been updated and row has been updated",
                          status: "success",
                        });
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                }
              />
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  const UpdateDc = async (data) => {
    console.log(data, "checking");
    await magento
      .post("dc/edit/" + DcId, data, {
        // .post("http://localhost:3001/dc/edit/" + DcId, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setTimeout(function () {
          navigate("/admin/dc/view/" + DcId, {
            state: {
              message: "DC has been sucessfully Edited",
            },
          });
        }, 1400);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  /**
   *
   *
   */

  const OnSubmitData = (data, profile) => {
    console.log(formData);
    return UpdateDc({ data, profile });
  };
  const headdetails = () => {
    return (
      <>
        {console.log(Profile)}
        <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
          Edit: Delivery Challan #{formData.dc_number}
        </Typography>
      </>
    );
  };
  useEffect(() => {
    console.log(formData);
    ProfileCall();
  }, []);
  return (
    <>
      <DataLayout data={formData} navigate={navigate} headdetails={headdetails}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <Grid sx={{ px: 2 }} container spacing={2}>
        <Grid textAlign={"left"} md={10} sx={{ p: 2 }}>
        {formData.quote_id > 0 ? (
              ""
            ) : (
              <CompanySelection data={formData} newCompany={false} />
            )}
          </Grid>
          <Grid textAlign={"left"} md={5} sx={{ p: 2 }}>
      

            <Typography
              color={"#777"}
              fontSize={20}
              bottom
              variant="h6"
              textAlign="left"
            >
              TO: {formData.dc_to_company}{" "}
            </Typography>
            <Typography variant="p" >
              {formData.dc_to}
            </Typography>
            <br />
            <Typography variant="p" >
              STN#: {formData.dc_to_stn}
            </Typography>
            <br />
            <Typography variant="p" >
              NTN#: {formData.dc_to_ntn}
            </Typography>
            <br />
            <Typography variant="p" >
              {formData.dc_to_email}
            </Typography>
            <br />
            <Typography variant="p" >
              Phone: {formData.dc_to_phone}
            </Typography>
          </Grid>
          <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
            <Typography
              color={"#777"}
              fontSize={20}
              bottom
              variant="h6"
              textAlign="left"
            >
              By: {Profile.channel}{" "}
            </Typography>
            <Typography variant="p" >
              STN#: {Profile.stn}
            </Typography>
            <br />
            <Typography variant="p" >
              NTN#: {Profile.ntn}
            </Typography>
            <br />
            <Typography variant="p" >
              {Profile.address}
            </Typography>
            <br />
            <Typography variant="p" >
              Phone: {Profile.phone}
            </Typography>
          </Grid>
          <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
            <Typography
              color={"#777"}
              fontSize={20}
              bottom
              variant="h6"
              textAlign="left"
            >
              Generated By:
            </Typography>
            <Typography variant="p" >
              Name: {formData.name}
            </Typography>
            <br />
            <Typography variant="p" >
              Role: {formData.role}
            </Typography>
            <br />
            <Typography variant="p" >
              Phone: {formData.phone}
            </Typography>
            <br />
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                id="po_number"
                label="PO #"
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.po_number}
                marginBottom="0"
                onChange={(e) => {
                  setFormData({ ...formData, po_number: e.target.value });
                  return formData;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="ref_number"
                label="Reference #"
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.ref_number}
                marginBottom="0"
                onChange={(e) => {
                  setFormData({ ...formData, ref_number: e.target.value });
                  return formData;
                }}
              />
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                id="quote_number"
                label="Quotation #"
                value={formData.quote_number}
                type="text"
                sx={{ background: "#fff" }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  formData.quote_id === 0 || formData.quote_id === null
                    ? false
                    : true
                }
                helperText={
                  formData.quote_id > 0 ? (
                    <Link to={"/admin/quotation/view/" + formData.quote_id}>
                      View Quotation
                    </Link>
                  ) : (
                    ""
                  )
                }
                onChange={(e) => {
                  // console.log(e.target.value);
                  setFormData({ ...formData, quote_number: e.target.value });
                  return formData;
                }}
              />

              <br />
              <br />
              <TextField
                fullWidth
                id="create_at"
                label="DC Date"
                type="date"
                sx={{ background: "#fff" }}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(formData.dc_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setFormData({ ...formData, dc_date: e.target.value });
                  return formData;
                }}
              />
            </Grid>
            <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
              <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                rows={5}
                multiline
                sx={{ background: "#fff" }}
                placeholder="Please enter remarks"
                variant="outlined"
                value={formData.remarks}
                onChange={(e) => {
                  setFormData({ ...formData, remarks: e.target.value });
                  return formData;
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Grid container spacing={1}>
          <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
            <Typography variant="h6" color={"#777"} fontSize={20}>
              Details
            </Typography>
            {/* <SearchBar/> */}
          </Grid>
          <Grid textAlign={"right"} md={12} sx={{ px: 4 }}>
            {formData.SelectedItems && (
              <>
                {/* <FormControl fullWidth="true" style={{fontSize:"8px"}} >               */}
                <Table
                  sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                  size="small"
                  aria-label="a dense table"
                  padding="0"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"50px"}
                      >
                        Sno.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"200px"}
                        align="left"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"500px"}
                      >
                        Product Description
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Qty.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Measure of Unit
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px" }}
                        width={50}
                        colSpan={2}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.SelectedItems.map((item, index) => {
                      // console.log(item, index, "consle");
                      return TableRowNew(item, index);
                    })}
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Button onClick={() => AddSelected()}>
                          Add New Row
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* </FormControl> */}
              </>
            )}
          </Grid>
          <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}></Grid>
          <Grid textAlign={"left"} md={5} sx={{ pl: 0, pr: 4, py: 2 }}></Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
            <PreviewPopUp data={[formData, profile[0]]} type="dc" />
          </Grid>
          <Grid textAlign={"right"} md={5} sx={{ px: 4, py: 2 }}>
            <Button
              variant="contained"
              color="inherit"
              type="submit"
              onClick={() => {
                setDisablebtn(false);
                console.log(formData);
                //  localStorage.setItem(
                //   "previewdata",
                //   JSON.stringify(formData, profile)
                // );
              }}
              sx={{ mx: 3 }}
            >
              Calculate
            </Button>
            <Button
              onClick={() => OnSubmitData(formData, Profile)}
              type="submit"
              variant="contained"
              color="success"
              size="large"
              disabled={disablebtn}
            >
              Save DC
            </Button>
          </Grid>
        </Grid>
      </DataLayout>
    </>
  );
}
