import { Dialog, Button, DialogContent } from "@mui/material";
import React from "react";

function  ModelPopup({ content, childComp, title, sze = "md" }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {}, []);

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          handleClickOpen();
        }}
      >
        {content}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{ padding: 15 }}
        maxWidth={sze}
      >
        <h1>{title}</h1>
        <DialogContent sx={{ padding: 2 }}>{childComp}</DialogContent>
      </Dialog>
    </>
  );
}

export default ModelPopup;
