import React, { useState } from "react";
import "./../create.css";
import { Button, Grid, Divider, Segment } from "semantic-ui-react";
import magento from "../../api/magento";
import { useLocation } from "react-router-dom";

export default function ViewChannel({ navigate }) {
  const location = useLocation();
  const formData = location.state;

  return (
    <>
      <Grid divided className="sub-container" columns="equal">
        <Grid.Row>
          <Grid.Column textAlign="left" width={14}>
            <>
              <h2 textAlign="left">Channel: {formData.channel}</h2>
              <Divider />
            </>
            <Grid divied width={16}>
              <Grid.Row>
                <Grid.Column width={4} textAlign="left">
                  <Segment.Group>
                    <Segment>
                      <h3>General Information</h3>
                      <p>Channel: {formData.channel}</p>
                      <p>STN #: {formData.stn}</p>
                      <p>NTN #: {formData.ntn}</p>
                    </Segment>
                    <Segment>
                      <h3>Address Information</h3>
                      <p>Address: {formData.address}, Pakistan</p>
                      <p>Phone: {formData.phone}</p>
                      <p>Email: {formData.email}</p>
                    </Segment>
                    <Segment>
                      <h3>Series </h3>

                      <p>SO: {formData.so_series}</p>
                      <p>DC: {formData.dc_series}</p>
                      <p>Invoice: {formData.invoice_series}</p>
                      <p>Quotaion: {formData.qoute_series}</p>
                    </Segment>
                  </Segment.Group>
                </Grid.Column>
                <Grid.Column textAlign="left" width={12}>
                  <Segment></Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
    </>
  );
}
