import { Search } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import magento from "../api/magento";
import "./styles.css";
import ItemAddComponenet from "./ItemAddComponenet";
import ItemAddVendorComp from "./ItemAddVendorComp";

function SearchResultPopup({ id, data, term, index, updateItem, vendor = false, vID,psize = 'md'}) {
  const [open, setOpen] = React.useState(false);
  const [edata, setEdata] = React.useState([]);
  const [Results, setResults] = React.useState([]);
  const [editSection, setEdit] = React.useState(false);
  const handleClickOpen = () => {
    SearchItem(term);
    setOpen(true);
  };

  const SearchItem = async (term, index) => {
    await magento
      .get(
        "/item/search/terms/" +
          encodeURIComponent(term) +
          "/" +
          id,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        // console.log(response.data);
        setResults(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const RowCall = (item, i) => {
    return (
      <>
        <TableRow
          onClick={() => {
            updateItem(item);
            handleClose();
          }}
          hover="true"
        >
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {i + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            {item.id}
          </TableCell>

          {term === item.item_code ? (
            <TableCell
              sx={{
                p: "1px",
                borderRight: "1px solid #d5d5d5",
                backgroundColor: "#faffd7",
              }}
            >
              {item.item_code}
            </TableCell>
          ) : (
            <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
              {item.item_code}
            </TableCell>
          )}
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.item_title}
            <br />
            <small>{item.sales_description}</small>
          </TableCell>

          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            {item.price}
          </TableCell>
          <TableCell sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}>
            {item.cost}
          </TableCell>
          <TableCell sx={{ px: "5px", borderRight: "1px solid #d5d5d5" }}>
            {item.weight}
          </TableCell>
          <TableCell sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}>
            {item.unit_of_measure}
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          ><Button onClick={() => {    
              setEdata(item);
              setEdit(true);
              // updateItem(item);
            // handleClose();
          }}>Add</Button></TableCell>
        </TableRow>
      </>
    );
  };
  const CheckVendor = () =>{
    return vendor ? <ItemAddVendorComp addterm={term} addItems={(d) => updateItem(d)} popup={() => handleClose()} vendor_id={vID} ed={editSection} type='edit'  edata={edata} />
     : <ItemAddComponenet  addterm={term} addItems={(d) => updateItem(d)}  popup={() => handleClose()}
  />
  }
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    console.log(data,'search popup')
    SearchItem(term);
  }, []);

  return (
    <>
      <IconButton
        disabled={term.length > 3 ? false : true}
        edge="end"
        onClick={handleClickOpen}
      >
        <Search />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{ minWidth: "900px", padding: 10 }}
      >
        <DialogContent sx={{ padding: 2 }}>
          <h2>Search Result "{term}"</h2>
          {Results[0] === "No Item Found" ? (
            CheckVendor()
          ) : (
            ""
          )}
          <Table
            sx={{ minWidth: "100%", border: "1px solid #d5d5d5", mt: "10px" }}
            size="small"
            aria-label="a dense table"
            padding="0"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"50px"}
                >
                  Sno.
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"50px"}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  width={"200px"}
                  align="left"
                >
                  Item Code
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  width={"500px"}
                >
                  Product Title
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"100px"}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"150px"}
                >
                  Cost
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"200px"}
                >
                  Weight{" "}
                </TableCell>
                <TableCell
                  sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                  align="center"
                  width={"150px"}
                >
                  Measure
                </TableCell>
                <TableCell sx={{ p: "2px" }} width={100}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Results[0] !== "No Item Found" ? (
                Results.map((item, index) => {
                  return RowCall(item, index);
                })
              ) : (
                <TableCell
                  colSpan={9}
                  sx={{
                    p: "2px",
                    mt: "10px",
                    borderRight: "1px solid #d5d5d5",
                  }}
                  align="center"
                  width={"150px"}
                >
                  <h2>No Item Found</h2>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SearchResultPopup;
