import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import magento from "../../api/magento";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import SubNavBar from "../../components/SubNavBar";

export default function DeliveryChallan({ navigate }) {
  const [Dc, setDc] = useState([]);
  const [FRow, setFRow] = useState({});
  const [SearchTerms, setSearchTerms] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const ItemPerPage = useState(15);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Dc.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Dc.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };
  const SearchQuote = async (quoteNo) => {
    await magento
      .get(
        "/search/quote/channel/" +
          localStorage.getItem("channel") +
          "/" +
          quoteNo,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setDc(response.data);
        return Dc;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const CallDc = async () => {
    await magento
      .get(
        "/dc/channel/" +
          localStorage.getItem("channel"),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setDc(response.data);
        response.data.map((item, index) => {
          setFRow(response.data);
        });
        return Dc;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };
  console.log(FRow);

  useEffect(() => {
    CallDc();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Delivery Challan
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          sx={{ mx: 2, mb: 10, p: 2, background: "#ffffff", fontSize:'10px' }}
          density="standard"
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            total_items_count: false,
            id: false,
            total_rejected_qty: false,
            username: false,
          }}
          rowHeight={30}
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 50 },
            { field: "dc_number", hideable: true, headerName: "DC#", width:'80',
               renderCell: (params) => <Link to={`/admin/dc/view/${params.row.id}`}>{params.row.dc_number}</Link>

          },
            { field: "po_number", hideable: true, headerName: "PO#" },
            { field: "ref_number", hideable: true, headerName: "Ref#" },
            { field: "quote_number", hideable: true, headerName: "Quote#" },
            {
              field: "dc_date",
              hideable: true,
              headerName: "Create at",
              valueGetter: ({ value }) =>
                value && moment(value).format("YYYY-MM-DD"),
            },
            { field: "username", hideable: true, headerName: "Create By" },
            {
              field: "dc_to_company",
              hideable: false,
              headerName: "Company",
              width: 220,
              renderCell: (params) => <Link target="_blank" to={`/admin/company/view/${params.row.company_id}`}>{params.row.dc_to_company}</Link>
            },
            {
              field: "total_items_count",
              hideable: true,
              headerName: "Items",
              width: 40,
            },
            {
              field: "total_rejected_qty",
              hideable: true,
              headerName: "Items",
              width: 40,
            },
            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/dc/view/${GridRowParams.id}`}>Edit</Link>,
              ],
            },
          ]}
          rows={Dc}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/dc/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
