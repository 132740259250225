import React, { useEffect } from "react";
import "./../admin/create.css";
import { Button, Input, Modal, Checkbox, Form } from "semantic-ui-react";
import magento from "../api/magento";
import { Grid, TextField, Typography } from "@mui/material";
import { FormattingNumber } from "../Helper/Helper";


const QuoteIntoSo = ({ data, navigate }) => {
  const [open, setOpen] = React.useState(false);
  // const [ConvertinData, setConvertinData] = React.useState({
  //   Items: data.Items,
  //   checked: false,
  // });
  const [message, setMessage] = React.useState({
    message: "",
    status: "",
  });
  const InvItems = data.Items
  const [SelectedItems, setSelectedItems] = React.useState([]);
  const [InvData, setInvData] = React.useState({
    SubTotal: 0,
    additional_charges: 0,
    discount: 0,
    GSTTotal: 0,
    GrandTotalGST: 0,
    gstinpercentage: data.Data[0].gstinpercentage,
    Selected: [],
    Items:data.Items,
    checked: true
  });


  const [checked, setChecked] = React.useState([]);
  
  const handleChecked = (e, index, it) => {
    let prev = checked;
    let I = SelectedItems;
    let itemIndex = I.indexOf(    );
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
      I.splice(itemIndex,1)
    } else {
      prev.push(itemIndex);
      I.splice(itemIndex,0,it)
      // I.push([index]= it)
    }
    setChecked([...prev]);
    setSelectedItems([...I]);
    console.log(prev, 'test new', SelectedItems)
    CalulateTotal(SelectedItems,it.id)
    
  };

  
  const HandleCheckBox = (itemData, array, index) => {
    console.log(itemData.id, itemData.checked)
    itemData.checked = !itemData.checked;
    itemData.checked === false
    ? array = array.splice((i) => i.id === itemData.id,1)
    : array = array.push(itemData);
    console.log("list length", array)
    
    //  CalulateTotal(array,itemData.id, itemData.checked)
     return itemData;
  };

  const ConvertIntoInv = async (vdata, itemsData) => {
    console.log(vdata);
    if (itemsData.length == 0) {
      setMessage({
        message:
          "Please select at least one item to create new delivery challan",
        status: "error",
      });
    } else {
      setMessage({
        message: "",
        status: "",
      });
      await magento
        .post(
          // "https://webapi.automarts.pk/quotations/convert/dc",
          "http://localhost:3001/quotations/convert/so",
          {  data, InvData },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.insertId);
          // navigate("/admin/invoice/view/" + response.data.insertId);
          // setData(response.data);
          // return data;
        })
        .catch((error) => {
          console.log(error);
        });
      // navigate("/admin/dashboard");
    }
  };


   // ** Calculate Total */
  const CalulateTotal = (
    idata = InvData.Selected,
    iid= 0,
    c= "add",
  ) => {
    let SubTotal = 0 ;
    let CostTotal= 0;
    let GSTTotal= 0 ;
    let total_items_qty= 0;
    console.log(idata.length , InvData.Items)
    if (idata.length > 0) {
      SubTotal = idata.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = idata.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = idata.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );

      total_items_qty = idata.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_qty),
        0
      );
     

        setInvData({
          ...InvData,
          item_count_total: InvData.length,
          GSTTotal: parseInt(GSTTotal),
          SubTotal: parseInt(SubTotal),
          GrandTotal: parseInt(SubTotal),
          GrandTotalGST: parseInt(SubTotal) + parseInt(GSTTotal),
          CostTotal: CostTotal,
          total_items_qty: total_items_qty,

        });
      } else {
        let gdtotal = parseInt(InvData.SubTotal) 
        setInvData({
          ...InvData,
          GrandTotal: parseInt(gdtotal),
          GrandTotalGST: parseInt(InvData.GSTTotal) + parseInt(InvData.SubTotal),
        });
      }
      return InvData;
    
  }

  useEffect(() => {
    CalulateTotal(InvData.Selected)
  },[])


  return (
    <>
      <Modal
        // size="fullscreen"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        centered={false}
        trigger={
          <Button align="center" className="ui primary tiny" color="black">
            Create SO
          </Button>
        }
      >
        <Modal.Header>
          Converting Quote# {data.Data[0].quote_number} into SO#{" "}
          {data.channel[0].so_series}
        </Modal.Header>

        <Modal.Content>
          <>
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  required
                  fullWidth
                  id="po_number"
                  label="PO Number"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(data.po_number);
                    data.po_number = e.target.value;
                    return data;
                  }}
                />
              </Grid>
            </Grid>
            <h4>Please check items qty to add into SO </h4>
            <table className="ui celled table">
              <thead>
                <tr>
                  <th></th>
                  <th>Code</th>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>GST</th>
                  <th>Row Total</th>
                </tr>
              </thead>
              <tbody>
                {InvData.Items.map((item,index) => {
                  // let v = checkSku(item.sku);
                  
                  // console.log(InvData.Selected);
                  return (
                    <>
                      {item.item_invoiced_qty !== item.item_qty ? (
                        <tr key={index}>
                          <td>
                                
                            <Checkbox
                              value="1"
                              defaultChecked={true}
                              
                              // onClick={() => {
                              //   InvData.length === 0
                              //     ? setBtnActive(true)
                              //     : setBtnActive(false);

                              //   // return InvData;
                              // }}
                              
                                type="checkbox"
                                id={`custom-checkbox-${index}`}
                                 checked={checked.includes(index)}
                                 onChange={(e) => handleChecked(e, index, item)}
                              // onChange={(index) => HandleCheckBox(item, InvItems,index)}
                            />
                          </td>
                          <td data-label="partno">{ item.item_code}</td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{FormattingNumber(item.item_price)}.00</td>
                          <td data-label="itemQty">{FormattingNumber(item.item_gst_total)}.00</td>
                          <td data-label="itemQty">{FormattingNumber(item.item_row_total_gst)}.00</td>
                         
                        </tr>
                      ) : (
                        <tr key={item.id} className="disabled ">
                          <td>
                            <Checkbox checked />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_dc_qty}</td>
                          <td data-label="DcQty" width="20%" colSpan={2}>
                            <strong>
                              All Quantity of Item is under Delivery Challan
                            </strong>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                placeholder="Please enter remarks"
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                rows={2}
                value={InvData.remarks}
                onChange={(e) => {
                  // InvData.remarks = e.target.value;
                  return data;
                }}
              />
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {InvData.SubTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  {data.Data[0].tax_type} ({data.Data[0].gstinpercentage})
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {InvData.GSTTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h5">
                  {InvData.GrandTotalGST}
                </Typography>
              </Grid>
            </Grid>
          </>
        </Modal.Content>
        <Modal.Actions>
          <Form onSubmit={() => ConvertIntoInv(InvData, InvData.Selected)}>
            <Button
              type="submit"
              content="Please Proceed!"
              labelPosition="right"
              icon="checkmark"
              positive
            />
          </Form>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default QuoteIntoSo;
