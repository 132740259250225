import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FormattingNumber } from "../../Helper/Helper";
import SubNavBar from "../../components/SubNavBar";
import moment from "moment";
import magento from "../../api/magento";

export default function So({ navigate, props }) {
  const [Quotation, setQuotation] = useState([]);
  const [FRow, setFRow] = useState({});
  const [CurrentPage, setCurrentPage] = useState(1);
  const [ItemPerPage, setItemPerPage] = useState(15);

  const location = useLocation();
  const message = !location.state ? "" : location.state.message;

  var IndexOfLastItem = CurrentPage * ItemPerPage;
  var IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
  var CurrentItems = Quotation.slice(IndexOfFirstItem, IndexOfLastItem);

  const handlePaginationChange = (e, { activePage }) => {
    IndexOfLastItem = activePage * ItemPerPage;
    IndexOfFirstItem = IndexOfLastItem - ItemPerPage;
    CurrentItems = Quotation.slice(IndexOfFirstItem, IndexOfLastItem);
    setCurrentPage(activePage);
  };

  const CallQuotation = async () => {
    await magento
      .get(
        "/so/channel/" +
          localStorage.getItem("channel"),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setQuotation(response.data);
        setFRow(response.data);
        return Quotation;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  const SearchQuote = async (quoteNo) => {
    await magento
      .get(
        "https://webapi.automarts.pk/search/quote/channel/" +
          localStorage.getItem("channel") +
          "/" +
          quoteNo,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setQuotation(response.data);
        return Quotation;
      })
      .catch(function (error) {
        console.log("Error: ", error.message);
      });
  };

  useEffect(() => {
    CallQuotation();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Sales Orders (SO)
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowHeight={30}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            created_by: false,
            id: false,
            // grand_total_gst: false,
          }}
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          components={{ Toolbar: GridToolbar }}
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 20 },
            {
              field: "so_number",
              hideable: true,
              headerName: "SO#",
              width:'80',
              renderCell: (params) => <Link to={`/admin/so/view/${params.row.id}`}>{params.row.so_number}</Link>
            },
            {
              field: "status",
              hideable: true,
              headerName: "Status",
              width: 80,
            },
            {
              field: "po_number",
              hideable: true,
              headerName: "Ref#",
              width: 100,
            },
            {
              field: "ref_number",
              hideable: true,
              headerName: "Ref#",
              width: 100,
            },
           
            { field: "created_by", hideable: true, headerName: "Create By" },
            {
              field: "so_to_company",
              hideable: true,
              headerName: "Company",
              width: 200,
              renderCell: (params) => <Link target="_blank" to={`/admin/company/view/${params.row.company_id}`}>{params.row.so_to_company}</Link>,
            },
            {
              field: "create_at",
              hideable: true,
              headerName: "Create at",
              width: 80,
              valueGetter: ({ value }) =>
                value && moment(value).format("YYYY-MM-DD"),
            },
            {
              field: "total_items_count",
              hideable: true,
              headerName: "Items",
              width: 40,
            },
            {
              field: "items_left",
              hideable: true,
              headerName: "Left",
              width: 40,
            },
            {
              field: "grand_total_gst",
              hideable: true,
              headerName: "Grand Total",
              width: 100,
              renderCell: (params) => FormattingNumber(params.row.grand_total_gst) + '.00'
            },
            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/so/view/${GridRowParams.id}`}>
                  View
                </Link>,
              ],
            },
          ]}
          rows={Quotation}
          pageSize="50"
        />

        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/so/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
