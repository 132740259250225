import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import SideNav from "./../SideNav";

const Layout = () => {
  return (
    <>
      <div>
        <Grid width="16" stretched>
          <Grid.Row stretched>
            <Grid.Column width="3">
              <SideNav />
            </Grid.Column>
            <Grid.Column width="13">
              <Outlet />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
export default Layout;
