import React from "react";
import "./../admin/create.css";
import { Button, Input, Modal, Checkbox, Form } from "semantic-ui-react";
import magento from "../api/magento";
import { Grid, TextField, Typography } from "@mui/material";

const QuoteIntoInv = ({ data, navigate }) => {
  const [open, setOpen] = React.useState(false);
  const [ConvertinData, setConvertinData] = React.useState({
    Items: data.Items,
    checked: true,
  });
  const [message, setMessage] = React.useState({
    message: "",
    status: "",
  });

  const InvItems = [];
  const InvData = {
    SubTotal: 0,
    additional_charges: 0,
    discount: 0,
    GSTTotal: 0,
    GrandTotalGST: 0,
  };
  // const [InvData, setInvData] = React.useSate([
  //   {
  //     SubTotal: 0,
  //     GrandTotal: 0,
  //     GrandTotalGST: 0,
  //     CostTotal: 0,
  //     GSTTotal: 0,
  //     addtionalCharges: 0,
  //     discount: 0,
  //     terms: "- Prices in this quotation are valid for 7 days.",
  //     remarks: "",
  //   },
  // ]);

  const HandleCheckBox = (itemData, array) => {
    itemData.checked = !itemData.checked;
    itemData.checked !== false
      ? InvItems.splice((i) => i.id !== itemData.id, 1)
      : InvItems.push(itemData);

    console.log(array);
    return;
  };

  const ConvertIntoInv = async (vdata, itemsData) => {
    console.log(vdata);
    if (itemsData.length == 0) {
      setMessage({
        message:
          "Please select at least one item to create new delivery challan",
        status: "error",
      });
    } else {
      setMessage({
        message: "",
        status: "",
      });
      await magento
        .post(
          // "https://webapi.automarts.pk/quotations/convert/dc",
          "http://localhost:3001/quotations/convert/invoice",
          { InvItems, data, InvData },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.insertId);
          // navigate("/admin/invoice/view/" + response.data.insertId);
          // setData(response.data);
          // return data;
        })
        .catch((error) => {
          console.log(error);
        });
      // navigate("/admin/dashboard");
    }
  };

  return (
    <>
      <Modal
        // size="fullscreen"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        centered={false}
        trigger={
          <Button align="center" className="ui primary tiny" color="black">
            INV Convert
          </Button>
        }
      >
        <Modal.Header>
          Converting Quote# {data.Data[0].quote_number} into Invoice#{" "}
          {data.channel[0].invoice_series}
        </Modal.Header>

        <Modal.Content>
          <>
            <h4>Add Invoice Information</h4>

            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  required
                  fullWidth
                  id="po_number"
                  label="PO Number"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(data.po_number);
                    data.po_number = e.target.value;
                    return data;
                  }}
                />
              </Grid>
            </Grid>
            <h4>Please check items qty to add into Invoice </h4>
            <table className="ui celled table">
              <thead>
                <tr>
                  <th></th>
                  <th>Product</th>
                  <th>Code</th>
                  <th>Qty</th>
                  <th>Invoiced</th>
                  <th>Left</th>
                  <th>Add Qty</th>
                </tr>
              </thead>
              <tbody>
                {ConvertinData.Items.map((item) => {
                  // let v = checkSku(item.sku);
                  item.checked = ConvertinData.checked;
                  item.addedToInv = item.item_qty - item.item_invoiced_qty;
                  console.log(ConvertinData.DCItems);
                  return (
                    <>
                      {item.item_invoiced_qty !== item.item_qty ? (
                        <tr key={item.id}>
                          <td>
                            <Checkbox
                              value="1"
                              // onClick={() => {
                              //   InvData.length === 0
                              //     ? setBtnActive(true)
                              //     : setBtnActive(false);

                              //   // return InvData;
                              // }}
                              onChange={() => {
                                HandleCheckBox(item, InvItems);
                                // console.log(InvData);
                              }}
                            />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_invoiced_qty}</td>
                          <td data-label="itemQty">{item.item_left_invoice}</td>
                          <td data-label="DcQty" width="20%">
                            <Input
                              size="13"
                              max={item.item_left_invoice}
                              min="1"
                              defaultValue={item.item_left_invoice}
                              type="number"
                              onChange={(e) => {
                                item.addedToInv = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr key={item.id} className="disabled ">
                          <td>
                            <Checkbox checked />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_dc_qty}</td>
                          <td data-label="DcQty" width="20%" colSpan={2}>
                            <strong>
                              All Quantity of Item is under Delivery Challan
                            </strong>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              <TextField
                fullWidth
                id="terms"
                label="Terms & Conditions"
                placeholder="Please enter Terms & Conditions"
                multiline
                variant="outlined"
                rows={6}
                value={InvData.terms}
                onChange={(e) => {
                  // InvData[0].terms = e.target.value;
                  return data;
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="remarks"
                label="Remarks"
                placeholder="Please enter remarks"
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                rows={2}
                value={InvData.remarks}
                onChange={(e) => {
                  // InvData.remarks = e.target.value;
                  return data;
                }}
              />
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid textAlign={"left"} md={12} sx={{ pb: 2 }}>
                <TextField
                  fullWidth
                  id="discount"
                  label="Discount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    // InvData.discount = e.target.value;
                    return data;
                  }}
                />
              </Grid>
              <Grid textAlign={"left"} md={12}>
                <TextField
                  fullWidth
                  id="additional_charges"
                  label="Additonal Charges"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    // InvData.additional_charges = e.target.value;
                    return data;
                  }}
                />
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {InvData.SubTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Taxes
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {InvData.GSTTotal}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h5">
                  {InvData.GrandTotalGST}
                </Typography>
              </Grid>
            </Grid>
          </>
        </Modal.Content>
        <Modal.Actions>
          <Form onSubmit={() => ConvertIntoInv(InvData, InvItems)}>
            <Button
              type="submit"
              content="Please Proceed!"
              labelPosition="right"
              icon="checkmark"
              positive
            />
          </Form>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default QuoteIntoInv;
