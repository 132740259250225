import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation, useParams } from "react-router-dom";
import InvoicesReportsComp from "../../components/pdf/Reports/InvoicesReport";

export const ReportsPrint = ({  }) => {
    const location = useLocation();
    const {type} = useParams();
    const [data, setData] = useState({Calc: location.state.rdata, records: location.state.Reports, period: location.state.period, type});
    
    useEffect(() => {
        
    
    },[]);
    return (
        <PDFViewer width="100%" height="1000px">
            <InvoicesReportsComp data={{data}} />
        </PDFViewer>
      );
}

