import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ArrowBack } from "@mui/icons-material";

const DisplayLayout = ({ children, data, navigate, headdetails }) => {
  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            {headdetails()}
            <Button
              variant="outlined"
              endIcon={<ArrowBack />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Toolbar>
        </AppBar>

        {children}
      </Box>
    </>
  );
};
export default DisplayLayout;
