import React from "react";
import FileUpload from "../../components/FileUpload";

export default function MediaUpload({ navigate }) {
  return (
    <>
      <FileUpload />
    </>
  );
}
