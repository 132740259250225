import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
  Line,
  Rect,
  // Font,
} from "@react-pdf/renderer";
// import logo from "./../admin/order/ZA-Autos-logo2.jpg";
// import logo from "./../admin/order/logo.png";

import moment from "moment/moment";
// Font.register({
//   family: "RobotoBold",
//   fontWeight: "900",
//   src: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900",
// });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9Regular' });
// Font.register('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap', { fontFamily: 'Roboto-Bold' });

const tbDivider = (offset, offsetTop = 0 ) => {
  return (
    <Svg
      height="100%"
      width="2"
      style={{ position: "absolute", left: offset, top: offsetTop }}
      fixed
    >
      <Line
        style={{}}
        x1="0px"
        y1="0px"
        x2="0px"
        y2="520px"
        
        strokeWidth={1}
        stroke="#ccc"
      />
    </Svg>
  );
}; 

const tbDividerHor = (offset, offsetTop = 0 ) => {
  return (
    <Svg
      height="3"
      width="100%"
      style={{ position: "absolute", left: offset, top: offsetTop }}
      fixed
    >
      <Line
        style={{}}
        x1="0px"
        y1="0px"
        x2="564px"
        y2="0px"
        // style={{ position: "absolute" }}
        strokeWidth={1}
        stroke="#ccc"
      />
    </Svg>
  );
}; 
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 180,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {

    paddingVertical: 10,
    width: "100%",
    height: "1.5in",
  },
  topSection: {
    padding: 15,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },
  MainHeading: {
    fontSize: 23,
    fontWeight: "700",
    textAlign: "right",
    marginLeft: 170,
    lineHeight:1.2,
    textTransform:'uppercase'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    right: "3%",
    top: "12pt",
  },
  MainText: {
    fontSize: 9,
    marginVertical: 2,
    lineHeight:1.2
  },
  SubHeading: {
    fontSize: "9pt",
    marginTop: 5,
    textAlign: "left",
    marginLeft: 170,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 1.3,
    fontSize:8,
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 8,
                marginRight:5,
                width: "61%",
                height: 80,
                minHeight: 60,
                backgroundColor:'#fff',
  },
  ShippingAddress: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    backgroundColor:'#fff',
    
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                paddingHorizontal:8,
                paddingVertical:5,
                marginLeft:10,
                width: "61%",
                height: 80,
                minHeight: 60,
  },    
  BillingAddress: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 8,
                marginRight:10,
                width: "61%",
                height: 80,
                minHeight: 60,
  },
  logo: {
    position:'absolute',
    width: "100%",
    left:'-30px',
    top:"-20px",
    minHeight: 90,
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  stamp: {
    position:'absolute',
    width: "55%",
    right:'-20px',
    top:"-25px",
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  signature: {
    position:'absolute',
    width: "55%",
    right:'30px',
    bottom:"0px",
    
    marginLeft: 0,
    marginTop: 0,
    transform:'scale(0.7)'
  },
  grid: {
    width: "100%",
    marginTop: 5,
    marginHorizontal: 15,
  },
 
  GridHeader: {
    // overflow: "hidden",
    width: "100%",
    display: "flex",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    border: 1,
    borderStyle: "solid",
    borderColor: "#ccc",
    minHeight: "25px",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    paddingVertical: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#ccc",
    // borderBottomLeftRadius: 5,
    // borderBottomRightRadius: 5,
    height: 10,
  },
  GridProductsItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    // borderBottomWidth: 1,
    // borderBottomStyle: "solid",
    // borderBottomColor: "#ccc",
  },
  InvoiceNumber: {
    fontSize: 12,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 9,
    textAlign: "left",
    alignSelf: "left",
  },
  gridText: {
    fontSize: 9,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
  },
  productSno: {
    flex: 0.6,
    padding: 5,
    height: "100%",
  },
  productTitle: {
    flex: 10,
    padding: 5,
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
  },
  productPart: {
    flex: 2.5,
    padding: 5,
    height: "100%",
  },
  productQty: {
    flex: 1.2,
    padding: 5,
    height: "100%",
  },
  productMeasure: {
    flex: 1.2,
    padding: 5,

    height: "100%",
  },
  productPrice: {
    flex: 3,
    padding: 5,
    height: "100%",
  },
  producctTotal: {
    flex: 3,
    padding: 5,
    height: "100%",
  },
  productGrid: {
    padding: 5,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#ccc",
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  productNote: {
    padding: 5,
    flex: 9,
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 9,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 9,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 10,
    textAlign: "right",
    textTransform: "uppercase",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
    textAlign: "center",
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
    textAlign: "center",
  },
      mainhead2: {
    fontSize: "12pt",
  },
});

// Create Document Component
const   CreateDcPDF = ({ data, type }) => {
  return (
    // <PDFViewer width="100%" height="100%">
    <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="ZA Autos"
      title={type}
      keywords="ZA Autos"
      subject={type}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pagewrapper} fixed>
          <View style={styles.topSection}>
            <View style={styles.HeadingWrapper}>
              {data.channel && (
                <Image
                  style={styles.logo}
                  source={{
                    uri:
                      "https://accounting.automarts.pk/assets/products/" +
                      data.channel[0].logo,
                    method: "GET",
                    headers: {},
                    body: "",
                  }}
                />
              )}
              {/* <Image style={styles.logo} src={logo} /> */}
            </View>
            <Svg viewBox="0 0 720 300" style={{transform:'rotate(-25deg)', position:'absolute',top:-140, left:-90, zIndex:-1}}>

                <Rect
                  width="900"
                  height="600"
                  fill="#DA1F2D"
                />
                </Svg>
            <View style={styles.InfoWrapper}>
              <Text style={styles.MainHeading}>Delivery Challan</Text>
              
              <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  {data.channel && data.channel[0].address}{", "} 
                </Text>
                <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  STN: {data.channel && data.channel[0].stn}{", "}NTN: {data.channel && data.channel[0].ntn}
                </Text>
                {/* <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  Email: {data.channel && data.channel[0].email}
                </Text> */}
                {/* <Text style={{ fontSize: 8, lineHeight:1.5, }}>
                  Phone: {data.channel && data.channel[0].phone}
                  
                </Text>
            */}

            </View>
            <View style={styles.InfoWrapper}>
              <Text style={styles.MainHeading}>Delivery Challan</Text>
             
            </View>
          </View>
        </View>
        <View style={styles.CustomerInfo} fixed>
          <View style={styles.InfWrapper}>
            <Text style={styles.MainText}>
              {data.Data && data.Data[0].dc_to_company}
            </Text>
            <Text style={styles.MainText}>
              {data.Data && data.Data[0].dc_to}
            </Text>
            <Text style={styles.MainText}>
              STN: {data.Data && data.Data[0].dc_to_stn}
            </Text>
            <Text style={styles.MainText}>
              NTN: {data.Data && data.Data[0].dc_to_ntn}
            </Text>
            <Text style={styles.MainText}>
              Phone:{data.Data && data.Data[0].dc_to_phone}
            </Text>
          </View>
          <View style={styles.BillingAddress}>
          <Text style={styles.MainText}>
                Date: &nbsp; &nbsp; &nbsp; &nbsp;
                {data.Data &&
                  moment(data.Data[0].dc_date).format("DD/MM/YYYY")}
              </Text>
              <Text style={styles.MainText}>
                DC#:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {data.Data && data.Data[0].dc_number}    
              </Text>
              <Text style={styles.MainText}>
                { (data.Data[0].po_number !== "" || data.Data[0].po_number !== null)
                  ? "PO#: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data.Data[0].po_number   
                  : data.Data && data.Data[0].ref_number !== "" 
                  ? "Ref#: " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    " " +
                    data.Data[0].ref_number
                  : " "}
              </Text>
          </View>
        </View>
        <View style={[styles.grid,{minHeight:'440pt'}]}>
          {tbDividerHor(2,'440pt')}
          {tbDivider(0, 5)}
          {tbDivider(30)}
          {tbDivider(120)}
          {tbDivider(470)}
          {tbDivider(510)}
          {tbDivider(564, 5)}
          <View style={styles.GridHeader} fixed>
            <View style={styles.productSno}>
              <Text style={styles.gridHeading}>Sno.</Text>
            </View>
            <View style={styles.productPart}>
              <Text style={styles.gridHeading}>Item Code</Text>
            </View>
            <View style={styles.productTitle}>
              <Text style={styles.gridHeading}>Description</Text>
            </View>

            <View style={styles.productQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.productMeasure}>
              <Text style={styles.priceCenter}>Unit of Measure</Text>
            </View>
          </View>
          {data.Items &&
            data.Items.map((item, index) => {
              return (
                <View style={styles.GridProductsItems}>
                  <View style={styles.productSno}>
                    <Text style={styles.priceCenter}>{index + 1}</Text>
                  </View>
                  <View style={styles.productPart}>
                    <Text style={styles.gridText}>{item.item_code}</Text>
                  </View>

                  <View style={styles.productTitle}>
                    <Text style={styles.gridText}>{item.item_title}</Text>
                    <Text style={styles.smallText}></Text>
                  </View>

                  <View style={styles.productQty}>
                    <Text style={styles.priceCenter}>{item.item_qty}</Text>
                  </View>
                  <View style={styles.productMeasure}>
                    <Text style={styles.priceCenter}>
                      {item.measure_of_unit}
                    </Text>
                  </View>
                </View>
              );
            })}
          {/* <View style={styles.GridProducts}>
            <View style={styles.productSno}>
              <Text style={styles.gridText}>{}</Text>
            </View>
            <View style={styles.productPart}>
              <Text style={styles.gridText}>{}</Text>
            </View>

            <View style={styles.productTitle}>
              <Text style={styles.gridText}>{}</Text>
            </View>

            <View style={styles.productQty}>
              <Text style={styles.priceRight}></Text>
            </View>
            <View style={styles.productMeasure}>
              <Text style={styles.priceRight}></Text>
            </View>
          </View> */}
        </View>
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingHorizontal: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: 30,
            bottom: 7,
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 15,
            }}
          >
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "60%",
                height: 46,
                marginBottom: 5,
                minHeight: 51,
              }}
            >
              <View style={{ height: 20, minHeight: 30 }}>
                {data.Data && (<>
                  <Text style={{ fontSize: 7.5, color: "#333" }}>Remarks:</Text>
                  <Text style={{ fontSize: 7.5, color: "#333" }}> {data.Data && data.Data[0].remarks}</Text>
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                width: "39%",
                marginLeft: "1%",
              }}
            >
              <View
                style={{
                  height: 23,
                  minHeight: 23,
                  borderColor: "#ccc",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                
              </View>
              <View
                style={{
                  height: 23,
                  minHeight: 23,
                  borderColor: "#ccc",
                  borderRadius: 5,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingHorizontal: 20,
            }}
          >
            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                width: "61%",
                height: 80,

                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75 }}>
                {data.Data && (<>
                  <Text style={{ fontSize: 8.5, color: "#333" }}>Terms & Conditions:</Text>
                  <Text style={{ fontSize: 8.5, color: "#333" }}> {data.Data && data.Data[0].terms}</Text>
                  </>
                )}
              </View>
            </View>

            <View
              style={{
                borderColor: "#ccc",
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 10,
                width: "40%",
                height: 80,
                marginLeft: "1%",
                minHeight: 60,
              }}
            >
              <View style={{ height: 150, minHeight: 75 }}>
                <Text style={{ fontSize: 10, marginBottom: 5 }}>
                  For {data.channel && data.channel[0].channel}
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  Authorized Signatory:
                </Text>
                <Text style={{ fontSize: 8, marginBottom: 5 }}>
                  {data.Data && data.Data[0].name}
                </Text>
                <Text style={{ fontSize: 6, marginBottom: 5 }}>
                  {data.Data && data.Data[0].role}
                </Text>
                
              </View>
            </View>
          </View>
        </View>
      
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default CreateDcPDF;
