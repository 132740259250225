import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./dashboard.css";
import { AuthContext } from "../Context/AuthContext";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TableBody,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  Article,
  AttachMoney,
  BusinessOutlined,
  DescriptionOutlined,
  DescriptionSharp,
  DoNotDisturb,
  ListAltOutlined,
  MenuOutlined,
  MultipleStop,
  Person,
  Receipt,
  Settings,
} from "@mui/icons-material";
import magento from "../api/magento";
import moment from "moment";
import CurrencyConvertor from "../components/CurrencyConvertor";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Magento = ({ navigate }) => {
  const profile = JSON.parse(localStorage.getItem("channelDetail"));
  const taxes = JSON.parse(localStorage.getItem("taxes"));
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(
    JSON.parse(sessionStorage.getItem("channelDetail")),
    "dashboard call"
  );
  const { useAuth } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);

  const handleClick = (event, menu = event.currentTarget.id) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setMenu([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const DataCall = async () => {
    await magento
      .get("https://webapi.automarts.pk/data/channel/" + profile[0].id, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        return data;
      })
      .catch((error) => console.log("Error: ", error.message));
  };

  const newMenu = (men) => {
    setMenu(men);
    return menu;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  React.useEffect(() => {
    return DataCall();
  }, []);
  return (
    <div>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="primary"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Magento
            </Typography>
          </Toolbar>
        </AppBar>
        <Popover
          id={id}
          open={open}
          sx={{ p: 1 }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menu.map((item) => (
            <Box width={"200px"} sx={{ px: 1, py: 1 }}>
              <Link to={item.url}>
                <Typography width>{item.title}</Typography>
              </Link>
            </Box>
          ))}
        </Popover>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Paper sx={{ p: 2, px: "7%" }}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Magento Tasks
              </Typography>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="mproduct"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "Add New Product",
                        url: "/admin/mproducts/create",
                      },
                      { title: "Product List", url: "/admin/mproducts" },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <ListAltOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Products
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={() => navigate("/admin/settings")}
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Settings fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Settings
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="taxes"
                  onClick={() => navigate("/admin/taxes")}
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AccountBalanceWalletOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Tax
                  </Typography>
                </Paper>
              </Box>
              <Divider />
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="quotations"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "New Quotation",
                        url: "/admin/quotation/create",
                      },
                      {
                        title: "View & Edit Quotation",
                        url: "/admin/quotation",
                      },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionSharp fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Quotations
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="dc"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "Edit & View Delivery Challan",
                        url: "/admin/delivery-challans",
                      },
                      {
                        title: "New Delivery Challan",
                        url: "/admin/dc/create",
                      },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DescriptionOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    DC
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "Edit & View Rejections",
                        url: "/admin/rejections",
                      },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Rejections
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="invoice"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "Edit & View Invoice", url: "/admin/invoices" },
                      { title: "New Invoice", url: "/admin/invoice/create" },
                    ])
                  }
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Receipt fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Sales Invoice
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="bank_deposit"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AccountBalanceWallet fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Finance
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="recive_money"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AttachMoney fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Receive
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  variant="elevation"
                  elevation={0}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "none",
                  }}
                ></Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  variant="elevation"
                  elevation={0}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "none",
                  }}
                ></Paper>
                <Paper
                  aria-describedby={id}
                  id="bank_deposit"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <AccountBalance fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Deposits
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <Article fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Statements
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <MultipleStop fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Cdt. Returns
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  elevation={3}
                  style={{
                    display: "flex",
                    padding: "5px",
                    flexDirection: "column",
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    alignItems: "end",
                    justifyContent: "space-between",
                    background: "#f5f5f5",
                  }}
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Reports
                  </Typography>
                </Paper>
              </Box>
            </Paper>
          </Grid>
          <Grid
            xs={2}
            sm={4}
            md={8}
            sx={{ p: 3, my: 0, py: 3, display: "flex", flexWrap: "wrap" }}
            spacing={{ xs: 1, md: 3 }}
          >
            <Grid item sm={4} md={4}>
              <Paper sx={{ p: 2, mr: 2 }}>
                <Typography variant="h6">Today USD Rate</Typography>
                <CurrencyConvertor CurrFrom="usd" />
              </Paper>
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Paper sx={{ p: 2, mr: 2 }}>
                <Typography variant="h6">Today AED Rate</Typography>
                <CurrencyConvertor CurrFrom="aed" />
              </Paper>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Today GBP Rate</Typography>
                <CurrencyConvertor CurrFrom="gbp" />
              </Paper>
            </Grid>

            <Box sx={{ bgcolor: "background.paper", width: "100%", mx: 0 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="#000000"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={"Recent Quotation"} />
                <Tab label={"Recent DC"} />
                <Tab label={"Recent Invoices"} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <TableContainer item component={Paper} fluid sx={{}}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Created at</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Items Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.Quote.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/admin/quotation/view/${row.id}`}>
                                {row.quote_number}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.create_at).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell width={200}>
                              <Link
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.quote_to_company}
                              </Link>
                            </TableCell>
                            <TableCell>{row.total_items_count}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer component={Paper} fluid>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DC#</TableCell>
                        <TableCell>PO#</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Item Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.DC.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="left">
                              <Link to={`/admin/dc/view/${row.id}`}>
                                {row.dc_number}
                              </Link>
                            </TableCell>
                            <TableCell>{row.po_number}</TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell>
                              {row.quote_id !== 0 ? (
                                <>
                                  <Link
                                    to={`/admin/quotation/view/${row.quote_id}`}
                                  >
                                    {row.quote_number}
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.create_at).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell width={200}>
                              <Link
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.dc_to_company}
                              </Link>
                            </TableCell>
                            <TableCell>{row.total_items_count}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableContainer component={Paper} fluid>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice#</TableCell>
                        <TableCell>PO#</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Company</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.Invoices.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/admin/invoice/view/${row.id}`}>
                                {row.invoice_number}
                              </Link>
                            </TableCell>
                            <TableCell>{row.po_number}</TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell>
                              <Link to={`/admin/quotation/view/${row.id}`}>
                                {row.quotation_number}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.invoice_create_at).format(
                                "DD-MM-YYYY"
                              )}
                            </TableCell>

                            <TableCell width={200}>
                              <Link
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.bill_to_company}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>{" "}
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={6}></Grid>
          <Grid item xs={2} sm={4} md={12}></Grid>
        </Grid>
      </Box>
      {useAuth()}
    </div>
  );
};

export default Magento;
