import {
  AccountBalanceWalletOutlined,
  AttachMoney,
  BusinessOutlined,
  DescriptionOutlined,
  DescriptionSharp,
  DoNotDisturb,
  DocumentScanner,
  Group,
  Person,
  PrecisionManufacturingOutlined,
  Receipt,
  StoreOutlined,
} from "@mui/icons-material";
import { Box, Paper, Popover, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function SubNavBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);

  const handleClick = (event, menu = event.currentTarget.id) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setMenu([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={id}
        open={open}
        sx={{ p: 1 }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menu.map((item) => (
          <Box width={"200px"} sx={{ px: 1, py: 1 }}>
            <Link to={item.url}>
              <Typography width>{item.title}</Typography>
            </Link>
          </Box>
        ))}
      </Popover>
      <Paper
        variant="outlined"
        sx={{
          mx: "2%",
          py: 1,
          borderRadius: 5,
          mb: 2,
          background: "none",
          border: "none",
          display: "flex",
        }}
      >
        <Paper
          id="quotations"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              {
                title: "New Quotation",
                url: "/admin/quotation/create",
              },
              {
                title: "View & Edit Quotation",
                url: "/admin/quotation",
              },
            ])
          }
        >
          <DescriptionSharp fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Quotes
          </Typography>
        </Paper>
        <Paper
          id="dcs"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              {
                title: "New Delivery Challan",
                url: "/admin/dc/create",
              },
              {
                title: "Edit & View Delivery Challan",
                url: "/admin/delivery-challans",
              },
             
            ])
          }
        >
          <DescriptionOutlined fontSize="large" />
          <Typography variant="small" fontSize={12}>
            DC
          </Typography>
        </Paper>
        <Paper
          id="invoice"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Invoice", url: "/admin/invoice/create" },
              { title: "Edit & View Invoice", url: "/admin/invoices" },
              { title: "Edit & View Sales Invoice", url: "/admin/invoices/Sales" },
              { title: "Edit & View Service Invoice", url: "/admin/invoices/Service" },
            ])
          }
        >
          <Receipt fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Invoices
          </Typography>
        </Paper>
        <Paper
          id="payments"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Invoice", url: "/admin/payment/create" },
              { title: "Edit & View Invoice", url: "/admin/payments" },
              
            ])
          }
        >
          <AttachMoney fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Payments
          </Typography>
        </Paper>
        <Paper
          id="rfqs"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              {
                title: "New RFQ/PR",
                url: "/admin/rfq/create",
              },
              {
                title: "View & Edit RFQ/PR",
                url: "/admin/rfqs",
              },
            ])
          }
        >
          <DocumentScanner fontSize="large" />
          <Typography variant="small" fontSize={12}>
            PR
          </Typography>
        </Paper>
        <Paper
          id="rejections"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "View Rejection", url: "/admin/rejections" },
            ])
          }
        >
          <DoNotDisturb fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Rejections
          </Typography>
        </Paper>
        <Paper
          id="taxes"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Taxes", url: "/admin/tax/create" },
              { title: "Edit & View Taxes", url: "/admin/taxes" },
            ])
          }
        >
          <AccountBalanceWalletOutlined fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Taxes
          </Typography>
        </Paper>
        <Paper
          id="company"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Company", url: "/admin/company/create" },
              { title: "Edit & View Company", url: "/admin/company" },
            ])
          }
        >
          <BusinessOutlined fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Company
          </Typography>
        </Paper>
        <Paper
          id="items"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Item", url: "/admin/item/create" },
              { title: "Edit & View Items", url: "/admin/item" },
            ])
          }
        >
          <PrecisionManufacturingOutlined fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Items
          </Typography>
        </Paper>
        <Paper
          id="vendors"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Vendor", url: "/admin/vendor/create" },
              { title: "Edit & View Vendor", url: "/admin/vendors" },
            ])
          }
        >
          <Group fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Vendor
          </Typography>
        </Paper>
        <Paper
          id="contact"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Contact Person", url: "/admin/person/create" },
              { title: "Edit & View Contact", url: "/admin/persons" },
            ])
          }
        >
          <Person fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Contact
          </Typography>
        </Paper>
        {/* <Paper
          id="profiles"
          elevation={1}
          style={{
            display: "flex",
            padding: "7px",
            flexDirection: "column",
            width: "60px",
            height: "60px",
            margin: 10,
            alignItems: "center",
            justifyContent: "space-between",
            background: "#fff",
          }}
          onClick={(e) =>
            handleClick(e, [
              { title: "New Channel", url: "/admin/channel/create" },
              { title: "Edit & View Channel", url: "/admin/channels" },
            ])
          }
        >
          <StoreOutlined fontSize="large" />
          <Typography variant="small" fontSize={12}>
            Channel
          </Typography> 
        </Paper>*/}
      </Paper>
    </>
  );
}
