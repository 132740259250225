import React, { useState, useEffect } from "react";
import "./../admin/create.css";
import { Button } from "semantic-ui-react";
import {
  Autocomplete,
  TextField,
  Typography,
  InputAdornment,
  Popover,
  Paper,
} from "@mui/material";
import { Box } from "@mui/system";
import { Add, BusinessOutlined, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
const CompanySelection = ({ data, selectionOnly = false, fnCall = () =>{}, edit=false, EditC = "", onChangeEdit=()=>{}}) => { 
  const [billToSelected, setbillToSelected] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = () => {
    return data.CompanyList.map((i) => {
      return { key: i.id, label: `${i.company}`, value: i.id };
  })
}

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelectChange = (e, sdata) => {
    
    if (sdata !== null) {
      data.CompanyList.map((ie) => {
        return ie.id === sdata.value ? setbillToSelected(ie) : "";
      });
      console.log(sdata, data.company_id, data);
    } else {
      setbillToSelected([]);
    }

    if( edit ){
      onChangeEdit();
    }

    return data;
  };

  useEffect(() => {
    data.billTo = billToSelected;
    fnCall(billToSelected);
    // console.log(billToSelected);
  }, [billToSelected]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {/* <Typography
          style={{}}
          variant="h6"
          textAlign="left"
          color={"#777"}
          fontSize={20}
        >
          To:
        </Typography> */}
        <Autocomplete
          disablePortal
          fullWidth
          id="company"
          options={options()}
          sx={{
            width: "90%",
            "& input": { width: "100%" },
            background: "#fff",
            marginRight: "15px",
          }}
          autoHighlight
          defaultValue={EditC}
          filterSelectedOptions
          getOptionSelected={(option, value) => option === value}
          onChange={(e, data) => handleSelectChange(e, data)}
          getOptionLabel={(option) => option.label ? option.label : EditC}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              startAdornment={
                <InputAdornment position="start">
                  <Typography>TO:</Typography>
                </InputAdornment>
              }
              variant="outlined"
              {...params}
              label="Select Company"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          loading
        />
        {selectionOnly === false ? <>
          <Button
          aria-describedby={id}
          variant="contained"
          size="small"
          onClick={handleClick}
          disabled={billToSelected.company ? false : true}
        >
          <BusinessOutlined />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper sx={{ padding: "15px" }}>
            {billToSelected && billToSelected.company !== "" ? (
              <>
                <Typography variant="h5" sx={{ marginBottom: 0 }}>
                  {billToSelected.company}
                </Typography>
              </>
            ) : (
              ""
            )}
            {billToSelected && billToSelected.stn !== "" ? (
              <>
                <Typography variant="p" marginBottom={0}>
                  STN#: {billToSelected.stn}
                </Typography>
                <br />
              </>
            ) : (
              ""
            )}
            {billToSelected && billToSelected.ntn !== "" ? (
              <>
                <Typography variant="p" marginBottom={0}>
                  NTN#:{billToSelected.ntn}
                </Typography>
                <br />
              </>
            ) : (
              ""
            )}

            {billToSelected && billToSelected.address !== "" ? (
              <>
                <Typography variant="p" marginBottom={0}>
                  {billToSelected.address}
                </Typography>
                <br />
              </>
            ) : (
              ""
            )}
            {billToSelected && billToSelected.phone !== "" ? (
              <>
                <Typography variant="p" marginBottom={0}>
                  Phone: {billToSelected.phone}
                </Typography>
                <br />
              </>
            ) : (
              ""
            )}
            {billToSelected && billToSelected.fax !== "" ? (
              <>
                <Typography variant="p" marginBottom={0}>
                  Fax: {billToSelected.fax}
                </Typography>
                <br />
              </>
            ) : (
              ""
            )}
          </Paper>
        </Popover>

        <Button
          ria-describedby={id}
          variant="contained"
          size="small"
          disabled={billToSelected.company ? false : true}
        >
          <Link
            to={"/admin/company/edit/" + billToSelected.id}
            style={{ color: "#000000" }}
          >
            <Edit />
          </Link>
        </Button>
        <Button ria-describedby={id} size="small" color="success">
          <Link to={"/admin/company/create"} style={{ color: "#000000" }}>
            <Add />
          </Link>
        </Button>
          </>
          :''
        }
      </div>
    </>
  );
};

export default CompanySelection;
