import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  // Font,
} from "@react-pdf/renderer";
import moment from "moment/moment";
import logo from "./../../../admin/order/ZA-Autos-logo2.jpg";
import {profile} from "./../../../Helper/Helper";

const styles = StyleSheet.create({
    body: {
        paddingBottom: 105,
        paddingHorizontal: 35,
        fontSize:'11px'
      },
      page: {
        padding:'40px', 
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize:'11px' 
        
      },
      logo: {
        width: "25%",
        height: "60%",
        minHeight: 100,
        marginLeft: 40,
        marginRight: "auto",
        marginTop: 30,
        
      },
      companyAddress:{
        margin:'30px',
         width:'300px',
         height:'180px',
         border: '1px solid #777',
         borderRadius: '5px',
         padding: '12px',
         display:'flex',
         flexWrap:'wrap',
         flexDirection:'row',
         fontSize:'13px',
      },
      companyDetailLabel: {
        width:'30%',
        marginBottom:'15px'
      },
      companyDetailInfo:{
        width:'70%',
        marginBottom:'15px'
        
      },
      footerDetail:{
        position:'absolute',
        width:'100%',
        borderTop:'1px solid #777',
        height:'30px',
        paddingTop:'10px',
        bottom:20,
        left: 32,
        right:'auto',
      }

});

const PrintEnvelope = ({ data, type }) => {
 
  console.log('New',data.formData && data.formData.Data[0].id);
    return (<>
    <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="Mohsin Ahmed"
      title={type}
      keywords=""
      subject={type}
    >
        
        <Page
        
        size={{
            // Printer Settings Width: 3.90 height: 6.90 OR 8.7 // Brown Envolope
            // Printer Settings Width: 3.   90 height: 6.90 // White Envolope
            width:"835.199",
            height:"374.4"

          }}
         style={styles.page} wrap orientation="portrait">
        <Image style={styles.logo} src={logo} />
        <View style={styles.companyAddress}>
            <Text style={styles.companyDetailLabel}>Company: </Text>
            <Text  style={styles.companyDetailInfo}>{data.formData && data.formData.company[0].company}</Text>
            <Text style={styles.companyDetailLabel}>Address: </Text>
            <Text  style={styles.companyDetailInfo}>{data.formData && data.formData.company[0].address}</Text>
            <Text style={styles.companyDetailLabel}>Att. To: </Text>
            <Text  style={styles.companyDetailInfo}>Mr. {data.formData && data.formData.Data[0].atten_name}  {data.formData && data.formData.Data[0].department}</Text>
            <Text style={styles.companyDetailLabel}>Subject: </Text>
            <Text  style={styles.companyDetailInfo}>{data.type.toUpperCase()} NO.{ data.formData && data.formData.Data[0].quote_number}</Text>
            <Text style={styles.companyDetailLabel}>Ref No.: </Text>
            <Text  style={styles.companyDetailInfo}>{ data.formData && data.formData.Data[0].ref_number}</Text>
        </View>
        <Line x1={100} x2={400} y1={100} y2={400} color='#000'/>
        <View style={styles.footerDetail}>
            <Text>Office Address: {profile[0].address}, &nbsp; &nbsp; &nbsp; Phone: {profile[0].phone} &nbsp; &nbsp; &nbsp; Email: {profile[0].email}</Text>

        </View>
        </Page>
    </Document>

    </>)
}

export default PrintEnvelope;