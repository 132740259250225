import { AppBar, Box, Button, CssBaseline, Divider, MenuItem, Paper, Select, Table, TableCell, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import "./settings.css";
import React, { useEffect } from "react";
import SubNavBar from "../../components/SubNavBar";
import magento from "../../api/magento";
import {profile} from './../../Helper/Helper'
import { useState } from "react";
import { Send } from "@mui/icons-material";
import NotificationMessage from "../../components/NotificationMessage";



export default function Settings({navigate}) {
  const [defaultSettings, setdefaultSettings] = useState()
  const [formData, setFormData] = useState([])
  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const CallSettings = async () =>{
    await magento.get(
      'https://webapi.automarts.pk/settings/' + profile[0].id,{
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      }
      ).then((response)=>{
      console.log(response.data)
      setFormData(response.data)
      return formData; 

    }).catch((error)=>{
      return error;
    })
  }

  const OnSubmit = async (data) =>{
    console.log('subumit log', data);
    await magento.post('https://webapi.automarts.pk/settings/edit/'+ profile[0].id ,data,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      }}
      ).then(
        (response) => {
          handleClickOpen();
          setmessage({
            message: "Setting has been Updated",
            status: "success",
          });
        }
      ).catch(
        (err) =>{
          console.log(err)
          handleClickOpen();
          setmessage({
            message: err,
            status: "error",
          });
        }
      )

  }
  
  useEffect(()=>{
    CallSettings();
  },[])
  return (
    
    <><Box bgcolor="#f9f9f9" fluid height="100vh">
    <CssBaseline />

    <AppBar
      position="static"
      color="default"
      enableColorOnDark
      sx={{ mb: 3 }}
    >
      <Toolbar sx={{ textAlign: "left" }}>
        <Typography
          variant="h4"
          noWrap
          component="div"
          sx={{ flexGrow: 1, justifyContent: "start" }}
        >
          <h1>Settings</h1>
        </Typography>
      </Toolbar>
    </AppBar>
    <NotificationMessage message={message} open={open} handleClose={handleClose} />
    <SubNavBar />
    <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Paper sx={{ mx: "2%", p: 2, width: "96%" }}>
          <Table size="small"
                  aria-label="a dense table"
                  padding="0">
                    <TableRow>
          <TableCell>
            <label>Templates (Invoice/Quotation/DC)</label>
          </TableCell>
          <TableCell>
             <Select
             defaultValue={formData.templates}
             
             sx={{width:'100%', height:"50px"}}>
                <option value="Default" key="Default">Default</option>
                <option value="Red Theme" key="Red Theme">Red Theme</option>
                <option value='Red & Blue Theme' key='Red & Blue Theme'>Red & Blue Theme</option>
             </Select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tbCells" width={300}>
            <label>Terms & Conditions</label>
          </TableCell>
          <TableCell className="tbCells">
             <TextField placeholder="Please Enter Detials"
                multiline
                value={formData.terms_conditions && formData.terms_conditions}
                onChange={(e)=> setFormData({...formData, terms_conditions: e.target.value})}
                sx={{width:'100%'}}
                rows={2} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tbCells" width={300}>
            <label>Remarks</label>
          </TableCell>
          <TableCell className="tbCells">
             <TextField placeholder="Please Enter Detials"
                multiline
                value={formData.remarks}
                onChange={(e)=> setFormData({...formData, remarks: e.target.value})}
                sx={{width:'100%'}}
                rows={2} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tbCells" width={300}>
            <label>US Currency Rates (PKR)</label>
          </TableCell>
          <TableCell className="tbCells">
             <TextField placeholder="Please Enter Detials"
                type="number"
                value={formData.usd}
                onChange={(e)=> setFormData({...formData, usd: e.target.value})}
                sx={{width:'100%'}}
                 />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tbCells" width={300}>
            <label>UK Currency Rates (PKR)</label>
          </TableCell>
          <TableCell className="tbCells">
             <TextField placeholder="Please Enter Detials"
                type="number"
                value={formData.gbp}
                onChange={(e)=> setFormData({...formData, gbp: e.target.value})}
                sx={{width:'100%'}}
                 />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tbCells" width={300}>
            <label>UAE Currency Rates(PKR)</label>
          </TableCell>
          <TableCell className="tbCells">
             <TextField placeholder="Please Enter Detials"
                type="number"
                onChange={(e)=> setFormData({...formData, aed: e.target.value})}
                value={formData.aed}
                sx={{width:'100%'}}
                 />
          </TableCell>
        </TableRow>
      </Table>
      
          <Button
            sx={{ textAlign: "right", mt: 1, mr: 1 }}
            variant="contained"
            color="success"
            endIcon={<Send />}
            onClick={() => OnSubmit(formData)}
          >
            Save Settings
          </Button>
    </Paper>
    
    </Box>
     
      </Box>
    </>
  );
}
